<div class="mt-1 container">
    <div *ngFor="let day of days; let i = index" class="days">
        <div [formGroup]="day" class="day">
            <h4>{{ dayNames[i] }}</h4>
            <p-calendar [timeOnly]="true" placeholder="Ora de început" formControlName="start"></p-calendar>
            <p-calendar [timeOnly]="true" placeholder="Ora de sfârșit" formControlName="end"></p-calendar>
            <p-checkbox [binary]="true" formControlName="closed" label="Închis"></p-checkbox>
            <p-checkbox [binary]="true" formControlName="allDay" label="Deschis toată ziua"></p-checkbox>
        </div>
    </div>
</div>
