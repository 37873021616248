import { Component, OnInit } from '@angular/core';
import { SttTableService } from '../../../shared/components/stt-table/stt-table.service';
import { SttTableComponent } from '../../../shared/components/stt-table/stt-table.component';
import { CompanyService } from '../../../shared/services/app/nomenclatures/company.service';
import { ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UserModel } from 'src/app/auth/models/user.model';
import { UserService } from 'src/app/auth/user.service';
import { ProductCategoriesActionTableComponent } from './product-categories-action-table/product-categories-action-table.component';

@Component({
    selector: 'app-product-categories',
    templateUrl: './product-categories.component.html',
    styleUrls: ['./product-categories.component.scss'],
})
export class ProductCategoriesComponent implements OnInit {
    tableEndpoint = 'category/index';
    tableQuery: SttTableService;
    actionTemplate = ProductCategoriesActionTableComponent;
    form: FormGroup;
    user: UserModel = null;
    companyName: string;

    public selectedCompany: boolean = false;

    @ViewChild('table') table: SttTableComponent;

    constructor(private companyService: CompanyService, private userService: UserService) {}

    ngOnInit(): void {
        this.tableQuery = new SttTableService().addParam('companyUid', this.companyService.company.uid);
        this.companyName = this.companyService.company.name;
        this.user = this.userService.user;
        this.selectedCompany = true;
    }
}
