import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

export interface Promotion {
    promotionFee: number;
    promotionThreshold: number;
}

@Component({
    selector: 'app-locations-add-edit-promotions',
    templateUrl: './locations-add-edit-promotions.component.html',
    styleUrls: ['./locations-add-edit-promotions.component.scss'],
})
export class LocationsAddEditPromotionsComponent implements OnInit {
    zoneRules: Promotion[] = [];
    constructor(public dialogConfig: DynamicDialogConfig, public dialogRef: DynamicDialogRef) {}

    ngOnInit() {
        const data = this.dialogConfig.data;
        if (data[0] !== null) {
            this.zoneRules = data;
        }
    }

    addPromotion(): void {
        this.zoneRules.push({ promotionFee: 0, promotionThreshold: 0 });
    }

    removePromotion(index: number): void {
        this.zoneRules.splice(index, 1);
    }

    clearPromotions(): void {
        this.zoneRules = [];
    }

    onSave(): void {
        if (this.zoneRules.length === 0 || this.zoneRules[0] === null) {
            this.dialogRef.close(null);
        } else {
            const promotions = this.zoneRules.filter(
                (zone: Promotion) => zone.promotionFee !== 0 || zone.promotionThreshold !== 0
            );
            this.dialogRef.close(promotions);
        }
    }
}
