import { BaseModel } from '../../base.model';
import { CustomerAddressModel } from './customer-address.model';
import { CompanyModel } from '../../../../auth/models/company.model';

export class CustomerModel extends BaseModel {
    id: number;
    uid: string;
    firstName: string;
    lastName: string;
    isCompany: number;
    companyCode?: string;
    companyName?: string;
    phone: string;
    email: string;
    lastOrder?: Date;
    lastOrderId?: number;
    company_id: number;
    company: CompanyModel;
    addresses: Array<CustomerAddressModel>;
}
