import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProductImageModel } from '../../../../../shared/models/nomenclatures/products/product-image.model';
import { ProductImageService } from '../../../../../shared/services/app/nomenclatures/product-image.service';
import { MessageService } from 'primeng/api';
import { ApiResponseModel } from '../../../../../shared/models/api-response.model';
import { HttpErrorResponse } from '@angular/common/http';
import { FileUpload } from 'primeng/fileupload';

@Component({
    selector: 'app-products-add-edit-images',
    templateUrl: './products-add-edit-images.component.html',
    styleUrls: ['./products-add-edit-images.component.scss'],
})
export class ProductsAddEditImagesComponent implements OnInit {
    @Input() form: FormGroup;
    @Input() productUid: string;
    @Input() images: Array<ProductImageModel>;
    @Input() defaultImage: ProductImageModel | undefined;
    @Output() imagesUpdated = new EventEmitter<void>();

    @ViewChild('fileUpload') fileUpload: FileUpload;

    previewImageSrc: string | undefined;

    localActiveImage: ProductImageModel | undefined;

    constructor(readonly imageService: ProductImageService, readonly messageService: MessageService) {}

    ngOnInit() {
        this.localActiveImage = this.defaultImage;
    }

    setActiveImage(image: ProductImageModel) {
        this.localActiveImage = image;
    }

    updateDefaultImage() {
        if (!this.localActiveImage) {
            this.messageService.add({
                severity: 'error',
                summary: 'Eroare',
                detail: 'Nu ați selectat o imagine.',
            });
            return;
        }
        if (this.productUid) {
            if (this.localActiveImage.uid === this.defaultImage.uid) {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Eroare',
                    detail: 'Imaginea selectată este deja implicită.',
                });
                return;
            }
            this.imageService.updateDefaultImage(this.productUid, this.localActiveImage.uid).subscribe({
                next: (response: ApiResponseModel) => {
                    this.defaultImage = this.localActiveImage;
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Succes',
                        detail: 'Imaginea implicită a fost actualizată.',
                    });
                },
                error: (error) => {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Eroare',
                        detail: 'Nu a fost posibilă actualizarea imaginii.',
                    });
                },
            });
        }
    }

    deleteImage() {
        if (!this.localActiveImage) {
            this.messageService.add({
                severity: 'error',
                summary: 'Eroare',
                detail: 'Nu ați selectat o imagine.',
            });
            return;
        }
        if (this.productUid) {
            this.imageService.deleteImage(this.productUid, this.localActiveImage.uid).subscribe({
                next: (response: ApiResponseModel) => {
                    this.images = this.images.filter((image) => image.uid !== this.localActiveImage.uid);
                    if (this.defaultImage.uid === this.localActiveImage.uid) {
                        this.defaultImage = this.images[0] ?? undefined;
                        this.localActiveImage = this.defaultImage;
                    } else if (this.defaultImage) {
                        this.localActiveImage = this.defaultImage;
                    }
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Succes',
                        detail: 'Imaginea a fost ștearsă.',
                    });
                },
                error: (error) => {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Eroare',
                        detail: 'Nu a fost posibilă ștergerea imaginii.',
                    });
                },
            });
        }
    }

    onSelect(event: { files: Array<File> }) {
        this.getImageUrlFromFile(event.files[0]);
    }

    getImageUrlFromFile(file: File) {
        const reader = new FileReader();
        reader.onload = () => {
            this.previewImageSrc = reader.result as string;
        };
        reader.readAsDataURL(file);
    }

    onImageUpload(event: { files: Array<File> }) {
        if (event.files.length === 0) {
            this.messageService.add({
                severity: 'error',
                summary: 'Eroare',
                detail: 'Nu ați selectat o imagine.',
            });
            return;
        }
        this.imageService.uploadImage(this.productUid, event.files[0]).subscribe({
            next: (response: ApiResponseModel) => {
                const image = response.payload as ProductImageModel;
                if (this.images.length === 0) {
                    this.defaultImage = image;
                    this.localActiveImage = image;
                }
                this.images.push(image);
                this.messageService.add({
                    severity: 'success',
                    summary: 'Succes',
                    detail: 'Imaginea a fost încărcată.',
                });
                this.fileUpload.clear();
            },
            error: (err) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 422 && err.error?.payload?.errors?.image) {
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Eroare',
                            detail: 'Imaginea este prea mare. Dimensiunea maximă este de 4MB.',
                        });
                        return;
                    }
                }
                this.messageService.add({
                    severity: 'error',
                    summary: 'Eroare',
                    detail: 'Nu a fost posibilă încărcarea imaginii.',
                });
            },
        });
    }

    onClearImage() {
        this.previewImageSrc = undefined;
        this.fileUpload.clear();
    }
}
