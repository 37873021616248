import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FreyaCloudPaymentTypeEnum } from '../../../../../shared/enum/freya-cloud/freya-cloud-payment-type.enum';
import { FreyaCloudOutputNoticeTypeEnum } from '../../../../../shared/enum/freya-cloud/freya-cloud-output-notice-type.enum';

interface PaymentType {
    name: string;
    value: FreyaCloudPaymentTypeEnum;
}

interface OutputNoticeType {
    name: string;
    value: FreyaCloudOutputNoticeTypeEnum;
}

@Component({
    selector: 'app-companies-add-edit-freya-cloud',
    templateUrl: './companies-add-edit-freya-cloud.component.html',
    styleUrls: ['./companies-add-edit-freya-cloud.component.scss'],
})
export class CompaniesAddEditFreyaCloudComponent implements OnInit {
    @Input() form: FormGroup;
    @Input() companyUid: string;

    getRepositoryEndpoint: string = '';

    paymentDropdownItems = [
        { name: 'Nu trimite', value: FreyaCloudPaymentTypeEnum.NO_PAYMENT_ON_ORDER },
        { name: 'Virament', value: FreyaCloudPaymentTypeEnum.VIRAMENT },
    ];

    outputNoticeDropdownItems = [
        { name: 'Fără document de stoc', value: FreyaCloudOutputNoticeTypeEnum.NO_OUTPUT_NOTICE },
        { name: 'Proces verbal de ieșire', value: FreyaCloudOutputNoticeTypeEnum.OUTPUT_MINUTE },
    ];

    constructor() {}

    ngOnInit(): void {
        this.getRepositoryEndpoint = `freya-sync/getRepositories/${this.companyUid}`;
        this.form.controls['uid'].valueChanges.subscribe((uid: string) => {
            this.getRepositoryEndpoint = `freya-sync/getRepositories/${uid}`;
        });
    }
}
