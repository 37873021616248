import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { EditorModule } from 'primeng/editor';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressBarModule } from 'primeng/progressbar';
import { RippleModule } from 'primeng/ripple';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TooltipModule } from 'primeng/tooltip';

import { AuthGuardService } from '../../guards/auth-guard.service';
import { MainComponent } from '../../main.component';
import { SharedModule } from '../../shared/shared.module';
import { CompaniesAddEditCallCenterComponent } from './companies/companies-add-edit/companies-add-edit-call-center/companies-add-edit-call-center.component';

import { UsersComponent } from './users/users.component';
import { UsersAddEditComponent } from './users/users-add-edit/users-add-edit.component';
import { UsersActionTemplateComponent } from './users/users-action-template/users-action-template.component';
import { CompaniesComponent } from './companies/companies.component';
import { CompaniesAddEditComponent } from './companies/companies-add-edit/companies-add-edit.component';
import { CompaniesActionTemplateComponent } from './companies/companies-action-template/companies-action-template.component';
import { CustomersComponent } from './customers/customers.component';
import { CustomersAddEditComponent } from './customers/customers-add-edit/customers-add-edit.component';
import { CustomersActionTemplateComponent } from './customers/customers-action-template/customers-action-template.component';
import { CompaniesAddEditDataComponent } from './companies/companies-add-edit/companies-add-edit-data/companies-add-edit-data.component';
import { CompaniesAddEditFreyaCloudComponent } from './companies/companies-add-edit/companies-add-edit-freya-cloud/companies-add-edit-freya-cloud.component';
import { ProductsComponent } from './products/products.component';
import { ProductsAddEditComponent } from './products/products-add-edit/products-add-edit.component';
import { ProductsActionTemplateComponent } from './products/products-action-template/products-action-template.component';
import { ProductCategoriesComponent } from './product-categories/product-categories.component';
import { ProductCategoriesAddEditComponent } from './product-categories/product-categories-add-edit/product-categories-add-edit.component';
import { ProductCategoriesActionTableComponent } from './product-categories/product-categories-action-table/product-categories-action-table.component';
import { ProductCategoriesAddEditDataComponent } from './product-categories/product-categories-add-edit-data/product-categories-add-edit-data.component';
import { ProductsAddEditDataComponent } from './products/products-add-edit/products-add-edit-data/products-add-edit-data.component';
import { ProductsAddEditLocationDataComponent } from './products/products-add-edit/products-add-edit-location-data/products-add-edit-location-data.component';
import { ProductsAddEditDescriptionComponent } from './products/products-add-edit/products-add-edit-description/products-add-edit-description.component';
import { ComplexVariationsComponent } from './products/complex-products/complex-variations/complex-variations.component';
import { ProductsAddEditImagesComponent } from './products/products-add-edit/products-add-edit-images/products-add-edit-images.component';
import { FileUploadModule } from 'primeng/fileupload';
import { CompaniesAddEditUsersComponent } from './companies/companies-add-edit/companies-add-edit-users/companies-add-edit-users.component';
import { LocationsComponent } from './locations/locations.component';
import { LocationsAddEditComponent } from './locations/locations-add-edit/locations-add-edit.component';
import { LocationsActionTemplateComponent } from './locations/locations-action-template/locations-action-template.component';
import { LocationsAddEditDataComponent } from './locations/locations-add-edit/locations-add-edit-data/locations-add-edit-data.component';
import { ChipsModule } from 'primeng/chips';
import { LocationsAddEditLocalizationComponent } from './locations/locations-add-edit/locations-add-edit-localization/locations-add-edit-localization.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { LocationsAddEditDeliveryComponent } from './locations/locations-add-edit/locations-add-edit-delivery/locations-add-edit-delivery.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { LocationsAddEditSyncComponent } from './locations/locations-add-edit/locations-add-edit-sync/locations-add-edit-sync.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { LocationsAddEditScheduleComponent } from './locations/locations-add-edit/locations-add-edit-schedule/locations-add-edit-schedule.component';
import { CalendarModule } from 'primeng/calendar';
import { LocationsAddEditWalletComponent } from './locations/locations-add-edit/locations-add-edit-wallet/locations-add-edit-wallet.component';
import { LocationsAddEditPromotionsComponent } from './locations/locations-add-edit/locations-add-edit-delivery/locations-add-edit-promotions/locations-add-edit-promotions.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { CustomersAddEditDataComponent } from './customers/customers-add-edit/customers-add-edit-data/customers-add-edit-data.component';
import { CustomersAddEditAddressesComponent } from './customers/customers-add-edit/customers-add-edit-addresses/customers-add-edit-addresses.component';
import { AccordionModule } from 'primeng/accordion';
import { CompaniesAddEditSettingsComponent } from './companies/companies-add-edit/companies-add-edit-settings/companies-add-edit-settings.component';
import { CompaniesAddEditUsersActionsComponent } from './companies/companies-add-edit/companies-add-edit-users/companies-add-edit-users-actions/companies-add-edit-users-actions.component';
import { ComplexProductsComponent } from './products/complex-products/complex-products.component';
import { StepsModule } from 'primeng/steps';
import { ComplexConfigTypeComponent } from './products/complex-products/complex-config-type/complex-config-type.component';
import { ComplexRulesComponent } from './products/complex-products/complex-rules/complex-rules.component';
import { ComplexRulesPropagationComponent } from './products/complex-products/complex-rules-propagation/complex-rules-propagation.component';
import { LocationInfoComponent } from './products/complex-products/location-info/location-info.component';
import { ComplexVariationsPropagationComponent } from './products/complex-products/complex-variations-propagation/complex-variations-propagation.component';
import { CompaniesAddEditPhotosComponent } from './companies/companies-add-edit/companies-add-edit-photos/companies-add-edit-photos.component';

const routes: Routes = [
    {
        path: 'nomenclatures',
        redirectTo: 'nomenclatures/companies',
        pathMatch: 'full',
    },
    {
        path: 'nomenclatures',
        component: MainComponent,
        children: [
            {
                path: 'users',
                canActivate: [AuthGuardService],
                children: [
                    {
                        path: 'list',
                        component: UsersComponent,
                        canActivate: [AuthGuardService],
                    },
                    { path: '', redirectTo: 'list', pathMatch: 'full' },
                ],
            },
            {
                path: 'companies',
                canActivate: [AuthGuardService],
                data: { needsAdmin: true },
                children: [
                    {
                        path: 'list',
                        component: CompaniesComponent,
                        canActivate: [AuthGuardService],
                    },
                    {
                        path: 'add-edit',
                        component: CompaniesAddEditComponent,
                        canActivate: [AuthGuardService],
                    },
                    { path: '', redirectTo: 'list', pathMatch: 'full' },
                ],
            },
            {
                path: 'customers',
                canActivate: [AuthGuardService],
                children: [
                    {
                        path: 'list',
                        component: CustomersComponent,
                        canActivate: [AuthGuardService],
                    },
                    { path: '', redirectTo: 'list', pathMatch: 'full' },
                ],
            },
            {
                path: 'locations',
                canActivate: [AuthGuardService],
                children: [
                    {
                        path: 'list',
                        component: LocationsComponent,
                        canActivate: [AuthGuardService],
                    },
                    {
                        path: 'add-edit',
                        component: LocationsAddEditComponent,
                        canActivate: [AuthGuardService],
                    },
                    { path: '', redirectTo: 'list', pathMatch: 'full' },
                ],
            },
            {
                path: 'products',
                canActivate: [AuthGuardService],
                children: [
                    {
                        path: 'list',
                        component: ProductsComponent,
                        canActivate: [AuthGuardService],
                    },
                    {
                        path: 'add-edit',
                        component: ProductsAddEditComponent,
                        canActivate: [AuthGuardService],
                    },
                    {
                        path: 'complex-products',
                        canActivate: [AuthGuardService],
                        component: ComplexProductsComponent,
                    },
                    { path: '', redirectTo: 'list', pathMatch: 'full' },
                ],
            },

            {
                path: 'product-categories',
                canActivate: [AuthGuardService],
                children: [
                    {
                        path: 'list',
                        component: ProductCategoriesComponent,
                        canActivate: [AuthGuardService],
                    },
                    {
                        path: 'add-edit',
                        component: ProductCategoriesAddEditComponent,
                        canActivate: [AuthGuardService],
                    },
                    { path: '', redirectTo: 'list', pathMatch: 'full' },
                ],
            },
        ],
    },
];

@NgModule({
    declarations: [
        UsersComponent,
        UsersAddEditComponent,
        UsersActionTemplateComponent,
        CompaniesComponent,
        ProductCategoriesAddEditDataComponent,
        CompaniesAddEditComponent,
        CompaniesActionTemplateComponent,
        CustomersComponent,
        CustomersAddEditComponent,
        CustomersActionTemplateComponent,
        CompaniesAddEditCallCenterComponent,
        CompaniesAddEditDataComponent,
        CompaniesAddEditFreyaCloudComponent,
        ProductsComponent,
        ProductsAddEditComponent,
        ProductsActionTemplateComponent,
        ProductCategoriesComponent,
        ProductCategoriesAddEditComponent,
        ProductCategoriesActionTableComponent,
        ProductsAddEditDataComponent,
        ProductsAddEditLocationDataComponent,
        ProductsAddEditDescriptionComponent,
        ComplexRulesComponent,
        ComplexVariationsComponent,
        ProductsAddEditImagesComponent,
        CompaniesAddEditUsersComponent,
        LocationsComponent,
        LocationsAddEditComponent,
        LocationsActionTemplateComponent,
        LocationsAddEditDataComponent,
        LocationsAddEditLocalizationComponent,
        LocationsAddEditDeliveryComponent,
        LocationsAddEditSyncComponent,
        LocationsAddEditScheduleComponent,
        LocationsAddEditWalletComponent,
        LocationsAddEditPromotionsComponent,
        ProductsAddEditImagesComponent,
        CustomersAddEditDataComponent,
        CustomersAddEditAddressesComponent,
        CompaniesAddEditSettingsComponent,
        CompaniesAddEditUsersActionsComponent,
        ComplexProductsComponent,
        ComplexConfigTypeComponent,
        ComplexRulesComponent,
        ComplexRulesPropagationComponent,
        LocationInfoComponent,
        ComplexVariationsPropagationComponent,
        CompaniesAddEditPhotosComponent,
    ],
    imports: [
        CommonModule,
        RouterModule.forRoot(routes),
        DividerModule,
        RadioButtonModule,
        SharedModule,
        RippleModule,
        FormsModule,
        ButtonModule,
        ReactiveFormsModule,
        ToggleButtonModule,
        ProgressBarModule,
        TabViewModule,
        TableModule,
        CheckboxModule,
        EditorModule,
        TooltipModule,
        InputTextModule,
        FileUploadModule,
        ChipsModule,
        AutoCompleteModule,
        LeafletModule,
        LeafletDrawModule,
        NgSelectModule,
        CalendarModule,
        FileUploadModule,
        MultiSelectModule,
        AccordionModule,
        OverlayPanelModule,
        StepsModule,
        NgOptimizedImage,
    ],
    exports: [CompaniesAddEditComponent],
})
export class NomenclatureModule {}
