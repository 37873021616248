import { BaseModel } from '../base.model';
import { CustomerModel } from './customers/customer.model';
import { LocationModel } from './locations/location.model';

export class OrderModel extends BaseModel {
    _id: string;
    added_by: number;
    address_id: number | null;
    customer?: CustomerModel;
    customer_id: number;
    deliveryType: string;
    delivery_type: string;
    description: string;
    discount: number;
    error_msg: string;
    location?: LocationModel;
    location_id: number;
    minutes_taken: number;
    orderNo: number;
    payments: Array<OrderPayment>;
    pickup_date: Date;
    products: Array<OrderProduct>;
    source: string;
    status: number;
    total: number;
    totalWithDiscount: number;
    uid: string;
}

export interface OrderPayment {
    name: string;
    total: number;
    type: number;
}

export interface OrderProduct {
    comment: string;
    discount?: string;
    isComplex: boolean;
    menu: Array<any>;
    name: string;
    percentDiscount: boolean;
    price: number;
    priceWithDiscount: number;
    uid: string;
    units: number;
}
