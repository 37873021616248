import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SttTableComponent } from '../../../../shared/components/stt-table/stt-table.component';
import { CustomerModel } from '../../../../shared/models/nomenclatures/customers/customer.model';
import { CustomersAddEditComponent } from '../customers-add-edit/customers-add-edit.component';
import { ApiResponseModel } from '../../../../shared/models/api-response.model';
import { CustomerService } from '../../../../shared/services/app/nomenclatures/customer.service';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-customers-action-template',
    templateUrl: './customers-action-template.component.html',
    styleUrls: ['./customers-action-template.component.scss'],
})
export class CustomersActionTemplateComponent {
    public data: CustomerModel;
    public table: SttTableComponent;
    public dialog: DynamicDialogRef;

    constructor(
        public dialogService: DialogService,
        public router: Router,
        private customerService: CustomerService,
        private messageService: MessageService
    ) {}

    setParams(entity: CustomerModel): void {
        this.data = entity;
    }

    setTableReference(table: SttTableComponent): void {
        this.table = table;
    }

    showDialog() {
        this.dialog = this.dialogService.open(CustomersAddEditComponent, {
            header: 'Editare client',
            width: '70%',
            contentStyle: { 'max-height': '70vh' },
            height: 'fit-content',
            baseZIndex: 10000,
            closeOnEscape: false,
            data: { uid: this.data.uid },
        });

        this.dialog.onClose.subscribe((user: CustomerModel) => {
            if (user) {
                this.table.refresh();
            }
        });
    }

    delete() {
        this.customerService.delete(this.data).subscribe((response: ApiResponseModel) => {
            if (response.status === true) {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Clientul a fost sters cu succes!',
                });
                this.table.refresh();
            }
        });
    }
}
