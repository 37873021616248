import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { finalize } from 'rxjs';
import { ApiResponseModel } from '../../../../../shared/models/api-response.model';
import { LocationService } from '../../../../../shared/services/app/nomenclatures/location.service';

interface Suggestion {
    name: string;
    id: string;
    latitude: string;
    longitude: string;
    streetName?: string;
    streetNumber?: string | number;
    country?: string;
    city?: string;
    county?: string;
    suburb?: string;
    type: 'google' | 'nominatim';
}

@Component({
    selector: 'app-locations-add-edit-localization',
    templateUrl: './locations-add-edit-localization.component.html',
    styleUrls: ['./locations-add-edit-localization.component.scss'],
})
export class LocationsAddEditLocalizationComponent {
    suggestionAddresses: Suggestion[];
    results: string[];

    googleSessionUid?: string;

    loading: boolean = false;
    @Input() form: FormGroup;
    @Input() uid: string;
    @Input() company_uid: string;

    @Output() localizationChanged: EventEmitter<any> = new EventEmitter<any>();

    constructor(private locationService: LocationService, private messageService: MessageService) {}

    onAddressChange(): void {
        const county = this.form.get('county').value;
        const city = this.form.get('city').value;
        const searchText = this.form.get('fullAddress').value;

        this.suggestionAddresses = [];

        if (searchText.length <= 2) {
            return;
        }

        this.locationService
            .addressLookup(city, county, searchText, this.company_uid, this.uid, this.googleSessionUid)
            .subscribe((response: ApiResponseModel) => {
                const newResults: string[] = [];
                if (response.payload.session_uid) {
                    this.googleSessionUid = response.payload.session_uid;
                }
                response.payload.suggestions.map((item) => {
                    this.suggestionAddresses.push({
                        name: item.displayName,
                        id: item.id,
                        latitude: item.lat,
                        longitude: item.lon,
                        streetName: item.streetName,
                        streetNumber: item.streetNumber,
                        county: item.county,
                        city: item.city,
                        suburb: item.suburb,
                        type: response.payload.type,
                    });

                    newResults.push(item.displayName);
                });
                this.results = newResults;
            });
    }

    onAutocompleteOptionSelected(option: string): void {
        const data = this.suggestionAddresses.find((address: Suggestion) => {
            return address.name === option;
        });

        if (data) {
            if (data.type === 'google') {
                if (!this.googleSessionUid) {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Eroare la geodecodarea Google',
                        detail: 'Nu am putut prelua uid-ul sesiunii de autocomplete, încearca din nou.',
                    });
                    return;
                }

                this.loading = true;
                this.locationService
                    .getGooglePlaceDetails(data.id, this.googleSessionUid, this.company_uid)
                    .pipe(
                        finalize(() => {
                            this.form.patchValue({
                                county: data.county ? data.county : data.city,
                                city: data.city ? data.city : data.county,
                                latitude: data.latitude,
                                longitude: data.longitude,
                            });
                            this.localizationChanged.emit();
                            this.loading = false;
                            this.googleSessionUid = undefined;
                        })
                    )
                    .subscribe((response: ApiResponseModel) => {
                        data.name = response.payload.displayName;
                        data.streetName = response.payload.streetName;
                        data.streetNumber = response.payload.streetNumber;
                        data.city = response.payload.city;
                        data.county = response.payload.county;
                        data.country = response.payload.country;
                        data.latitude = response.payload.lat;
                        data.longitude = response.payload.lon;
                    });
                return;
            }
            this.form.patchValue({
                county: data.county ? data.county : data.city,
                city: data.city ? data.city : data.county,
                latitude: data.latitude,
                longitude: data.longitude,
            });
            this.localizationChanged.emit();
        } else {
            this.googleSessionUid = undefined;
            this.messageService.add({
                summary: 'Am intampinat o eroare la preluarea datelor de geolocare',
                severity: 'error',
                detail: 'Va rugam incercati din nou.',
            });
        }
    }
}
