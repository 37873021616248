<section>
    <h2 class="header">Cum doriți să configurați meniul complex?</h2>
    <div
        class="config-option config-option-left"
        [ngClass]="configType === 0 ? 'config-active' : ''"
        (click)="updateConfigType(0)"
    >
        <i class="fas fa-box fa-6x fa-fw"></i>
        <span>Doar pentru o locație</span>
    </div>
    <div
        class="config-option config-option-right"
        [ngClass]="configType === 1 ? 'config-active' : ''"
        (click)="updateConfigType(1)"
    >
        <i class="fas fa-boxes fa-6x fa-fw"></i>
        <span>Pentru mai multe locații</span>
    </div>
</section>
<div [formGroup]="form" class="location-dropdown">
    <h2>
        {{
            configType === 0
                ? 'Alegeți locația pentru care se configurează meniul complex'
                : 'Alegeți locația de referință pentru configurarea meniului complex'
        }}
    </h2>
    <app-stt-dropdown
        [endPoint]="locationEndpoint"
        [formControlParent]="form.controls['location_id']"
    ></app-stt-dropdown>
</div>
