import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { finalize } from 'rxjs';
import { CompanyModel } from '../../auth/models/company.model';
import { UserModel } from '../../auth/models/user.model';
import { ApiResponseModel } from '../../shared/models/api-response.model';
import { CompanyService } from '../../shared/services/app/nomenclatures/company.service';
import { SyncService } from '../../shared/services/app/sync.service';
import { SyncConsoleEventService } from '../../shared/services/base/socket/sync-console-event.service';
import { SyncConsoleService } from '../../shared/services/base/socket/sync-console.service';
import { UserService } from '../../auth/user.service';
import { CompanyModuleEnum } from '../../shared/enum/company-module.enum';
import { CompanyModuleService } from '../../shared/services/app/company-module.service';

@Component({
    selector: 'app-sync',
    templateUrl: './sync.component.html',
    styleUrls: ['./sync.component.scss'],
})
export class SyncComponent implements OnInit, OnDestroy {
    form: FormGroup;
    company: CompanyModel = null;
    requestedUid: string = null;
    user: UserModel = null;
    loading = false;
    messages: string[] = [];

    selectedCompanyName: string = '';
    selectedSync: string;

    MAX_CONSOLE_MESSAGES = 2000;

    ecommerce: boolean = false;

    constructor(
        private syncService: SyncService,
        private syncConsoleService: SyncConsoleService,
        private syncConsoleEventService: SyncConsoleEventService,
        private companyService: CompanyService,
        private messageService: MessageService,
        private router: Router,
        private route: ActivatedRoute,
        private readonly userService: UserService,
        private readonly companyModuleService: CompanyModuleService
    ) {}

    ngOnInit(): void {
        this.user = this.userService.user;
        this.company = this.companyService.company;
        this.selectedCompanyName = this.company.name;
        this.requestedUid = this.company.uid;
        this.messages.push('Se așteaptă comanda de sincronizare...');

        this.syncConsoleEventService.connectionEvent$.subscribe((data: any) => {
            if (data.event === 'connected') {
                this.syncConsoleService.joinChannel(this.user.uid);
            }
        });

        this.syncConsoleEventService.channelBindEvent$.subscribe((e: any) => {
            if (e.event === 'console-update') {
                const text = e.data.text;
                const locationName = e.data.locationName;
                const response = this.createConsoleMessage(text, locationName);
                if (this.messages[0] !== response) {
                    this.messages.splice(0, 0, response);
                }

                if (this.messages.length >= this.MAX_CONSOLE_MESSAGES) {
                    this.messages.pop();
                }
            }
        });

        this.ecommerce = this.companyModuleService.hasModule(CompanyModuleEnum.ECOMMERCE);
    }

    private createConsoleMessage(text: string, locationName?: string): string {
        return !locationName || locationName === '' ? text : `${locationName}: ${text}`;
    }

    getItem(): void {
        this.loading = true;
        this.companyService
            .getItem(this.requestedUid)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe((response: ApiResponseModel) => {
                this.company.map(response.payload);

                if (!this.company) {
                    this.router.navigate(['/pages/404']).then(() => {
                        this.messageService.add({
                            severity: 'error',
                            summary: '404',
                            detail: 'Nu s-a putut găsi aceasta pagina.',
                        });
                    });
                }

                this.router.navigate([], {
                    relativeTo: this.route,
                    queryParams: {
                        uid: this.company.uid,
                    },
                    queryParamsHandling: 'merge',
                    skipLocationChange: true,
                });
            });
    }

    startSync(): void {
        if (!this.company) {
            this.messageService.add({
                severity: 'error',
                summary: 'Alegeți o companie!',
            });
            return;
        }

        if (!this.selectedSync) {
            this.messageService.add({
                severity: 'error',
                summary: 'Selectați tipul de sincronizare!',
            });
            return;
        }

        this.messages = ['Începe sincronizarea!'];

        this.syncService
            .syncAll({
                uid: this.company.uid,
                action: this.selectedSync,
            })
            .subscribe(() => {});
    }

    ngOnDestroy() {
        this.syncConsoleService.leftChannel('console.' + this.user.uid);
    }
}
