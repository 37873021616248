<p-divider></p-divider>
<div class="header">
    <div class="header-text">
        <h2 *ngIf="company; else noCompany">Sincronizare pentru compania {{ selectedCompanyName }}</h2>
        <ng-template #noCompany>
            <h2>Sincronizare</h2>
        </ng-template>
        <h4 *ngIf="!company">Selectați o companie!</h4>
    </div>
</div>
<p-divider></p-divider>

<div class="card" *ngIf="company" [ecommerce]="ecommerce">
    <div class="grid">
        <div class="col-7 xl:col-6 transition-all transition-duration-200">
            <div class="radio-box">
                <p-radioButton
                    [(ngModel)]="selectedSync"
                    name="syncType"
                    value="freyaProducts"
                    label="Sincronizare produse Freya -> Freya Sync"
                ></p-radioButton>
                <p-radioButton
                    *ngIf="ecommerce"
                    [(ngModel)]="selectedSync"
                    name="syncType"
                    value="ecommerce"
                    label="Sincronizare produse eCommerce -> Freya Sync"
                ></p-radioButton>
                <p-radioButton
                    *ngIf="ecommerce"
                    [(ngModel)]="selectedSync"
                    name="syncType"
                    value="freyaToEcommerce"
                    label="Sincronizare produse Freya -> eCommerce"
                ></p-radioButton>
                <p-radioButton
                    [disabled]="!company.freyaCloud"
                    [(ngModel)]="selectedSync"
                    name="syncType"
                    value="locations"
                    label="Sincronizare locații (Freya Cloud)"
                ></p-radioButton>
                <p-radioButton
                    *ngIf="ecommerce"
                    [(ngModel)]="selectedSync"
                    name="syncType"
                    value="all"
                    label="Sincronizare completă"
                ></p-radioButton>
                <p-button label="Sincronizare" icon="pi pi-plus" (click)="overlay.toggle($event)"></p-button>

                <p-overlayPanel #overlay>
                    <h3>Sunteți sigur că doriți să continuați?</h3>
                    <div class="buttons">
                        <button
                            pButton
                            pRipple
                            type="button"
                            class="p-button-success"
                            (click)="startSync()"
                            label="Da"
                            (click)="overlay.hide()"
                        ></button>
                        <button
                            pButton
                            pRipple
                            type="button"
                            (click)="overlay.hide()"
                            label="Nu"
                            class="p-button-danger"
                        ></button>
                    </div>
                </p-overlayPanel>
            </div>
        </div>
        <div class="col-5 xl:col-6 transition-all transition-duration-200">
            <h3>Informații:</h3>
            <div class="content">
                <p>
                    <strong>Freya</strong> = Freya 3 / Freya Cloud <br />
                    <strong>eCommerce</strong> = site
                </p>
                <p>
                    Nu se recomandă sincronizarea cu site-ul dacă aveți în componenta meniului produse complexe
                    neconfigurate. Acestea trebuie să fie configurate întâi, apoi se poate sincroniza cu site-ul.
                </p>
            </div>
        </div>
    </div>
    <p-divider></p-divider>
    <h3>Log sincronizare</h3>
    <div class="grid">
        <div class="col-12">
            <section class="terminal">
                <div *ngFor="let message of messages">
                    {{ message }}
                </div>
            </section>
        </div>
    </div>
</div>
