<div class="px-1 lg:px-3 xl:px-4 transition-all transition-duration-200 mt-2" [formGroup]="form">
    <div class="col-12">
        <div class="grid">
            <div class="col-6 pr-3">
                <app-stt-input
                    inputId="name"
                    [control]="form.controls['name']"
                    icon="pi pi-box"
                    type="text"
                    label="Denumire"
                ></app-stt-input>
            </div>
            <div class="col-6 pl-3 border-gray-400 border-left-1">
                <app-stt-input
                    inputId="categoryName"
                    [control]="form.controls['categoryName']"
                    icon="pi pi-paperclip"
                    type="text"
                    [readonly]="true"
                    label="Categorie"
                ></app-stt-input>
            </div>
        </div>
        <p-divider></p-divider>
        <div class="grid mt-3">
            <div class="col-6">
                <div class="checkboxes">
                    <p-checkbox
                        formControlName="isComplexProduct"
                        [readonly]="true"
                        [binary]="true"
                        label="Produs complex"
                    ></p-checkbox>
                    <p-checkbox
                        formControlName="weightable"
                        [readonly]="true"
                        [binary]="true"
                        label="Produs cântăribil"
                    ></p-checkbox>
                </div>
            </div>
            <div class="col-6">
                <p-checkbox formControlName="dispatcher_favorite" [binary]="true" label="Favorit"></p-checkbox>
            </div>
        </div>
        <p-divider></p-divider>
        <app-products-add-edit-location-data
            [locationsForm]="locations"
            [dataset]="dataset"
            [productUid]="productUid"
            [company]="company"
        ></app-products-add-edit-location-data>
    </div>
</div>
