<p-progressBar mode="indeterminate" *ngIf="loading"></p-progressBar>
<section>
    <h1>Alegeți locațiile la care se vor propaga varietățile</h1>
    <div class="location-selection">
        <app-location-info
            [locationData]="locationData"
            [selectedLocations]="selectedLocations"
            [(propagationResult)]="propagationResult"
            (selectedLocationsChange)="onLocationsUpdate($event)"
            (openManualConfiguration)="onManualConfiguration($event)"
        ></app-location-info>
    </div>
    <div class="select-buttons">
        <button pButton pRipple label="Selectează tot" icon="fas fa-square-check" (click)="onSelectAll()"></button>
        <button
            pButton
            pRipple
            label="Deselectează tot"
            icon="fas fa-square-check"
            class="p-button-danger"
            (click)="onDeselectAll()"
        ></button>
    </div>
</section>
