import { ApiResponseModel } from '../../shared/models/api-response.model';
import { BaseModel } from '../../shared/models/base.model';
import { LocationModel } from '../../shared/models/nomenclatures/locations/location.model';
import { CompanyModel } from './company.model';

export class UserModel extends BaseModel {
    uid: string;
    code: string;
    name: string;
    email: string;
    password: string;
    password_confirmation: string;
    isAdmin: boolean;
    company_id: number;
    company: CompanyModel;
    locations?: LocationModel[];
    hasCallCenter: boolean = false;
    callCenterExtension: string;
    role: any;
    isActive: boolean = true;
}

export class UserApiResponseModel extends ApiResponseModel {
    override payload: UserModel;
}
