import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocationService } from '../../../../../shared/services/app/nomenclatures/location.service';
import { ApiResponseModel } from 'src/app/shared/models/api-response.model';
import { finalize } from 'rxjs';
import { LocationModel } from 'src/app/shared/models/nomenclatures/locations/location.model';
import { ProductModel } from '../../../../../shared/models/nomenclatures/products/product.model';
import { PropagationResultModel } from '../../../../../shared/models/propagation-result.model';
import { DialogService } from 'primeng/dynamicdialog';
import { ComplexVariationsComponent } from '../complex-variations/complex-variations.component';

interface LocationData {
    name: string;
    id: number;
}

@Component({
    selector: 'app-complex-variations-propagation',
    templateUrl: './complex-variations-propagation.component.html',
    styleUrls: ['./complex-variations-propagation.component.scss'],
})
export class ComplexVariationsPropagationComponent implements OnInit {
    loading = false;
    locationData: Array<LocationData>;
    selectedLocations = new Array<number>();

    @Input() companyId: number;
    @Input() locationId: number;
    @Input() product: ProductModel;
    @Input() propagationResult: PropagationResultModel[];

    @Output() propagationResultChange = new EventEmitter<PropagationResultModel[]>();
    @Output() selectedLocationsChange = new EventEmitter<Array<number>>();

    constructor(private locationService: LocationService, private dialogService: DialogService) {}

    getLocations(): void {
        this.loading = true;
        this.locationService
            .getLocationsForProduct(this.product.id)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe((response: ApiResponseModel) => {
                const locations = response.payload.data.filter(
                    (location: LocationModel) => location.id !== this.locationId
                );
                this.locationData = locations.map((location: LocationModel) => {
                    return {
                        name: location.name,
                        id: location.id,
                    };
                });
            });
    }

    ngOnInit(): void {
        if (this.companyId) {
            this.getLocations();
        }
    }

    onLocationsUpdate(locations: Array<number>): void {
        this.selectedLocations = locations;
        this.selectedLocationsChange.emit(this.selectedLocations);
    }

    onSelectAll(): void {
        this.selectedLocations = this.locationData.map((location: LocationData) => location.id);
        this.selectedLocationsChange.emit(this.selectedLocations);
    }

    onDeselectAll(): void {
        this.selectedLocations = [];
        this.selectedLocationsChange.emit(this.selectedLocations);
    }

    onManualConfiguration(location_id: number): void {
        const dialog = this.dialogService.open(ComplexVariationsComponent, {
            header: 'Configurare reguli',
            width: '70%',
            contentStyle: { 'max-height': '70vh' },
            height: 'fit-content',
            baseZIndex: 10000,
            data: { location_id: location_id, product: this.product },
        });

        dialog.onClose.subscribe(() => {
            this.propagationResult = this.propagationResult.map((result: PropagationResultModel) => {
                if (result.location_id === location_id) {
                    return { location_id: location_id, status: true };
                } else {
                    return result;
                }
            });
            if (!this.selectedLocations.find((item: number) => item === location_id)) {
                this.selectedLocations.push(location_id);
                this.selectedLocationsChange.emit(this.selectedLocations);
            }
            this.propagationResultChange.emit(this.propagationResult);
        });
    }
}
