import { MenuItem } from 'primeng/api';

export interface RestrictedMenuItem extends MenuItem {
    needsAdmin?: boolean;
    items?: RestrictedMenuItem[];
}

export const MenuModel: RestrictedMenuItem[] = [
    {
        label: 'Comenzi',
        icon: 'fas fa-truck fa-fw',
        routerLink: ['/dashboard'],
    },
    {
        label: 'Compania mea',
        icon: 'fas fa-building fa-fw',
        routerLink: ['/mycompany'],
    },
    {
        label: 'Nomenclatoare',
        icon: 'fas fa-book-open fa-fw',
        items: [
            {
                label: 'Companii',
                icon: 'fas fa-building fa-fw',
                routerLink: ['/nomenclatures/companies'],
                needsAdmin: true,
            },
            {
                label: 'Utilizatori',
                icon: 'fas fa-users fa-fw',
                routerLink: ['/nomenclatures/users'],
                needsAdmin: true,
            },
            {
                label: 'Clienți',
                icon: 'fas fa-business-time fa-fw',
                routerLink: ['/nomenclatures/customers'],
            },
            {
                label: 'Locații',
                icon: 'fas fa-location-dot fa-fw',
                routerLink: ['/nomenclatures/locations'],
            },
            {
                label: 'Produse',
                icon: 'fas fa-box-open fa-fw',
                routerLink: ['/nomenclatures/products'],
            },
            {
                label: 'Categorii produse',
                icon: 'fas fa-compass-drafting fa-fw',
                routerLink: ['/nomenclatures/product-categories'],
            },
        ],
    },
    {
        label: 'Sincronizare',
        icon: 'fas fa-rotate fa-fw',
        routerLink: ['/sync'],
    },
];
