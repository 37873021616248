import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';

import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { RippleModule } from 'primeng/ripple';
import { MessageService } from 'primeng/api';
import { StyleClassModule } from 'primeng/styleclass';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './layout/menu/menu.component';
import { MenuItemComponent } from './layout/menu/menu-item/menu-item.component';

import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module';
import { UserInfoComponent } from './layout/user-info/user-info.component';
import { MainComponent } from './main.component';
import { NotfoundComponent } from './layout/notfound/notfound.component';
import { SyncComponent } from './freya-sync/sync/sync.component';
import { CardModule } from 'primeng/card';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TerminalModule } from 'primeng/terminal';
import { SettingsComponent } from './freya-sync/settings/settings.component';
import { ToastModule } from 'primeng/toast';
import { MyCompanyComponent } from './freya-sync/my-company/my-company.component';
import { NomenclatureModule } from './freya-sync/nomenclature/nomenclature.module';
import { UnavailableComponent } from './freya-sync/unavailable/unavailable.component';

@NgModule({
    declarations: [
        AppComponent,
        MenuComponent,
        MenuItemComponent,
        UserInfoComponent,
        MainComponent,
        NotfoundComponent,
        SyncComponent,
        SettingsComponent,
        MyCompanyComponent,
        UnavailableComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        ButtonModule,
        RippleModule,
        SharedModule,
        AuthModule,
        AvatarModule,
        HttpClientModule,
        OverlayPanelModule,
        DividerModule,
        AccordionModule,
        StyleClassModule,
        PanelModule,
        PanelMenuModule,
        ReactiveFormsModule,
        FormsModule,
        CardModule,
        RadioButtonModule,
        TerminalModule,
        ToastModule,
        NomenclatureModule,
    ],
    providers: [MessageService],
    bootstrap: [AppComponent],
})
export class AppModule {}
