import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
    selector: 'app-stt-input',
    templateUrl: './stt-input.component.html',
    styleUrls: ['./stt-input.component.scss'],
})
export class SttInputComponent implements OnInit {
    // Only use PrimeIcons, FontAwesome breaks
    @Input() icon: string;
    @Input() label = '';
    // Default type is text, you can specify other types -> 'number'
    @Input() type = 'text';
    @Input() inputId: string;
    @Input() control?: AbstractControl;
    @Input() readonly = false;
    @Input() feedback = true;
    @Input() iconPosition = 'left';
    @Input() min: number;

    convertedControl: FormControl;

    hasRequired(abstractControl: AbstractControl): boolean {
        if (abstractControl && abstractControl.validator) {
            const validator = abstractControl.validator({} as AbstractControl);
            if (validator && validator['required']) {
                return true;
            }
        }
        return false;
    }

    constructor() {}

    ngOnInit(): void {
        this.convertedControl = this.control as FormControl;
    }
}
