<p-divider></p-divider>
<div class="header">
    <div class="header-text">
        <h2 *ngIf="selectedCompany; else noCompany">Clienți pentru compania: {{ companyName }}</h2>
        <ng-template #noCompany>
            <h2>Clienti</h2>
        </ng-template>
        <h4 *ngIf="!selectedCompany">Selectați o companie!</h4>
    </div>
</div>
<p-divider></p-divider>

<div class="grid" *ngIf="selectedCompany === true">
    <div class="col-12">
        <div class="card">
            <app-stt-table #table [actionTemplate]="actionTemplate" [endpoint]="tableEndpoint" [query]="tableQuery">
            </app-stt-table>
        </div>
    </div>
</div>
