<section [formGroup]="form">
    <div class="grid mt-2">
        <div class="col-6">
            <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                    <i class="pi pi-map"></i>
                </span>
                <div class="p-float-label">
                    <ng-select
                        [items]="items"
                        bindLabel="name"
                        bindValue="value"
                        formControlName="usesGoogleGeo"
                    ></ng-select>
                    <label>Tip Geo-Localizare</label>
                </div>
            </div>
        </div>
        <div class="col-6">
            <app-stt-input
                [control]="form.controls['googleApiKey']"
                label="Google Api Key"
                icon="pi pi-key"
            ></app-stt-input>
        </div>
    </div>
    <div class="grid mt-4">
        <div class="checkboxes">
            <p-checkbox
                formControlName="sendBuildingDataOnOrderDescription"
                pTooltip="Bifați dacă vreți să fie adăugate automat în descrierea comenzii blocul și apartamentul clientului, pentru a apărea în POS"
                tooltipPosition="left"
                label="Trimite detaliile clădirii în descrierea comenzii (bloc, apartament)"
                [binary]="true"
            ></p-checkbox>
            <p-checkbox
                formControlName="sendPaymentOnOrderDescription"
                pTooltip="Bifați dacă vreți să fie trimise automat metodele de plată pe descrierile comenzilor, pentru a apărea în POS"
                tooltipPosition="left"
                label="Trimite metodele de plată în descrierea comenzii"
                [binary]="true"
            ></p-checkbox>
        </div>
    </div>
</section>
