import {
    AfterViewInit,
    Component,
    ComponentFactoryResolver,
    Input,
    OnInit,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';

@Component({
    selector: 'app-stt-table-actions',
    templateUrl: './stt-table-actions.component.html',
    styleUrls: ['./stt-table-actions.component.scss'],
})
export class SttTableActionsComponent implements OnInit, AfterViewInit {
    @ViewChild('actionRowSlot', { static: false, read: ViewContainerRef })
    actionRowSlot: ViewContainerRef;

    @Input() template: any;
    @Input() params: any;
    @Input() tableReference: any;

    constructor(private componentResolver: ComponentFactoryResolver) {}

    ngOnInit(): void {
        return;
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            const factory = this.componentResolver.resolveComponentFactory(this.template);
            const componentRef: any = this.actionRowSlot.createComponent(factory);
            componentRef.instance.setParams(this.params);
            componentRef.instance.setTableReference(this.tableReference);
        }, 10);
    }
}
