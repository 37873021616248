<div class="grid lg:px-3 xl:px-4 transition-all transition-duration-200 mt-2" [formGroup]="form">
    <div class="col-12">
        <div class="grid">
            <div class="col-6 lg:col-7 transition-all transition-duration-200 pr-3">
                <app-stt-input
                    inputId="name"
                    [control]="form.controls['name']"
                    icon="pi pi-building"
                    type="text"
                    label="Denumire"
                ></app-stt-input>
            </div>
            <div class="col-6 lg:col-5 transition-all transition-duration-200 border-gray-400 border-left-1 pl-3">
                <app-stt-input
                    inputId="uniqueCode"
                    [control]="form.controls['uniqueCode']"
                    icon="pi pi-building"
                    type="text"
                    label="CUI"
                ></app-stt-input>
            </div>
        </div>
        <p-divider></p-divider>
        <div class="grid mt-4">
            <div class="col-5 md:col-6 transition-all transition-duration-200 pr-3">
                <app-stt-input
                    inputId="email"
                    [control]="form.controls['email']"
                    icon="pi pi-envelope"
                    type="email"
                    label="Email"
                ></app-stt-input>
            </div>
            <div class="col-7 md:col-6 transition-all transition-duration-200 border-gray-400 border-left-1 pl-3">
                <app-stt-input
                    inputId="nrRegCom"
                    [control]="form.controls['nrRegCom']"
                    icon="pi pi-building"
                    type="text"
                    label="Nr. Registrul Comerțului"
                ></app-stt-input>
            </div>
        </div>
        <br />
        <div class="grid">
            <div class="col-5 md:col-6 transition-all transition-duration-200 pr-3">
                <app-stt-input
                    inputId="phone"
                    [control]="form.controls['phone']"
                    icon="pi pi-phone"
                    type="text"
                    label="Telefon"
                ></app-stt-input>
            </div>
            <div class="col-7 md:col-6 transition-all transition-duration-200 border-left-1 border-gray-400 pl-3">
                <app-stt-input
                    inputId="website"
                    [control]="form.controls['website']"
                    icon="pi pi-globe"
                    type="text"
                    label="Website"
                ></app-stt-input>
            </div>
        </div>
        <p-divider *ngIf="!onMyCompany || isAdmin"></p-divider>
        <div class="grid mt-4" *ngIf="!onMyCompany || isAdmin">
            <div class="col-3 pr-3">
                <h4 class="my-3">Tip Freya</h4>
                <div class="radio-buttons p-fluid">
                    <p-radioButton
                        name="freyaType"
                        value="local"
                        formControlName="freyaType"
                        label="Freya 3"
                    ></p-radioButton>
                    <p-radioButton
                        name="freyaType"
                        value="cloud"
                        formControlName="freyaType"
                        label="Freya Cloud"
                    ></p-radioButton>
                </div>
            </div>
            <div class="col-9 border-gray-400 border-left-1 pl-3">
                <div class="limits">
                    <app-stt-input
                        inputId="locationLimit"
                        [control]="form.controls['locationsLimit']"
                        icon="pi pi-home"
                        type="number"
                        label="Limită locații"
                    ></app-stt-input>
                    <app-stt-input
                        inputId="userLimit"
                        [control]="form.controls['usersLimit']"
                        icon="pi pi-users"
                        type="number"
                        label="Limită utilizatori"
                    ></app-stt-input>
                    <app-stt-input
                        [readonly]="!form.controls.sendMappingSummaries.value"
                        inputId="dailyReportEmail"
                        [control]="form.controls['mappingSummaryEmail']"
                        icon="pi pi-at"
                        type="email"
                        label="Adresă e-mail raport zilnic"
                    ></app-stt-input>
                    <div class="cboxes">
                        <p-checkbox
                            formControlName="isActive"
                            pTooltip="Bifați dacă doriți ca această companie să fie activă"
                            tooltipPosition="left"
                            label="Companie activă"
                            [binary]="true"
                        ></p-checkbox>
                        <p-checkbox
                            formControlName="sendMappingSummaries"
                            pTooltip="Bifați dacă doriți ca zilnic să fie trimis un raport către adresa indicată!"
                            tooltipPosition="left"
                            label="Raport zilnic"
                            [binary]="true"
                        ></p-checkbox>
                        <p-checkbox
                            formControlName="hasPolygons"
                            pTooltip="Bifați dacă doriți ca această companie să aibă poligoane"
                            tooltipPosition="left"
                            label="Companie cu poligoane"
                            [binary]="true"
                        ></p-checkbox>
                        <div formArrayName="modules">
                            <div *ngFor="let module of modules.controls; let i = index" [formGroupName]="i">
                                <p-checkbox
                                    formControlName="active"
                                    [pTooltip]="getTooltip(module.value.module)"
                                    tooltipPosition="left"
                                    [label]="getLabel(module.value.module)"
                                    [binary]="true"
                                ></p-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
