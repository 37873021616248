import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiResponseModel } from '../../../models/api-response.model';
import { LocationApiResponseModel } from '../../../models/nomenclatures/locations/location.model';
import { HttpApiService } from '../../base/http-api.service';
import { LocationModel } from '../../../models/nomenclatures/locations/location.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class LocationService {
    constructor(public httpApiService: HttpApiService, private http: HttpClient, private router: Router) {}

    getTable(params?: LocationModel): Observable<LocationApiResponseModel> {
        return this.httpApiService.httpPost('location/index', params);
    }

    getItem(uid: string): Observable<LocationApiResponseModel> {
        return this.httpApiService.httpGet('location/show', { uid: uid });
    }

    create(data: LocationModel): Observable<LocationApiResponseModel> {
        return this.httpApiService.httpPost('location/store', data);
    }

    update(data: LocationModel): Observable<LocationApiResponseModel> {
        return this.httpApiService.httpPost('location/update', data);
    }

    delete(data: LocationModel): Observable<LocationApiResponseModel> {
        return this.httpApiService.httpDelete('location/destroy', data);
    }

    restore(data: LocationModel): Observable<LocationApiResponseModel> {
        return this.httpApiService.httpPost('location/restore', data);
    }

    getMyList(companyId: number = null): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('location/getMyList', { companyId: companyId });
    }

    addressLookup(
        city: string,
        county: string,
        searchString?: any,
        company_uid?: string,
        location_uid?: string,
        session_uid?: string
    ): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('dispecer/localization/locate', {
            searchTerm: searchString,
            company_uid: company_uid,
            location_uid: location_uid,
            session_uid: session_uid,
        });
    }

    getGooglePlaceDetails(place_id: string, session_uid: string, company_uid: string): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('dispecer/localization/getGooglePlaceDetails', {
            place_id: place_id,
            company_uid: company_uid,
            session_uid: session_uid,
        });
    }

    saveMapPolygon(param: any): Observable<ApiResponseModel> {
        return this.httpApiService.httpPost('location/saveMapPolygon', param);
    }

    editMapPolygon(param: any): Observable<ApiResponseModel> {
        return this.httpApiService.httpPost('location/editMapPolygon', param);
    }

    deletePolygon(uuidLocation: string, clearAll: boolean, uuidPolygon?: any): Observable<ApiResponseModel> {
        return this.httpApiService.httpPost('location/removePolygon', {
            polygon_uid: uuidPolygon,
            location_uid: uuidLocation,
        });
    }

    deleteAllPolygons(location_uid: string): Observable<ApiResponseModel> {
        return this.httpApiService.httpPost('location/removeAllPolygons', { location_uid: location_uid });
    }

    getMapPolygon(uid: string): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('location/getMapPolygon', { uid: uid });
    }

    testFreyaConnection(uid: string): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('location/testFreyaConnection', { uid: uid });
    }

    testEcommerceConnection(uid: string): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('location/testEcommerceConnection', { uid: uid });
    }

    getProducsFromFreya(uid: string): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('location/getProducsFromFreya', { uid: uid });
    }

    getProducsFromEcomerce(uid: string): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('location/getProducsFromEcomerce', { uid: uid });
    }

    getCounties(): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('localization/searchCounty');
    }

    getLocations(companyId?: number): Observable<LocationApiResponseModel> {
        return this.httpApiService.httpGet('location/getLocations', { companyId: companyId });
    }

    getLocationsForProduct(productId: number): Observable<ApiResponseModel> {
        return this.httpApiService.httpPost('location/getLocationsForProduct', { productId: productId });
    }

    currentAddress(searchTerm: string = ''): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('dispecer/localization/locate', { searchTerm: searchTerm });
    }
}
