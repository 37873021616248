import { Injectable } from '@angular/core';
import { TableService } from 'primeng/table';
import { Observable } from 'rxjs';
import { ApiResponseModel } from '../../../models/api-response.model';
import { ProductApiResponseModel } from '../../../models/nomenclatures/products/product.model';
import { MenuRestrictionsModel } from '../../../models/nomenclatures/products/menu.restrictions.model';
import { HttpApiService } from '../../base/http-api.service';

@Injectable({
    providedIn: 'root',
})
export class ProductService {
    constructor(public httpApiService: HttpApiService) {}

    getItem(uid: string): Observable<ProductApiResponseModel> {
        return this.httpApiService.httpGet('product/show/' + uid);
    }

    removeAll(company_uid: string): Observable<ApiResponseModel> {
        return this.httpApiService.httpDelete('product/removeAllProducts', { company_uid });
    }

    getLocationProductsByCategory(locationUid: string, productCategoryId: number): Observable<ProductApiResponseModel> {
        return this.httpApiService.httpGet('product/getLocationProducts', {
            locationUid: locationUid,
            productCategoryId: productCategoryId,
        });
    }

    getLocationProductCategories(locationUid: string): Observable<ProductApiResponseModel> {
        return this.httpApiService.httpGet('product/getLocationProductCategories', { locationUid: locationUid });
    }

    mapEcommerceProductOnLocationProduct(
        locationUid: string,
        productId: number,
        ecommerceId: number
    ): Observable<ProductApiResponseModel> {
        return this.httpApiService.httpPost('product/mapEcommerceProductOnLocationProduct', {
            locationUid: locationUid,
            productId: productId,
            ecommerceId: ecommerceId,
        });
    }

    saveProductDetail(data: any): Observable<ProductApiResponseModel> {
        return this.httpApiService.httpPost('product/saveProductDetail', data);
    }

    saveProductLocationDetail(data: any): Observable<ProductApiResponseModel> {
        return this.httpApiService.httpPost('product/saveProductLocationDetail', data);
    }

    saveProductVariations(data: any): Observable<ProductApiResponseModel> {
        return this.httpApiService.httpPost('product/saveProductVariations', data);
    }

    getProductVariations(uid: string): Observable<ProductApiResponseModel> {
        return this.httpApiService.httpGet('product/getProductVariations/' + uid);
    }

    getProductMenus(productId: number): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('product/getMenus', {
            product_id: productId,
        });
    }

    getProductRestrictions(productId: number, locationId: number): Observable<ProductApiResponseModel> {
        return this.httpApiService.httpGet('product/getComplexRules', {
            product_id: productId,
            location_id: locationId,
        });
    }

    /**
     * Propagates rules on a specified company to all other locations
     *
     * @param locationId
     * @param productId
     * @param locationsToPropagateTo an array of the ids of locations to propagate to
     */
    propagateRules(
        locationId: number,
        productId: number,
        locationsToPropagateTo: Array<number>
    ): Observable<ApiResponseModel> {
        return this.httpApiService.httpPost('product/propagateRules', {
            location_id: locationId,
            product_id: productId,
            propagate_to: locationsToPropagateTo,
        });
    }

    propagateVariations(
        locationId: number,
        productId: number,
        locationsToPropagateTo: Array<number>
    ): Observable<ApiResponseModel> {
        return this.httpApiService.httpPost('product/propagateVariations', {
            location_id: locationId,
            product_id: productId,
            propagate_to: locationsToPropagateTo,
        });
    }

    /**
     * Sets restrictions for a complex product
     * @param productId id of the product
     * @param menuId id of the step (1, 2, 3, ...)
     * @param locationId id of the location the product is in
     * @param itemId
     * @param allowedProducts
     */
    saveProductRestrictions(
        productId: number,
        locationId: number,
        menuCode: string,
        itemCode: string,
        allowedProducts: Map<string, Array<string>>
    ): Observable<ApiResponseModel> {
        const obj: { [key: string]: any } = {};
        allowedProducts.forEach((value: string[], key: string) => (obj[key] = value));
        return this.httpApiService.httpPost('product/setComplexRules', {
            productId: productId,
            locationId: locationId,
            menuId: menuCode,
            itemId: itemCode,
            allowed_products: obj,
        });
    }

    updateProductRestrictions(
        ruleId: string,
        rules: MenuRestrictionsModel,
        productId: number
    ): Observable<ProductApiResponseModel> {
        return this.httpApiService.httpPost('product/updateRule', {
            ruleId: ruleId,
            rules: rules,
            product_id: productId,
        });
    }

    deleteProductRestriction(ruleId: string): Observable<ProductApiResponseModel> {
        return this.httpApiService.httpPost('product/deleteRule', {
            ruleId: ruleId,
        });
    }

    getGeneratedVariations(
        productId: number,
        locationId: number,
        perPage: number,
        page: number
    ): Observable<ProductApiResponseModel> {
        return this.httpApiService.httpGet('product/getAllGeneratedVariations', {
            product_id: productId,
            location_id: locationId,
            per_page: perPage,
            page: page,
            onlyValid: true,
        });
    }

    updateVariationPrice(variationId: string, price: number): Observable<ApiResponseModel> {
        return this.httpApiService.httpPost('product/updateVariationPrice', {
            variation_id: variationId,
            price: price,
        });
    }

    getNextOptions(productId: number, menuName: string): Observable<ProductApiResponseModel> {
        return this.httpApiService.httpGet('product/getNextMenuOptions', {
            product_id: productId,
            menu_name: menuName,
        });
    }

    getExistingRestrictions(
        productId: number,
        menuName: string,
        itemName: string
    ): Observable<ProductApiResponseModel> {
        return this.httpApiService.httpGet('product/getExistingRestrictions', {
            product_id: productId,
            menu_name: menuName,
            item_name: itemName,
        });
    }
}
