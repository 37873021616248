import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { ApiResponseModel } from '../../models/api-response.model';
import { HttpApiService } from '../../services/base/http-api.service';
import { AbstractControl, FormControl } from '@angular/forms';
import { CustomerService } from '../../services/app/nomenclatures/customer.service';
import { from } from 'rxjs';

@Component({
    selector: 'app-stt-dropdown',
    templateUrl: './stt-dropdown.component.html',
    styleUrls: ['./stt-dropdown.component.scss'],
})
export class SttDropdownComponent implements OnInit {
    public loading = false;
    public items: Array<any> = [];
    control: FormControl;

    @Input() placeholder: string;

    @Input() iconPosition = 'left';
    @Input() icon: string;
    @Input() readonly = false;
    @Input() formControlParent: AbstractControl;
    @Input() formControlNameDependency?: string;
    @Input() label = '';
    @Input() endPoint: string;
    @Input() fields = { text: 'name', value: 'id' };
    // New added
    @Input() returnProductId = false;
    @Input() indexOfProductId: number;
    @Input() formControlParent2: AbstractControl;

    @Output() changeEvent = new EventEmitter<any>();
    @Output() loadedDataEvent = new EventEmitter<any>();
    // New added
    @Output() setProductId = new EventEmitter<any>();

    hasRequired(abstractControl: AbstractControl): boolean {
        if (abstractControl.validator) {
            const validator = abstractControl.validator({} as AbstractControl);
            if (validator && validator['required']) {
                return true;
            }
        }
        return false;
    }

    constructor(private httpApiService: HttpApiService, private customerService: CustomerService) {}

    ngOnInit(): void {
        this.control = this.formControlParent as FormControl;
        const filter = {};

        this.formControlParent.valueChanges.subscribe((value) => {
            if (this.returnProductId === true) {
                this.setProductId.emit({
                    id: value,
                    index: this.indexOfProductId,
                });
            }

            // If exist in list, not make request to API
            const exist = this.items.find((obj) => {
                return obj[this.fields.value] === value;
            });

            if (!exist) {
                this.setImplicitValue(value);
            }
        });

        if (this.formControlNameDependency) {
            this.formControlParent.parent.get(this.formControlNameDependency).valueChanges.subscribe((value) => {
                this.getData(filter);
            });

            // TODO make some logic here
            // IF dependency change value
        }

        if (this.formControlParent.value) {
            this.setImplicitValue(this.formControlParent.value);
        } else {
            this.getData(filter);
        }
    }

    setImplicitValue(fieldValue: string): void {
        const filter = {
            complexFilters: { id: { matchMode: 'equals', value: fieldValue } },
        };
        this.getData(filter);
    }

    getData(filter?: any) {
        this.items = [];
        this.loading = true;
        this.httpApiService
            .httpPost(this.endPoint, this.addDependencyFilter(filter))
            .pipe(finalize(() => (this.loading = false)))
            .subscribe((response: ApiResponseModel) => {
                this.items = response.payload.data;
                this.loadedDataEvent.emit(this.items);
            });
    }

    onChange($event: any): void {
        this.changeEvent.emit($event);
    }

    onSearch($event: { term: string; items: any[] }) {
        let filter = {};
        const filterField = this.fields.text;
        if ($event.term.length > 2) {
            filter = {
                complexFilters: {
                    [filterField]: {
                        matchMode: 'contains',
                        value: $event.term,
                    },
                },
            };
            this.getData(filter);
        }
    }

    addDependencyFilter(filter?: any): any {
        if (this.formControlNameDependency?.length) {
            const parentField = this.formControlNameDependency;
            const parentValue = this.formControlParent.parent.get(this.formControlNameDependency).value;

            if (filter.complexFilters) {
                filter.complexFilters[parentField] = {
                    matchMode: 'equals',
                    value: parentValue,
                };
            } else {
                filter = {
                    complexFilters: {
                        [parentField]: {
                            matchMode: 'equals',
                            value: parentValue,
                        },
                    },
                };
            }
        }

        return filter;
    }
}
