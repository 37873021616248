import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { AreaColourEnum, BorderColourEnum } from '../../../../../shared/models/colours';

import {
    circle,
    Control,
    featureGroup,
    FeatureGroup,
    GeoJSON,
    icon,
    Icon,
    latLng,
    marker,
    Marker,
    polygon,
    popup,
    tileLayer,
} from 'leaflet';

import { LeafletControlLayersConfig } from '@asymmetrik/ngx-leaflet';
import { finalize } from 'rxjs/operators';
import { LocationService } from '../../../../../shared/services/app/nomenclatures/location.service';
import { LocationModel } from '../../../../../shared/models/nomenclatures/locations/location.model';
import { ApiResponseModel } from '../../../../../shared/models/api-response.model';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
    LocationsAddEditPromotionsComponent,
    Promotion,
} from './locations-add-edit-promotions/locations-add-edit-promotions.component';

declare let L: any;

interface Palette {
    used: boolean;
    borderColour: string;
    fillColour: string;
    polygonUid?: string;
}

interface LayerColor {
    borderColor: string;
    fillColor: string;
}

interface Layer {
    type: string;
    geometry: any;
    uid: string;
    layerColour?: string;
    areaNumber?: number;
}

@Component({
    selector: 'app-locations-add-edit-delivery',
    templateUrl: './locations-add-edit-delivery.component.html',
    styleUrls: ['./locations-add-edit-delivery.component.scss'],
})
export class LocationsAddEditDeliveryComponent implements AfterViewInit, OnInit {
    colorPalette: Palette[] = [
        { used: false, borderColour: AreaColourEnum.AZURE_DARK, fillColour: BorderColourEnum.AZURE_LIGHT },
        { used: false, borderColour: AreaColourEnum.BLUE_DARK, fillColour: BorderColourEnum.BLUE_LIGHT },
        {
            used: false,
            borderColour: AreaColourEnum.FOREST_GREEN_DARK,
            fillColour: BorderColourEnum.FOREST_GREEN_LIGHT,
        },
        {
            used: false,
            borderColour: AreaColourEnum.FOREST_LIGHT_GREEN_DARK,
            fillColour: BorderColourEnum.FOREST_LIGHT_GREEN_LIGHT,
        },
        { used: false, borderColour: AreaColourEnum.GOLDENROD_DARK, fillColour: BorderColourEnum.GOLDENROD_LIGHT },
        { used: false, borderColour: AreaColourEnum.FIREBRICK_DARK, fillColour: BorderColourEnum.FIREBIRCK_LIGHT },
        {
            used: false,
            borderColour: AreaColourEnum.SADDLE_BROWN_DARK,
            fillColour: BorderColourEnum.SADDLE_BROWN_LIGHT,
        },
    ];

    dropdownEndpoint: string;

    map: L.Map;
    locationMarkerLayer: Marker;
    drawnItems: L.FeatureGroup = featureGroup();
    locationLayer: FeatureGroup = featureGroup();

    loading: boolean = false;
    dialog: DynamicDialogRef;

    layers: Layer[] = [];
    options = {
        layers: [
            tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                opacity: 1,
                attribution: '&copy; <a href="https://soft-tehnica.com/ro/" target="_blank">Soft Tehnica SRL</a>',
            }),
        ],
        zoom: 13,
        center: latLng(44.42044, 26.11039),
    };
    drawOptions: Control.DrawConstructorOptions = {
        position: 'topright',
        draw: {
            marker: false,
            polyline: false,
            circle: false,
            rectangle: false,
            circlemarker: false,

            polygon: {
                shapeOptions: {
                    bubblingMouseEvents: true,
                    interactive: true,
                },
            },
        },
        edit: {
            featureGroup: this.drawnItems,
        },
    };
    selectedPolygonId: number;

    @Input() form: FormGroup;
    @Input() locationModel: LocationModel;
    @Input() hasPolygons: boolean;
    @Input() hasFreyaCloud: boolean;

    @Output() layersChange = new EventEmitter<{ layerColour: string; index: number }[]>();

    constructor(
        private locationService: LocationService,
        private cd: ChangeDetectorRef,
        private dialogService: DialogService
    ) {}

    get freyaDeliveryProductCost(): FormArray<FormGroup> {
        return this.form.get('freyaDeliveryProductCost') as FormArray<FormGroup>;
    }

    ngOnInit() {
        this.dropdownEndpoint = `freya-sync/getProducts/${this.locationModel.uid}`;
    }

    ngAfterViewInit() {
        if (this.hasPolygons) {
            this.restorePolygons();
        }
    }

    onMapReady(map: L.Map): void {
        this.map = map;

        if (this.locationModel.uid) {
            this.addLocationLayerOnMap(Number(this.locationModel.latitude), Number(this.locationModel.longitude));
        }
        this.map.setView([this.locationMarkerLayer.getLatLng().lat, this.locationMarkerLayer.getLatLng().lng], 14);
    }

    onMapMove(): void {
        this.map.invalidateSize();
    }

    onClickMap(event: any): void {
        this.selectedPolygonId = null;
        this.cd.detectChanges();
    }

    onDrawCreated(e: any): void {
        this.drawnItems.addLayer(e.layer);

        this.locationService
            .saveMapPolygon({ geo: [e.layer.toGeoJSON()], location_uid: this.locationModel.uid })
            .pipe(finalize(() => (this.loading = false)))
            .subscribe((response: ApiResponseModel) => {
                this.resetOptions();
                this.drawShapes(response.payload.delivery_polygons);
            });
    }

    onClickPolygon(event: any) {
        const clickedPolygonUid: string = event.target.feature.uid;
        this.selectedPolygonId = this.colorPalette.findIndex(
            (value: Palette) => value.polygonUid === clickedPolygonUid
        );
        this.cd.detectChanges();
    }

    editPromotions(index: number): void {
        const products = this.form.get('freyaDeliveryProductCost') as FormArray<FormGroup>;
        const current = products.controls[index];
        const promoFees = current.controls['promoFees'];
        this.dialog = this.dialogService.open(LocationsAddEditPromotionsComponent, {
            header: 'Configurare promoții de livrare',
            data: promoFees.value,
            closeOnEscape: false,
            closable: false,
            style: { 'min-width': '50vw' },
        });

        this.dialog.onClose.subscribe((data: Promotion[]) => {
            if (data === undefined || data === null || data.length === 0) {
                promoFees.patchValue([]);
            } else {
                promoFees.patchValue(data);
            }
        });
    }

    onEdited(event: any): void {
        const data = {
            geo: event.layers.toGeoJSON().features,
            location_uid: this.locationModel.uid,
        };
        this.locationService
            .editMapPolygon(data)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe((response: any) => {
                this.resetOptions();
                this.drawShapes(response.payload.delivery_polygons);
            });
    }

    resetOptions(): void {
        this.colorPalette.forEach((cp: Palette) => (cp.used = false));
        this.drawnItems.clearLayers();
    }

    drawShapes(delivery_polygons: any): void {
        const geoLayer = L.geoJSON(delivery_polygons, {
            onEachFeature: this.colorLayer.bind(this),
        });

        geoLayer.toGeoJSON().features.forEach((layer: Layer) => {
            this.layers.push(layer);
        });

        const layersChanged = [];
        this.colorPalette.forEach((cp: Palette) => {
            if (cp.used) {
                layersChanged.push({
                    layerColour: cp.borderColour,
                    areaNumber: this.colorPalette.indexOf(cp) + 1,
                    polygonUid: cp.polygonUid,
                });
            }
        });
        this.layersChange.emit(layersChanged);
        this.cd.detectChanges();
    }

    colorLayer(feature: any, layer: any): void {
        const generatedColor: LayerColor = this.generateColor();
        let borderColour = generatedColor.borderColor;
        let fillColour = generatedColor.fillColor;
        let changed = false;

        for (const cp of this.colorPalette) {
            if (cp.used === false) {
                cp.used = true;
                borderColour = cp.borderColour;
                fillColour = cp.fillColour;
                cp.polygonUid = layer.feature.uid;
                changed = true;
                break;
            }
        }

        if (!changed) {
            this.colorPalette.push({
                used: true,
                borderColour: borderColour,
                fillColour: fillColour,
                polygonUid: layer.feature.uid,
            });
        }

        layer.setStyle({
            color: borderColour,
            fillColor: fillColour,
            fillOpacity: 0.2,
        });
        const id = this.drawnItems.getLayers().length;
        layer.bindPopup(`Zona ${id + 1}`);
        layer.on('click', (e: any) => {
            this.onClickPolygon(e);
        });
        this.drawnItems.addLayer(layer);
    }

    generateColor(): LayerColor {
        let color: string = '#';
        let darkerColor: string = '#';
        for (let i = 0; i < 3; i++) {
            const rgb = this.generateRGBValue();
            const darkerRGB = this.darkenRGBValue(rgb);
            const hex = rgb.toString(16);
            const darkerHex = darkerRGB.toString(16);
            color += hex;
            darkerColor += darkerHex;
        }
        return { borderColor: darkerColor, fillColor: color };
    }

    darkenRGBValue(value: number): number {
        return Math.floor(value * (3 / 4));
    }

    generateRGBValue(): number {
        return Math.floor(Math.random() * 255) + 1;
    }

    onDrawDeleted(e: any): void {
        let clearAll: boolean;
        let uuidPolygon: string[] = [];

        const layersToDelete = e.layers._layers;
        if (Object.keys(layersToDelete).length === 0) {
            return;
        }

        if (this.drawnItems.getLayers().length === 0) {
            clearAll = true;
        } else {
            clearAll = false;
            const layersUid = this.layers.map((layer: Layer) => layer.uid);
            Object.keys(layersToDelete).forEach((key: string) => {
                const idsToPush: string[] = layersUid.filter((uid: string) => uid === layersToDelete[key].feature.uid);
                idsToPush.forEach((id: string) => {
                    uuidPolygon.push(id);
                });
            });
        }

        // used to hold the polygons that are returned from the server so that leaflet can redraw them
        let deliveryPolygons: any;
        this.loading = true;
        if (clearAll) {
            this.locationService
                .deleteAllPolygons(this.locationModel.uid)
                .pipe(
                    finalize(() => {
                        this.resetOptions();
                        this.drawShapes(deliveryPolygons);
                        this.loading = false;
                    })
                )
                .subscribe((response: ApiResponseModel) => {
                    deliveryPolygons = response.payload.delivery_polygons;
                });
            return;
        }

        // if clearAll is false, go through all of the polygons and delete them
        // but only rerender on the last element
        for (let i = 0; i < uuidPolygon.length; i++) {
            this.locationService
                .deletePolygon(this.locationModel.uid, clearAll, uuidPolygon[i])
                .pipe(
                    finalize(() => {
                        if (i === uuidPolygon.length - 1) {
                            this.resetOptions();
                            this.drawShapes(deliveryPolygons);
                        }
                        this.loading = false;
                    })
                )
                .subscribe((response: ApiResponseModel) => {
                    if (i === uuidPolygon.length - 1) {
                        deliveryPolygons = response.payload.delivery_polygons;
                    }
                });
        }
    }

    restorePolygons(): void {
        if (!this.locationModel.uid) {
            return;
        }

        this.loading = true;
        this.locationService
            .getMapPolygon(this.locationModel.uid)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe((response: ApiResponseModel) => {
                this.drawShapes(response.payload.delivery_polygons);
            });
    }

    addLocationLayerOnMap(lat: number, lng: number): void {
        if (this.locationMarkerLayer) {
            this.map.removeLayer(this.locationMarkerLayer);
        }

        const iconRetinaUrl = 'assets/leaflet/marker-icon-2x.png';
        const iconUrl = 'assets/leaflet/marker-icon.png';
        const shadowUrl = 'assets/leaflet/marker-shadow.png';
        const iconDefault = icon({
            iconRetinaUrl,
            iconUrl,
            shadowUrl,
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            tooltipAnchor: [16, -28],
            shadowSize: [41, 41],
        });

        this.locationMarkerLayer = L.marker([lat, lng], { icon: iconDefault, draggable: false }).addTo(this.map);

        this.map.setView([lat, lng], 15);
    }
}
