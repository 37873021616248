import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs';
import { ApiResponseModel } from '../../../../shared/models/api-response.model';
import { LocationModel } from '../../../../shared/models/nomenclatures/locations/location.model';
import { ProductLocationModel } from '../../../../shared/models/nomenclatures/products/product-location.model';
import { ProductApiResponseModel, ProductModel } from '../../../../shared/models/nomenclatures/products/product.model';
import { CustomValidationService } from '../../../../shared/services/app/custom-validation.service';
import { LocationService } from '../../../../shared/services/app/nomenclatures/location.service';
import { ProductService } from '../../../../shared/services/app/nomenclatures/product.service';
import { ValidationMessageService } from '../../../../shared/services/base/validation-message.service';
import { ActivatedRoute } from '@angular/router';
import { CompanyService } from 'src/app/shared/services/app/nomenclatures/company.service';
import { CompanyModuleEnum } from 'src/app/shared/enum/company-module.enum';
import { CompanyModel } from 'src/app/auth/models/company.model';
import { CompanyModuleService } from '../../../../shared/services/app/company-module.service';

@Component({
    selector: 'app-products-add-edit',
    templateUrl: './products-add-edit.component.html',
    styleUrls: ['./products-add-edit.component.scss'],
})
export class ProductsAddEditComponent implements OnInit {
    form: FormGroup;
    itemModel: ProductModel = new ProductModel();
    locations: LocationModel[] = [];
    dataset: ProductLocationModel[] = [];
    loading: boolean = false;
    uid: string;
    image: File;
    isDialog = false;

    seenInEcommerceModule: boolean;
    seenInDigitalMenuModule: boolean;
    seenInDispecerModule: boolean;

    company: CompanyModel;

    constructor(
        private formBuilder: FormBuilder,
        public dialog: DynamicDialogRef,
        private config: DynamicDialogConfig,
        private productService: ProductService,
        private locationService: LocationService,
        private messageService: MessageService,
        private validationMessageService: ValidationMessageService,
        private customValidation: CustomValidationService,
        private route: ActivatedRoute,
        private companyService: CompanyService,
        private readonly companyModuleService: CompanyModuleService
    ) {}

    ngOnInit(): void {
        this.company = this.companyService.company;

        this.seenInEcommerceModule = this.companyModuleService.hasModule(CompanyModuleEnum.ECOMMERCE);
        this.seenInDispecerModule = this.companyModuleService.hasModule(CompanyModuleEnum.DISPATCHER);
        this.seenInDigitalMenuModule = this.companyModuleService.hasModule(CompanyModuleEnum.DIGITAL_MENU);

        if (this.route.snapshot.queryParams['uid']) {
            this.uid = this.route.snapshot.queryParams['uid'];
        } else if (this.config.data) {
            this.isDialog = true;
            this.uid = this.config.data ? this.config.data['uid'] : null;
        }
        this.createForm();
        if (this.uid) {
            this.getItem();
        }
    }

    createForm(): void {
        this.form = this.formBuilder.group({
            uid: [this.itemModel.uid],
            name: [this.itemModel.name, Validators.required],
            categoryName: [this.itemModel.categoryName],
            short_description: [this.itemModel.short_description],
            long_description: [this.itemModel.long_description],
            isComplexProduct: [this.itemModel.isComplexProduct],
            dispatcher_favorite: [this.itemModel.dispatcher_favorite],
            weightable: [this.itemModel.weightable],
            locations: this.formBuilder.array([]),
        });
    }

    get formLocations(): FormArray {
        return this.form.controls['locations'] as FormArray;
    }

    getItem(): void {
        this.loading = true;
        this.productService
            .getItem(this.uid)
            .pipe(
                finalize(() => {
                    this.loading = false;
                    this.getLocations();
                })
            )
            .subscribe((response: ProductApiResponseModel) => {
                this.dataset = [];
                this.itemModel.map(response.payload);
                this.form.patchValue(this.itemModel);
                this.form.patchValue({
                    isComplexProduct: Boolean(this.itemModel.isComplexProduct),
                    dispatcherFavourite: Boolean(this.itemModel.dispatcher_favorite),
                    categoryName: response.payload.productCategory.name,
                });
                this.dataset = this.itemModel.productLocations as Array<ProductLocationModel>;
            });
    }

    getLocations(): void {
        this.locationService
            .getMyList(this.itemModel.company_id)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe((response: ApiResponseModel) => {
                this.locations = response.payload;
                this.combineDatasetList();
            });
    }

    combineDatasetList(): void {
        this.locations.forEach((l: LocationModel) => {
            const item = this.dataset.find((i: ProductLocationModel) => i.location_id === l.id);
            if (item) {
                item.location = new LocationModel().map(l);
            } else {
                const newItem = new ProductLocationModel();
                newItem.location = new LocationModel().map(l);
                newItem.product_id = this.itemModel.id;
                newItem.company_id = this.itemModel.company_id;
                newItem.location_id = l.id;
                newItem.price = 0;

                this.dataset.push(newItem);
            }
        });
    }

    onImageUpload(image: File): void {
        this.image = image;
    }

    onClearImage(): void {
        this.image = null;
    }

    prepareData(data: any): any {
        data.isComplexProduct = Number(data.isComplexProduct);
        data.dispatcherFavourite = Number(data.dispatcherFavourite);
        return data;
    }

    onSubmit(): void {
        if (!this.customValidation.validateOnSubmit(this.form)) return;
        this.loading = true;
        let data = this.form.getRawValue();
        data = this.prepareData(data);
        this.productService
            .saveProductDetail(data)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe({
                next: (response: ProductApiResponseModel) => {
                    this.itemModel.map(response.payload);
                    this.form.patchValue(this.itemModel);
                    this.dataset = response.payload.productLocations;
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Produsul a fost salvat cu success!',
                    });
                    this.dialog.close(this.itemModel);
                },
                error: (err) => {
                    if (err instanceof HttpErrorResponse) {
                        this.validationMessageService.serverSideValidation(err, this.form, '', true);
                    }
                },
            });
    }
}
