import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { UserService } from '../../../auth/user.service';
import { SttTableComponent } from '../../../shared/components/stt-table/stt-table.component';
import { SttTableService } from '../../../shared/components/stt-table/stt-table.service';
import { ProductService } from '../../../shared/services/app/nomenclatures/product.service';
import { ProductsActionTemplateComponent } from './products-action-template/products-action-template.component';
import { UserModel } from '../../../auth/models/user.model';
import { CompanyService } from '../../../shared/services/app/nomenclatures/company.service';

@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
    tableEndpoint = 'product/companyProducts/';
    tableQuery: SttTableService;
    actionTemplate = ProductsActionTemplateComponent;
    dialog: DynamicDialogRef;
    form: FormGroup;
    user: UserModel = null;
    companyName: string;

    public selectedCompany: boolean = false;

    @ViewChild('table') table: SttTableComponent;

    constructor(
        private formBuilder: FormBuilder,
        private companyService: CompanyService,
        private productService: ProductService,
        private messageService: MessageService,
        private userService: UserService
    ) {}

    ngOnInit(): void {
        this.createForm();
        this.tableQuery = new SttTableService();

        const company = this.companyService.company;
        this.user = this.userService.user;
        this.companyName = company.name;
        this.tableEndpoint += company.uid;
        this.selectedCompany = true;
    }

    createForm(): void {
        this.form = this.formBuilder.group({
            company_id: [null],
        });
    }

    deleteAll(): void {
        const company = this.companyService.company;
        if (!this.userService.isAdmin()) {
            return;
        }
        this.productService.removeAll(company.uid).subscribe(() => {
            this.messageService.add({
                severity: 'success',
                summary: 'Produsele au fost șterse cu succes!',
            });
            this.table.refresh();
        });
    }
}
