<h2 *ngIf="!uid">Adăugare locatie</h2>
<h2 *ngIf="uid">Editare locație</h2>
<p-divider></p-divider>
<form [formGroup]="form" (ngSubmit)="onSave()" class="mt-4">
    <div class="card">
        <p-tabView>
            <p-tabPanel header="Date generale" leftIcon="pi pi-home" [disabled]="loading">
                <app-locations-add-edit-data
                    [form]="form"
                    [tags]="tags"
                    [location$]="itemModel$.asObservable()"
                    [company_id]="company_id"
                    (tagChanged)="updateTags($event)"
                ></app-locations-add-edit-data>
            </p-tabPanel>
            <p-tabPanel header="Localizare" leftIcon="pi pi-globe" [disabled]="loading">
                <app-locations-add-edit-localization
                    [form]="form"
                    [uid]="uid"
                    [company_uid]="company_uid"
                    (localizationChanged)="onLocalizationChange()"
                ></app-locations-add-edit-localization>
            </p-tabPanel>
            <p-tabPanel *ngIf="shouldShowLocations()" header="Livrare" leftIcon="pi pi-truck" [disabled]="loading">
                <ng-template pTemplate="content">
                    <app-locations-add-edit-delivery
                        #mapComponent
                        [locationModel]="itemModel"
                        (layersChange)="onLayersChanges($event)"
                        [hasFreyaCloud]="hasFreyaCloud"
                        [hasPolygons]="hasPolygons"
                        [form]="form"
                    ></app-locations-add-edit-delivery>
                </ng-template>
            </p-tabPanel>
            <p-tabPanel header="Sincronizare" leftIcon="pi pi-sync" [disabled]="loading">
                <ng-template pTemplate="content">
                    <app-locations-add-edit-sync
                        [form]="form"
                        [uid]="uid"
                        [ecommerceModule]="ecommerceModule"
                        [cloudCompany]="cloudCompany"
                        [companyUid]="company_uid"
                    ></app-locations-add-edit-sync>
                </ng-template>
            </p-tabPanel>
            <p-tabPanel header="Program" leftIcon="pi pi-calendar" [disabled]="loading">
                <app-locations-add-edit-schedule [form]="form"></app-locations-add-edit-schedule>
            </p-tabPanel>
            <p-tabPanel *ngIf="hasVivaWallet" header="Viva Wallet" leftIcon="pi pi-wallet" [disabled]="loading">
                <app-locations-add-edit-wallet [form]="form"></app-locations-add-edit-wallet>
            </p-tabPanel>
        </p-tabView>
        <p-divider></p-divider>
        <div class="actions-area">
            <div class="actions-left">
                <a [routerLink]="['/nomenclatures/locations/list']">
                    <button
                        pButton
                        pRipple
                        type="button"
                        label="Anulare"
                        class="p-button-raised p-button-danger"
                        icon="pi pi-ban"
                        [disabled]="loading"
                    ></button>
                </a>
            </div>

            <div class="actions-right">
                <button
                    pButton
                    pRipple
                    type="submit"
                    label="Salvează"
                    class="p-button-raised p-button-success"
                    icon="pi pi-save"
                    [loading]="loading"
                ></button>
            </div>
        </div>
    </div>
</form>
