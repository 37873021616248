import { UserModel } from '../../auth/models/user.model';

export class BaseModel {
    created_at?: Date;
    updated_at?: Date;
    deleted_at?: Date;

    created_by?: UserModel;
    updated_by?: UserModel;
    deleted_by?: UserModel;

    constructor() {}

    map(input: any): this {
        Object.assign(this, input);
        return this;
    }
}

export class BaseNomenclatureModel extends BaseModel {
    id: number;
    name: string;
    uid: string;
}
