import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { UserModel } from '../../auth/models/user.model';
import { ApiResponseModel } from '../../shared/models/api-response.model';
import { UserService } from '../../auth/user.service';
import { finalize } from 'rxjs';
import { CustomValidationService } from '../../shared/services/app/custom-validation.service';
import { ValidationMessageService } from '../../shared/services/base/validation-message.service';
import { UserProfileModel } from '../../auth/models/user-profile.model';
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { CompanyModel } from '../../auth/models/company.model';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
    form: FormGroup;
    user: UserModel = new UserModel();
    loading: boolean;
    userProfile: UserProfileModel;
    constructor(
        private formBuilder: FormBuilder,
        private userService: UserService,
        private validationMessageService: ValidationMessageService,
        private messageService: MessageService,
        private customValidators: CustomValidationService
    ) {}

    ngOnInit(): void {
        this.createForm();
        this.user = JSON.parse(localStorage.getItem('user'));
        if (this.user) {
            this.form.patchValue({
                name: this.user.name,
                email: this.user.email,
            });
        }
    }
    createForm(): void {
        this.form = this.formBuilder.group(
            {
                name: [this.user.name],
                email: [this.user.email],
                password: [this.user.password],
                password_confirmation: [this.user.password_confirmation],
            },
            {
                validators: this.customValidators.matchPasswords('password', 'password_confirmation'),
            }
        );
    }

    getCurrentCompanyID(): number {
        const company: CompanyModel = JSON.parse(localStorage.getItem('company'));
        if (!company) {
            return -1;
        } else {
            return company.id;
        }
    }

    onSaveProfile(): void {
        if (!this.customValidators.validateOnSubmit(this.form)) return;
        this.loading = true;
        const data = new UserModel().map(this.form.getRawValue());

        data.company_id = this.getCurrentCompanyID();

        this.userService
            .update(data)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe({
                next: (response: ApiResponseModel) => {
                    this.userProfile = response.payload;
                    if (response.status === true) {
                        this.messageService.add({
                            summary: 'Succes',
                            detail: 'Utilizatorul a fost modificat cu succes!',
                            severity: 'success',
                        });
                    }
                },
                error: (err) => {
                    if (err instanceof HttpErrorResponse) {
                        this.validationMessageService.serverSideValidation(err, this.form, 'user.', false);
                    }
                },
            });
    }
}
