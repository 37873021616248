import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class SyncConsoleEventService {
    constructor() {}

    private connectionEvent = new Subject<any>();
    private channelEvent = new Subject<any>();
    private channelBindEvent = new Subject<any>();

    channelBindEvent$ = this.channelBindEvent.asObservable();
    channelEvent$ = this.channelEvent.asObservable();
    connectionEvent$ = this.connectionEvent.asObservable();

    addConnectionEvent(data: { event: string; data: any }): void {
        this.connectionEvent.next(data);
    }

    addChannelEvent(data: { event: string; data: any }): void {
        this.channelEvent.next(data);
    }

    addChannelBindEvent(data: { event: string; data: any; metadata: any }): void {
        this.channelBindEvent.next(data);
    }
}
