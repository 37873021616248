import { Component, Input, OnInit } from '@angular/core';
import { HttpApiService } from 'src/app/shared/services/base/http-api.service';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-companies-add-edit-photos',
    templateUrl: './companies-add-edit-photos.component.html',
    styleUrls: ['./companies-add-edit-photos.component.scss'],
})
export class CompaniesAddEditPhotosComponent {
    @Input() companyUid: string;

    selectedFile = null;

    constructor(private http: HttpApiService, private messageService: MessageService) {}

    onUploadBanner(event) {
        this.http.postFile('company/' + this.companyUid + '/banner', event.files[0], 'banner').subscribe({
            next: (res) => {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Banner incarcat cu succes',
                });
            },
            error: (error) => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Eroare la incarcarea banner-ului',
                    detail: 'A intervenit o eroare la incarcarea banner-ului. Va rugam sa incercati din nou.',
                });
            },
        });
    }

    onUploadLogo(event) {
        this.http.postFile('company/' + this.companyUid + '/logo', event.files[0], 'logo').subscribe({
            next: (res) => {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Logo incarcat cu succes',
                });
            },
            error: (error) => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Eroare la incarcarea logo-ului',
                    detail: 'A intervenit o eroare la incarcarea logo-ului. Va rugam sa incercati din nou.',
                });
            },
        });
    }
}
