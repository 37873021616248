<div class="px-1 lg:px-3 xl:px-4 transition-all transition-duration-200 mt-2" [formGroup]="form">
    <div class="col-12">
        <div class="grid">
            <div class="col-6 pr-3">
                <app-stt-input
                    [control]="form.controls['name']"
                    icon="pi pi-paperclip"
                    type="text"
                    label="Denumire categorie"
                ></app-stt-input>
            </div>
            <div class="col-6 pl-3 border-gray-400 border-left-1">
                <app-stt-input
                    [control]="form.controls['custom_order']"
                    icon="pi pi-eye"
                    type="number"
                    min="0"
                    label="Poziție"
                ></app-stt-input>
            </div>
        </div>
        <p-divider></p-divider>
        <div class="col-12 mt-4 grid">
            <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                    <i class="pi pi-hashtag"></i>
                </span>
                <div class="p-float-label">
                    <p-chips
                        [(ngModel)]="tags"
                        [ngModelOptions]="{ standalone: true }"
                        (onAdd)="tagsChanged()"
                        (onRemove)="tagsChanged()"
                        (onClear)="tagsChanged()"
                        inputId="tags"
                        separator=","
                    ></p-chips>
                    <label for="tags">Etichete</label>
                </div>
            </div>
        </div>
        <p-checkbox formControlName="active" [binary]="true" label="Categorie activă"></p-checkbox>
    </div>
</div>
