<div class="flex justify-content-end">
    <button pButton pRipple label="Adaugă utilizator" (click)="onAdd()" type="button" icon="pi pi-plus"></button>
</div>
<div class="grid">
    <div class="col-12">
        <app-stt-table
            #table
            [actionTemplate]="actionTemplate"
            [endpoint]="tableEndpoint"
            [query]="tableQuery"
        ></app-stt-table>
    </div>
</div>
