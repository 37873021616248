<div class="p-inputgroup">
    <span class="p-inputgroup-addon" *ngIf="icon && iconPosition === 'left'">
        <i [ngClass]="icon"></i>
    </span>
    <div class="p-float-label">
        <input
            *ngIf="type === 'password'; else input"
            pPassword
            [id]="inputId"
            [type]="type"
            [class]="iconPosition === 'left' ? 'icon-left' : iconPosition === 'right' ? 'icon-right' : ''"
            [formControl]="convertedControl"
            [feedback]="feedback"
        />
        <ng-template #input>
            <input
                [type]="type"
                [id]="inputId"
                [formControl]="convertedControl"
                [readOnly]="readonly"
                [class.read-only]="readonly"
                pInputText
                [min]="min"
            />
        </ng-template>
        <label [for]="inputId">
            {{ label }}
            <span *ngIf="hasRequired(control)" style="color: red; font-style: italic">*</span>
        </label>
    </div>
    <span class="p-inputgroup-addon" *ngIf="icon && iconPosition === 'right'">
        <i [ngClass]="icon"></i>
    </span>
</div>
<app-stt-validator-message [control]="control"></app-stt-validator-message>
