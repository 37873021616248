import { Channel } from 'pusher-js';
import { PusherService } from './pusher.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { SyncConsoleEventService } from './sync-console-event.service';

@Injectable({
    providedIn: 'root',
})
export class SyncConsoleService {
    public channel: Channel;

    constructor(public pusherService: PusherService, public socketEvents: SyncConsoleEventService) {
        this.bindConnectionEvents();
    }

    private sendConnectionEvent(event: string, data: any): void {
        this.socketEvents.addConnectionEvent({ event, data });
    }

    private sendChannelEvent(event: string, data: any): void {
        this.socketEvents.addChannelEvent({ event, data });
    }

    private sendChannelBindEvent(event: string, data: any, metadata: any): void {
        this.socketEvents.addChannelBindEvent({ event, data, metadata });
    }

    public joinChannel(uid: string): void {
        this.subscribeToChannel(uid);
        this.bindChannelConnectionEvents();
        this.bindChannelEvents();
    }

    public leftChannel(uid: string): void {
        this.unSubscribeFromChannel(uid);
    }

    public bindConnectionEvents(): void {
        this.pusherService.pusher.connection.bind('initialized', (e: any) => {
            this.sendConnectionEvent('initialized', e);
        });

        this.pusherService.pusher.connection.bind('connecting', (e: any) => {
            this.sendConnectionEvent('connecting', e);
        });

        this.pusherService.pusher.connection.bind('connected', (e: any) => {
            this.sendConnectionEvent('connected', e);
        });

        this.pusherService.pusher.connection.bind('unavailable', (e: any) => {
            this.sendConnectionEvent('unavailable', e);
        });

        this.pusherService.pusher.connection.bind('failed', (e: any) => {
            this.sendConnectionEvent('failed', e);
        });

        this.pusherService.pusher.connection.bind('disconnected', (e: any) => {
            this.sendConnectionEvent('disconnected', e);
        });

        this.pusherService.pusher.connection.bind('error', (e: any) => {
            this.sendConnectionEvent('error', e);
        });
    }

    public bindChannelConnectionEvents(): void {
        this.channel.bind('pusher:subscription_error', (e: any) => {
            this.sendChannelEvent('subscription_error', e);
        });
        this.channel.bind('pusher:subscription_succeeded', (e: any) => {
            this.sendChannelEvent('subscription_succeeded', e);
        });
    }

    public subscribeToChannel(uid: string): void {
        this.channel = this.pusherService.pusher.subscribe('private-console.' + uid);
    }

    public unSubscribeFromChannel(uid: string): void {
        this.pusherService.pusher.unsubscribe('private-console.' + uid);
    }

    public bindChannelEvents(): void {
        this.channel.bind('console-update', (data: any, metadata: any) => {
            this.sendChannelBindEvent('console-update', data, metadata);
        });
    }

    public sendData(uid: string, event: string, data: any): Observable<any> {
        return this.pusherService.sendData('private-console.' + uid, event, data);
    }
}
