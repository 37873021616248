<form [formGroup]="form">
    <h3 class="mb-6">Date client</h3>
    <div class="grid">
        <div class="col-4">
            <app-stt-input
                [control]="form.controls['customerName']"
                inputId="customerName"
                label="Nume"
                [readonly]="true"
                icon="pi pi-user"
            ></app-stt-input>
        </div>
        <div class="col-4">
            <app-stt-input
                [control]="form.controls['customerEmail']"
                inputId="customerEmail"
                label="E-Mail"
                [readonly]="true"
                icon="pi pi-envelope"
            ></app-stt-input>
        </div>
        <div class="col-4">
            <app-stt-input
                [control]="form.controls['customerPhone']"
                inputId="customerPhone"
                label="Telefon"
                [readonly]="true"
                icon="pi pi-phone"
            ></app-stt-input>
        </div>
    </div>
    <div class="grid">
        <div class="col-12">
            <app-stt-input
                [control]="form.controls['customerAddress']"
                inputId="customerAddress"
                label="Adresa"
                [readonly]="true"
                icon="pi pi-home"
            ></app-stt-input>
        </div>
    </div>
    <p-divider></p-divider>
    <h3 class="mb-2">Produse</h3>
    <p-table [value]="formProducts.value">
        <ng-template pTemplate="header">
            <tr>
                <th>Produs</th>
                <th class="text-right">Cantitate</th>
                <th class="text-right">Pret unitar</th>
                <th class="text-right">Valoare</th>
                <th class="text-right">Discount</th>
                <th class="text-right">Valoare cu discount</th>
                <th>Comentariu</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr>
                <td>{{ product.name }}</td>
                <td class="text-right">{{ product.units }}</td>
                <td class="text-right">{{ product.pricePerUnit }}</td>
                <td class="text-right">{{ product.price }}</td>
                <td class="text-right">{{ product.discount }}</td>
                <td class="text-right">{{ product.priceWithDiscount }}</td>
                <td>{{ product.comment }}</td>
            </tr>
        </ng-template>
    </p-table>
    <p-divider></p-divider>
    <h3 class="mb-2">Metode de plata</h3>
    <p-table [value]="formPayments.value">
        <ng-template pTemplate="header">
            <tr>
                <th>Nume</th>
                <th class="text-right">Valoare</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr>
                <td>{{ item.name }}</td>
                <td class="text-right">{{ item.total }}</td>
            </tr>
        </ng-template>
    </p-table>
</form>
