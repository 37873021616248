<div class="card">
    <p-steps [model]="steps" [activeIndex]="activeIndex"></p-steps>
    <div class="active-item">
        <app-complex-config-type
            *ngIf="activeIndex === 0 && !loading"
            [form]="form"
            [product]="product"
            [(configType)]="configType"
            (configTypeChange)="onConfigTypeUpdate($event)"
        ></app-complex-config-type>
        <app-complex-rules
            *ngIf="activeIndex === 1"
            [product]="product"
            [locationId]="form.controls['location_id'].value"
        ></app-complex-rules>
        <app-complex-variations
            *ngIf="activeIndex === 2"
            [product]="product"
            [locationId]="form.controls['location_id'].value"
        ></app-complex-variations>
        <app-complex-rules-propagation
            *ngIf="activeIndex === 3"
            [locationId]="form.controls['location_id'].value"
            [companyId]="companyId"
            [product]="product"
            [(propagationResult)]="rulePropagationResult"
            (selectedLocationsChange)="onPropagationLocationsChange($event)"
        ></app-complex-rules-propagation>
        <app-complex-variations-propagation
            *ngIf="activeIndex === 4"
            [product]="product"
            [locationId]="form.controls['location_id'].value"
            [companyId]="companyId"
            [(propagationResult)]="variationPropagationResult"
            (selectedLocationsChange)="onPropagationLocationsChange($event)"
        ></app-complex-variations-propagation>
    </div>

    <div class="actions-area">
        <div class="actions-left">
            <button
                pButton
                pRipple
                type="button"
                label="Înapoi"
                icon="fas fa-arrow-left"
                [loading]="loading"
                [disabled]="activeIndex === 0"
                (click)="activeIndex = activeIndex - 1"
            ></button>
        </div>

        <div class="actions-right">
            <button
                pButton
                pRipple
                type="button"
                [label]="getNextButtonLabel()"
                [icon]="getNextButtonIcon()"
                [loading]="loading"
                [ngClass]="{ 'p-button-success': shouldNextButtonBeGreen() }"
                (click)="handleStep(activeIndex + 1)"
            ></button>
        </div>
    </div>
    <p-progressBar *ngIf="!product && !failedToLoad" mode="indeterminate"></p-progressBar>
</div>
<h1 *ngIf="failedToLoad">Acest produs este invalid!</h1>
