import { Injectable } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api/lazyloadevent';

@Injectable()
export class SttTableService {
    public includes: string[] = [];
    public perPage = 15;
    public currentPage = 1;
    public total = 0;
    public complexFilters = {};
    private params = {};

    constructor() {}

    public addInclude(relation: string): SttTableService {
        this.includes.push(relation);
        return this;
    }

    public addParam(key: string, value: any): this {
        this.params[key] = value;
        return this;
    }

    getCurrentPageNumber(param: LazyLoadEvent): number {
        return param.first / param.rows + 1;
    }

    getParams(param: LazyLoadEvent) {
        return {
            perPage: param.rows ? param.rows : this.perPage,
            sortField: param.sortField,
            sortOrder: param.sortOrder,
            multiSortMeta: param.multiSortMeta,
            complexFilters: Object.assign(param.filters, this.complexFilters),
            globalFilter: param.globalFilter,
            include: this.includes.join(','),
            page: this.getCurrentPageNumber(param),
            params: this.params,
        };
    }
}
