<div class="container">
    <a
        *ngIf="data.isComplexProduct"
        [routerLink]="'/nomenclatures/products/complex-products'"
        [queryParams]="{ product_uid: this.data.uid }"
    >
        <button
            pButton
            pRipple
            type="button"
            class="p-button-info"
            icon="fas fa-utensils"
            pTooltip="Configurare meniu complex"
            tooltipPosition="left"
        ></button>
    </a>
    <a [routerLink]="'/nomenclatures/products/add-edit'" [queryParams]="{ uid: data.uid }">
        <button pButton pRipple type="button" icon="fas fa-pencil" pTooltip="Editare" tooltipPosition="left"></button>
    </a>
    <button
        pButton
        pRipple
        type="button"
        (click)="copyToClipboard()"
        icon="fas fa-copy"
        pTooltip="Copiază UID"
        tooltipPosition="left"
    ></button>
</div>
