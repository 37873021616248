import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { HttpRequestOptionsModel } from '../../models/http-request-options.model';
import { catchError, finalize, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { serialize } from 'object-to-formdata';
import { Message, MessageService } from 'primeng/api';
import { ApiResponseModel } from '../../models/api-response.model';

@Injectable({
    providedIn: 'root',
})
export class HttpApiService {
    public apiURL = environment.apiUrl;

    constructor(
        private http: HttpClient,
        private router: Router,
        private messageService: MessageService,
        private route: ActivatedRoute
    ) {}

    public getAuthToken(): string {
        return 'Bearer ' + localStorage.getItem('auth-token');
    }

    public getHeaders(type: string = 'application/json', asList: boolean = false): any {
        const headers: { [key: string]: any } = {
            Authorization: this.getAuthToken(),
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,POST,PATCH,DELETE,PUT,OPTIONS',
            'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, content-type',
        };

        if (type) {
            headers['Content-Type'] = type;
        }

        return new HttpHeaders(headers);
    }

    public objectToFormData(obj: any): FormData {
        const options = {
            /**
             * include array indices in FormData keys
             * defaults to false
             */
            indices: true,

            /**
             * treat null values like undefined values and ignore them
             * defaults to false
             */
            nullsAsUndefineds: false,

            /**
             * convert true or false to 1 or 0 respectively
             * defaults to false
             */
            booleansAsIntegers: false,

            /**
             * store arrays even if they're empty
             * defaults to false
             */
            allowEmptyArrays: true,
        };
        return serialize(obj, options);
    }

    public getUrl(path: string): string {
        return this.apiURL + path;
    }

    public setParameters(data: any): any {
        const result: any = [];

        if (!data) {
            return result;
        }

        // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
        const _this = this;

        Object.keys(data).map((key) => {
            if (typeof data[key] === 'object') {
                result[key] = _this.setParameters(data[key]);
            } else {
                result[key] = data[key] ? data[key] : '';
            }
        });

        return result;
    }

    public httpGet(path: string, params?: any): Observable<any> {
        const url = this.getUrl(path);
        const httpOptions = new HttpRequestOptionsModel();

        httpOptions.headers = this.getHeaders();
        httpOptions.params = this.setParameters(params);

        return this.http.get(url, httpOptions).pipe(
            tap((response: any) => {
                // For errors with http code 200
                // @ts-ignore
                if (response.status === false) {
                    // @ts-ignore
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Eroare',
                        detail: response.message,
                    });
                }
            }),
            catchError((err) => {
                this.handleError(err);
                return throwError(err);
            }),
            finalize(() => {})
        );
    }

    public httpPost(path: string, body?: any): Observable<any> {
        const url = this.getUrl(path);
        const httpOptions = new HttpRequestOptionsModel();
        httpOptions.headers = this.getHeaders(null);

        return this.http.post(url, this.objectToFormData(body), httpOptions).pipe(
            tap((response: any) => {
                // For errors with http code 200
                // @ts-ignore
                if (response.status === false) {
                    // @ts-ignore
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Eroare',
                        detail: response.message,
                    });
                }
            }),
            catchError((err) => {
                this.handleError(err);
                return throwError(err);
            }),

            finalize(() => {})
        );
    }

    public httpPut(path: string, body?: any): Observable<any> {
        const url = this.getUrl(path);
        const httpOptions = new HttpRequestOptionsModel();
        httpOptions.headers = this.getHeaders();

        return this.http.put(url, body, httpOptions).pipe(
            tap((response: any) => {
                // For errors with http code 200
                // @ts-ignore
                if (response.status === false) {
                    // @ts-ignore
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Eroare',
                        detail: response.message,
                    });
                }
            }),
            catchError((err) => {
                this.handleError(err);
                return throwError(err);
            }),

            finalize(() => {})
        );
    }

    public httpDelete(path: string, body?: any): Observable<any> {
        const url = this.getUrl(path);
        const httpOptions = new HttpRequestOptionsModel();
        httpOptions.headers = this.getHeaders();
        httpOptions.params = this.setParameters(body);

        return this.http.delete(url, httpOptions).pipe(
            tap((response: any) => {
                // For errors with http code 200
                // @ts-ignore
                if (response.status === false) {
                    // @ts-ignore
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Eroare',
                        detail: response.message,
                    });
                }
            }),
            catchError((err) => {
                this.handleError(err);
                return throwError(err);
            }),

            finalize(() => {})
        );
    }

    public postFile(endpoint: string, file: File, formDataName: string, params?: any) {
        const url = this.getUrl(endpoint);

        const formData = new FormData();
        formData.append(formDataName, file);
        if (params) {
            Object.keys(params).forEach((key) => {
                const IS_OBJECT = params[key] === Object(params[key]);
                const IS_ARRAY = Array.isArray(params[key]);
                if (!IS_OBJECT && !IS_ARRAY) {
                    formData.append(key, params[key]);
                }
            });
        }

        return this.http
            .post<ApiResponseModel>(url, formData, {
                headers: this.getHeaders(null),
            })
            .pipe(
                catchError((err) => {
                    this.handleError(err);
                    return throwError(err);
                })
            );
    }

    public postFiles(path: string, files: any, params: Array<any>): Observable<any> {
        const formData = new FormData();

        files.commercial.forEach((file: any, index: any) => {
            const key = 'commercial[' + index.toString() + ']';
            formData.append(key, file);
        });

        files.technical.forEach((file: any, index: any) => {
            const key = 'technical[' + index.toString() + ']';
            formData.append(key, file);
        });

        params.forEach((parameter) => {
            formData.append(parameter.name, parameter.value);
        });

        return this.http
            .post<any>(this.getUrl(path), formData, {
                headers: this.getHeaders(null),
            })
            .pipe(
                tap((response) => {
                    // For errors with http code 200
                    // @ts-ignore
                    if (response.status === false) {
                        // @ts-ignore
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Eroare',
                            detail: response.message,
                        });
                    }
                }),
                catchError((err) => {
                    this.handleError(err);
                    return throwError(err);
                }),

                finalize(() => {})
            );
    }

    public getFile(entity: string, type: string, uid: string, allFiles: boolean = true): Observable<any> {
        const formData = new FormData();

        formData.append('type', type);
        formData.append('entity', entity);
        formData.append('uid', uid);
        // @ts-ignore
        formData.append('allFiles', allFiles.toString());

        return this.http.post<any>(this.getUrl('getFile'), formData).pipe(
            tap((response) => {
                // For errors with http code 200
                // @ts-ignore
                if (response.status === false) {
                    // @ts-ignore
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Eroare',
                        detail: response.message,
                    });
                }
            }),
            catchError((err) => {
                this.handleError(err);
                return throwError(err);
            }),

            finalize(() => {})
        );
    }

    private handleError(exception: any): void {
        if (exception.error && exception.error.message === 'auth.login.fail') {
            this.messageService.add({
                severity: 'error',
                summary: 'Autentificare',
                detail: 'Adresa de email sau parola nu este corecta. Va rugam sa incercati din nou.',
            });
        }

        if (exception.status === 0) {
            this.messageService.add({
                severity: 'error',
                detail: 'Nu s-a putut realiza conexiunea cu serverul. Va rugam sa incercati din nou.',
            });
        }

        if (exception.status === 511) {
            if (localStorage.getItem('auth-token')) {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Autentificare',
                    detail: 'Sesiunea dumneavoastra a expirat, va rugam sa va reautentificati!',
                });
                localStorage.removeItem('auth-token');
                localStorage.removeItem('auth_expire_in');
                localStorage.removeItem('auth_expire_at');
                localStorage.removeItem('company');
                localStorage.removeItem('user');
                localStorage.removeItem('user_name');
                this.router.navigateByUrl('/auth/login');
            }
        }

        if (exception.status === 400) {
            const defaultMessage =
                'Va rugam sa mai incercati inca o data, iar daca eroarea persista, va rugam sa contactati administratorul.';
            const message = exception.error.message ? exception.error.message : defaultMessage;
            this.messageService.add({
                severity: 'error',
                summary: 'Eroare neasteptata',
                detail: message,
            });
        }
    }

    public httpSocketPost(path: string, formData?: FormData): Observable<any> {
        return this.http.post(path, formData, { headers: this.getHeaders(null) }).pipe(
            tap((response: any) => {
                // For errors with http code 200
                // @ts-ignore
                if (response.status === false) {
                    // @ts-ignore
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Eroare',
                        detail: response.message,
                    });
                }
            }),
            catchError((err) => {
                this.handleError(err);
                return throwError(err);
            }),

            finalize(() => {})
        );
    }

    public httpPostDownload(path: string, body?: any): Observable<any> {
        const url = this.getUrl(path);
        const httpOptions = new HttpRequestOptionsModel();
        httpOptions.headers = this.getHeaders(null);
        httpOptions.responseType = 'blob';

        return this.http.post(url, this.objectToFormData(body), httpOptions);
    }
}
