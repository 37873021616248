<div class="container" (click)="overlay.toggle($event)">
    <div class="avatar-container">
        <span class="avatar">{{ initials }}</span>
    </div>
    <div class="user-info">
        <span class="name">{{ userName }}</span>
        <span class="role">
            {{ user.isAdmin ? 'Administrator' : 'Utilizator' }}
        </span>
    </div>
</div>
<p-overlayPanel #overlay>
    <span>Salut, {{ userName }}!</span><br />
    <a [routerLink]="['/settings']"><button style="margin-top: 1rem; width: 100%" pButton pRipple>Setari</button></a>
    <button
        style="margin-top: 1rem; width: 100%"
        class="p-button-danger"
        pButton
        pRipple
        (click)="userService.logout()"
    >
        Logout
    </button>
</p-overlayPanel>
