<p-divider></p-divider>
<div class="header">
    <div class="header-text">
        <h2 *ngIf="selectedCompany; else noCompany">Produse pentru compania: {{ companyName }}</h2>
        <ng-template #noCompany>
            <h2>Produse</h2>
        </ng-template>
        <h4 *ngIf="!selectedCompany">Selectați o companie!</h4>
    </div>
    <button
        *ngIf="user.isAdmin"
        pButton
        pRipple
        label="Șterge toate produsele"
        class="p-button-danger"
        icon="pi pi-trash"
        (click)="overlay.toggle($event)"
    ></button>
</div>
<p-overlayPanel #overlay>
    <h3>Esti sigur că vrei să ștergi TOATE produsele acestei companii?</h3>
    <div class="buttons">
        <button pButton pRipple type="button" class="p-button-danger" (click)="deleteAll()" label="Da"></button>
        <button pButton pRipple type="button" (click)="overlay.hide()" label="Nu"></button>
    </div>
</p-overlayPanel>
<p-divider></p-divider>

<div class="grid" *ngIf="selectedCompany === true">
    <div class="col-12">
        <div class="card">
            <app-stt-table #table [actionTemplate]="actionTemplate" [endpoint]="tableEndpoint" [query]="tableQuery">
            </app-stt-table>
        </div>
    </div>
</div>
