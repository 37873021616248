<div class="container">
    <h1>404</h1>
    <h2>Oops, această pagină nu exista!</h2>
    <button
        pButton
        type="link"
        class="p-button-raised"
        routerLink="/dashboard"
        icon="pi pi-home"
        label="Înapoi la dashboard"
    ></button>
</div>
