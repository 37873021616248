import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpApiService } from '../../base/http-api.service';
import { ApiResponseModel } from '../../../models/api-response.model';
import { CustomerModel } from '../../../models/nomenclatures/customers/customer.model';
import { TableService } from 'primeng/table';
import { CustomerAddressModel } from '../../../models/nomenclatures/customers/customer-address.model';

@Injectable({
    providedIn: 'root',
})
export class CustomerService {
    constructor(public httpApiService: HttpApiService) {}

    public getItem(uid: string): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('customer/index', { uid: uid });
    }

    public getCustomer(uid: string): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('customer/show', { uid: uid });
    }

    public create(customer: CustomerModel): Observable<ApiResponseModel> {
        if (!customer.uid) {
            return this.httpApiService.httpPost('customer/store', customer);
        } else {
            return this.httpApiService.httpPost('customer/update', customer);
        }
    }

    public addAddress(address: CustomerAddressModel): Observable<ApiResponseModel> {
        return this.httpApiService.httpPost('customer/storeAddress', address);
    }

    public removeAddress(address: CustomerAddressModel): Observable<ApiResponseModel> {
        return this.httpApiService.httpPost('customer/deleteAddress', address);
    }

    public delete(customerModel: CustomerModel): Observable<ApiResponseModel> {
        return this.httpApiService.httpDelete('customer/destroy', customerModel);
    }
}
