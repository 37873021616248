import { Component } from '@angular/core';
import { Params, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CompanyModel } from '../../../auth/models/company.model';
import { SttTableComponent } from '../../../shared/components/stt-table/stt-table.component';
import { ProductModel } from '../../../shared/models/nomenclatures/products/product.model';
import { CompanyService } from '../../../shared/services/app/nomenclatures/company.service';
import { ProductsAddEditComponent } from '../../nomenclature/products/products-add-edit/products-add-edit.component';
import { ViewOrderComponent } from '../view-order/view-order.component';

@Component({
    selector: 'app-orders-action-template',
    templateUrl: './orders-action-template.component.html',
    styleUrls: ['./orders-action-template.component.scss'],
})
export class OrdersActionTemplateComponent {
    public data: any;
    public table: SttTableComponent;
    public dialog: DynamicDialogRef;

    constructor(public dialogService: DialogService, public router: Router) {}

    setParams(entity: any): void {
        this.data = entity;
    }

    setTableReference(table: SttTableComponent): void {
        this.table = table;
    }

    showDialog() {
        this.dialog = this.dialogService.open(ViewOrderComponent, {
            header: 'Vizualizare comanda',
            width: '70%',
            contentStyle: { 'max-height': '70vh' },
            height: 'fit-content',
            baseZIndex: 10000,
            closeOnEscape: false,
            data: { uid: this.data.uid },
        });

        this.dialog.onClose.subscribe((data: any) => {
            if (data) {
                this.table.refresh();
            }
        });
    }
}
