import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroupDirective } from '@angular/forms';
import { SttTableService } from '../stt-table/stt-table.service';
import { SelectableRow } from 'primeng/table';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
    selector: 'app-stt-overlay-table',
    templateUrl: './stt-overlay-table.component.html',
    styleUrls: ['./stt-overlay-table.component.scss'],
})
export class SttOverlayTableComponent {
    @Input() endpoint: string;

    @Input() set controlName(value: string) {
        this.formControl = this.parentF.form.get(value) as FormControl;
    }

    @Input() readonly = false;
    @Input() fields = { text: 'name', value: 'id' };
    @Output() changeEvent = new EventEmitter<any>();
    @Output() loadedData = new EventEmitter<any>();
    @ViewChild('op', { static: false }) op: OverlayPanel;

    public tableQuery: SttTableService;
    public formControl: FormControl;
    public selectedRow: any;

    private lastOpenEvent: MouseEvent;

    constructor(private parentF: FormGroupDirective) {
        this.tableQuery = new SttTableService();
        this.tableQuery.perPage = 5;
    }

    onRowSelect($event: SelectableRow): void {
        this.selectedRow = $event.data;
        this.changeEvent.emit(this.selectedRow);
        this.op.hide();
    }

    onOpenOverlay($event: MouseEvent) {
        this.lastOpenEvent = $event;
        this.op.toggle($event);
    }
}
