import { Component, Input, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ProductLocationModel } from 'src/app/shared/models/nomenclatures/products/product-location.model';
import { ProductCategoriesComponent } from '../product-categories.component';
import { ProductCategoriesAddEditComponent } from '../product-categories-add-edit/product-categories-add-edit.component';
import { EventEmitter } from '@angular/core';
import { LocationModel } from 'src/app/shared/models/nomenclatures/locations/location.model';

@Component({
    selector: 'app-product-categories-add-edit-data',
    templateUrl: './product-categories-add-edit-data.component.html',
    styleUrls: ['./product-categories-add-edit-data.component.scss'],
})
export class ProductCategoriesAddEditDataComponent {
    @Input() form: FormGroup;
    @Input() categoryUid: string;
    @Input() tags: string[];

    @Output() tagChanged: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();

    constructor() {}

    tagsChanged(): void {
        this.tagChanged.emit(this.tags);
    }
}
