import { Injectable } from '@angular/core';
import { UserService } from '../../../auth/user.service';
import { CompanyService } from './nomenclatures/company.service';
import { ApiResponseModel } from '../../models/api-response.model';
import { UserModel } from '../../../auth/models/user.model';
import { HttpErrorResponse } from '@angular/common/http';
import { BootstrapCodeEnum } from '../../enum/bootstrap-code.enum';

@Injectable({
    providedIn: 'root',
})
export class BootstrapService {
    constructor(private readonly userService: UserService, private readonly companyService: CompanyService) {}

    /**
     * Bootstraps the app, then calls the done() or error() callbacks
     * @param done called when bootstrap is finished successfully
     * @param error called when bootstrap fails, with an error code
     */
    public bootstrap(done: () => void, error: (code: BootstrapCodeEnum) => void) {
        this.userService.me().subscribe({
            next: (res: ApiResponseModel) => {
                const user = res.payload as UserModel;
                this.userService.setUser(user);
                if (!this.companyService.hasCustomCompany() || !user.isAdmin) {
                    this.companyService.setCompany(user.company);
                    done();
                    return;
                }
                this.companyService.getItem(this.companyService.customCompanyUid).subscribe({
                    next: (res: ApiResponseModel) => {
                        this.companyService.setCompany(res.payload);
                        done();
                    },
                    error: () => {
                        this.companyService.clearCustomCompanyUid();
                        this.companyService.setCompany(user.company);
                        done();
                    },
                });
            },
            error: (err) => {
                if (!(err instanceof HttpErrorResponse)) {
                    return;
                }
                this.handleBootstrapError(err, error);
            },
        });
    }

    private handleBootstrapError(err: HttpErrorResponse, callback: (code: BootstrapCodeEnum) => void) {
        if (err.status === 0 || err.status === 503) {
            callback(BootstrapCodeEnum.UNAVAILABLE_API);
            return;
        }

        if (err.status === 511) {
            callback(BootstrapCodeEnum.UNAUTHORIZED);
            return;
        }
    }
}
