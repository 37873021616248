import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CompanyModel } from '../../../auth/models/company.model';
import { SttTableService } from '../../../shared/components/stt-table/stt-table.service';
import { SttTableComponent } from '../../../shared/components/stt-table/stt-table.component';
import { CompaniesActionTemplateComponent } from './companies-action-template/companies-action-template.component';
import { CompaniesAddEditComponent } from './companies-add-edit/companies-add-edit.component';
import { Router } from '@angular/router';

@Component({
    selector: 'app-companies',
    templateUrl: './companies.component.html',
    styleUrls: ['./companies.component.scss'],
})
export class CompaniesComponent implements OnInit {
    public tableEndpoint = 'company/index';
    public tableQuery: SttTableService;
    public actionTemplate = CompaniesActionTemplateComponent;
    public dialog: DynamicDialogRef;

    @ViewChild('table') table: SttTableComponent;

    constructor(private dialogService: DialogService, private router: Router) {}

    ngOnInit(): void {
        this.tableQuery = new SttTableService();
    }

    onAdd(): void {
        this.dialog = this.dialogService.open(CompaniesAddEditComponent, {
            header: 'Adăugare companie',
            width: '80%',
            contentStyle: { 'max-height': '70vh' },
            height: 'fit-content',
            baseZIndex: 10000,
            closeOnEscape: false,
            data: null,
        });

        this.dialog.onClose.subscribe((company: CompanyModel) => {
            if (company) {
                this.table.refresh();
            }
        });
    }
}
