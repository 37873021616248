import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-locations-add-edit-wallet',
    templateUrl: './locations-add-edit-wallet.component.html',
    styleUrls: ['./locations-add-edit-wallet.component.scss'],
})
export class LocationsAddEditWalletComponent {
    @Input() form: FormGroup;

    constructor() {}
}
