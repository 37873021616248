import { Injectable } from '@angular/core';
import { HttpApiService } from '../../base/http-api.service';
import { Observable } from 'rxjs';
import { ApiResponseModel } from '../../../models/api-response.model';

@Injectable({
    providedIn: 'root',
})
export class ProductImageService {
    constructor(readonly http: HttpApiService) {}

    updateDefaultImage(productUid: string, imageUid: string) {
        return this.http.httpPost(`product/${productUid}/default-image`, {
            imageUid: imageUid,
        });
    }

    uploadImage(productUid: string, image: File): Observable<ApiResponseModel> {
        return this.http.postFile(`product/${productUid}/images`, image, 'image');
    }

    deleteImage(productUid: string, imageUid: string): Observable<ApiResponseModel> {
        return this.http.httpDelete(`product/${productUid}/images/${imageUid}`);
    }
}
