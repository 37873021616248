import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpApiService } from '../base/http-api.service';
import { ApiResponseModel } from '../../models/api-response.model';

@Injectable({
    providedIn: 'root',
})
export class SyncService {
    constructor(public httpApiService: HttpApiService) {}

    syncAll(params?: object): Observable<ApiResponseModel> {
        return this.httpApiService.httpPost('sync/sync', params);
    }
}
