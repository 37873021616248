<div class="container" [ngClass]="{ 'toggle-menu': !menuHidden }">
    <div class="menu">
        <app-menu></app-menu>
    </div>
    <div class="content">
        <div class="topbar">
            <div class="left">
                <div class="toggle-button hide-on-pc" (click)="menuHidden = false">
                    <i class="fas fa-bars fa-2x" style="color: white"></i>
                </div>
            </div>
            <div class="right">
                <form [formGroup]="form" class="dropdown" (ngSubmit)="onSelectCompany()" *ngIf="isAdmin">
                    <button pButton type="submit" label="Schimbă" icon="pi pi-building"></button>
                    <app-stt-dropdown
                        (changeEvent)="onChangeCompany($event)"
                        endPoint="company/index"
                        [fields]="{ text: 'name', value: 'id' }"
                        icon="pi pi-building"
                        [formControlParent]="form.controls['company_id']"
                    ></app-stt-dropdown>
                </form>
                <app-user-info></app-user-info>
            </div>
        </div>
        <div class="freya-sync">
            <router-outlet></router-outlet>
        </div>
    </div>
    <div class="fade" *ngIf="!menuHidden" (click)="menuHidden = !menuHidden"></div>
</div>
