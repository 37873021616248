<h2 *ngIf="uid">Editare produs</h2>
<form class="flex flex-column card" autocomplete="off" (ngSubmit)="onSubmit()" [formGroup]="form">
    <p-tabView *ngIf="!loading">
        <p-tabPanel>
            <ng-template pTemplate="header">
                <div class="tab-header"><i class="pi pi-box"></i><span>Date produs</span></div>
            </ng-template>
            <ng-template pTemplate="body">
                <app-products-add-edit-data
                    [form]="form"
                    [dataset]="dataset"
                    [productUid]="uid"
                    [company]="company"
                ></app-products-add-edit-data>
            </ng-template>
        </p-tabPanel>
        <p-tabPanel *ngIf="seenInDigitalMenuModule">
            <ng-template pTemplate="header">
                <div class="tab-header"><i class="pi pi-images"></i><span>Imagini</span></div>
            </ng-template>
            <ng-template pTemplate="body">
                <app-products-add-edit-images
                    [form]="form"
                    [productUid]="uid"
                    [images]="itemModel.images"
                    [defaultImage]="itemModel.defaultImage"
                ></app-products-add-edit-images>
            </ng-template>
        </p-tabPanel>
        <p-tabPanel>
            <ng-template pTemplate="header">
                <div class="tab-header"><i class="pi pi-comment"></i><span>Descriere</span></div>
            </ng-template>
            <ng-template pTemplate="body">
                <app-products-add-edit-description [form]="form"></app-products-add-edit-description>
            </ng-template>
        </p-tabPanel>
    </p-tabView>
    <p-progressBar mode="indeterminate" *ngIf="loading"></p-progressBar>
    <p-divider></p-divider>
    <div class="actions-area">
        <div class="actions-left">
            <a [routerLink]="['/nomenclatures/products']">
                <button
                    pButton
                    pRipple
                    type="button"
                    label="Anulare"
                    class="p-button-raised p-button-danger"
                    icon="pi pi-ban"
                    [loading]="loading"
                ></button>
            </a>
        </div>

        <div class="actions-right">
            <button
                pButton
                pRipple
                type="submit"
                label="Salvează"
                class="p-button-raised p-button-success p-outlined-button"
                icon="pi pi-save"
                [loading]="loading"
            ></button>
        </div>
    </div>
</form>
