<div class="refresh-button">
    <button pButton label="Refresh" icon="pi pi-refresh" type="button" (click)="getVariations(perPage, page)"></button>
</div>
<p-table
    [paginator]="true"
    *ngIf="productLocation"
    [rows]="perPage"
    currentPageReportTemplate="Se afișează {first} - {last} din {totalRecords}"
    [lazy]="true"
    [showCurrentPageReport]="true"
    [loading]="loading"
    [totalRecords]="count"
    styleClass="p-datatable-sm"
    [lazyLoadOnInit]="true"
    [value]="variations"
    (onPage)="getServerData($event)"
>
    <ng-template pTemplate="header">
        <tr>
            <th *ngFor="let menu of menus">{{ menu.name }}</th>
            <th>Preț special</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
        <tr>
            <td *ngFor="let menuItem of item.menus">{{ menuItem.name }}</td>
            <td>
                <input
                    pInputText
                    type="number"
                    placeholder="Prețul meniului"
                    [value]="item.price ? item.price : product.price"
                    (input)="onPriceChange($event, item._id)"
                />
            </td>
        </tr>
    </ng-template>
</p-table>
