<br />
<form [formGroup]="form" autocomplete="off" (ngSubmit)="onSave()">
    <div class="grid lg:px-4 xl:px-5 transition-all transition-duration-200" *ngIf="!loading">
        <div class="col-12">
            <div class="grid p-fluid mt-2">
                <div class="col-12 field transition-all transition-duration-2000">
                    <app-stt-input
                        inputId="firstName"
                        [control]="form.controls['name']"
                        icon="pi pi-user"
                        type="text"
                        label="Nume"
                    ></app-stt-input>
                </div>
            </div>
            <div class="grid">
                <div class="col-6 field transition-all transition-duration-200 pr-3">
                    <app-stt-input
                        inputId="email"
                        [control]="form.controls['email']"
                        icon="pi pi-envelope"
                        type="email"
                        label="Email"
                    ></app-stt-input>
                </div>
                <div class="col-6 field border-left-1 border-gray-400 transition-all transition-duration-200 pl-3">
                    <app-stt-dropdown
                        placeholder="Nu ați selectat o companie!"
                        label="Companie"
                        (changeEvent)="getLocations($event.id)"
                        [fields]="{ text: 'name', value: 'id' }"
                        endPoint="company/index"
                        icon="pi pi-building"
                        [readonly]="company_id"
                        [formControlParent]="form.controls['company_id']"
                    ></app-stt-dropdown>
                </div>
            </div>
            <div class="grid">
                <div class="col-6 field transition-all transition-duration-200 pr-3">
                    <app-stt-input
                        inputId="password"
                        [control]="form.controls['password']"
                        icon="pi pi-key"
                        type="password"
                        label="Parola"
                    ></app-stt-input>
                </div>
                <div class="col-6 field border-left-1 border-gray-400 transition-all transition-duration-200 pl-3">
                    <app-stt-input
                        inputId="password_confirmation"
                        [control]="form.controls['password_confirmation']"
                        icon="pi pi-key"
                        type="password"
                        [feedback]="false"
                        label="Confirmare parola"
                    ></app-stt-input>
                </div>
            </div>
            <p-divider></p-divider>
            <div class="grid mt-5">
                <div class="col-6 pr-3">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-home"></i>
                        </span>
                        <div class="p-float-label">
                            <p-multiSelect
                                [options]="locations"
                                formControlName="locations"
                                inputId="locations"
                                lazy="true"
                                appendTo="body"
                                optionLabel="name"
                                dataKey="id"
                                [showClear]="true"
                            ></p-multiSelect>
                            <label for="locations">Locații permise</label>
                        </div>
                    </div>
                </div>
                <div class="col-6 pl-3 border-gray-400 border-left-1">
                    <app-stt-input
                        inputId="callCenterExtension"
                        [control]="form.controls['callCenterExtension']"
                        icon="pi pi-phone"
                        type="text"
                        [readonly]="!form.controls['hasCallCenter'].value"
                        label="Extensie call-center"
                    ></app-stt-input>
                </div>
            </div>
            <div class="grid p-fluid mt-2">
                <div class="col-6 pr-3">
                    <p-checkbox
                        formControlName="isActive"
                        pTooltip="Bifați dacă acest utilizator este activ"
                        tooltipPosition="left"
                        label="Cont activ"
                        [binary]="true"
                    ></p-checkbox>
                </div>
                <div class="col-6 border-left-1 border-gray-400 pl-3">
                    <p-checkbox
                        formControlName="hasCallCenter"
                        pTooltip="Bifați dacă acest utilizator este un agent în call-center"
                        tooltipPosition="left"
                        label="Membru call-center"
                        [binary]="true"
                    ></p-checkbox>
                </div>
            </div>
        </div>
    </div>
    <p-progressBar mode="indeterminate" class="addEditProgressBar" *ngIf="loading"></p-progressBar>
    <p-divider></p-divider>
    <div class="actions-area">
        <div class="actions-left">
            <button
                pButton
                pRipple
                type="button"
                label="Anulare"
                class="p-button-raised p-button-danger"
                icon="pi pi-ban"
                [disabled]="loading"
                (click)="onCancel()"
            ></button>
        </div>

        <div class="actions-right">
            <button
                pButton
                pRipple
                type="submit"
                label="Salveaza"
                class="p-button-raised p-button-success"
                icon="pi pi-save"
                [loading]="loading"
            ></button>
        </div>
    </div>
</form>
