import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocationModel } from '../../../../shared/models/nomenclatures/locations/location.model';
import { CustomValidationService } from '../../../../shared/services/app/custom-validation.service';
import { LocationService } from '../../../../shared/services/app/nomenclatures/location.service';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiResponseModel } from '../../../../shared/models/api-response.model';
import { ActivatedRoute, Router } from '@angular/router';
import { BorderColourEnum } from '../../../../shared/models/colours';
import { MessageService } from 'primeng/api';
import { ValidationMessageService } from '../../../../shared/services/base/validation-message.service';
import { ScheduleDayModel, ScheduleModel } from '../../../../shared/models/nomenclatures/customers/schedule.model';
import { LocationsAddEditDeliveryComponent } from './locations-add-edit-delivery/locations-add-edit-delivery.component';
import { BehaviorSubject } from 'rxjs';
import { CompanyService } from 'src/app/shared/services/app/nomenclatures/company.service';
import { CompanyModuleEnum } from 'src/app/shared/enum/company-module.enum';
import { CompanyModuleModel } from '../../../../auth/models/company.model';

interface Layer {
    layerColour: string;
    borderColour?: string;
    areaNumber: number;
    polygonUid: string;
    promoFees: any;
}

@Component({
    selector: 'app-locations-add-edit',
    templateUrl: './locations-add-edit.component.html',
    styleUrls: ['./locations-add-edit.component.scss'],
})
export class LocationsAddEditComponent implements OnInit {
    form: FormGroup;
    itemModel: LocationModel = new LocationModel();
    itemModel$ = new BehaviorSubject<LocationModel | undefined>(undefined);
    ecommerceModule: boolean;

    uid: string = null;
    company_id: number = null;
    company_uid: string = null;
    hasVivaWallet: boolean = false;

    layers: Layer[] = [];
    tags: string[] = [];

    loading: boolean;

    hasPolygons: boolean = true;
    hasFreyaCloud: boolean = false;

    coloursArray = Object.values(BorderColourEnum);

    cloudCompany: boolean;

    @ViewChild('mapComponent') mapComponent: LocationsAddEditDeliveryComponent;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private messageService: MessageService,
        private validationMessageService: ValidationMessageService,
        private locationService: LocationService,
        private router: Router,
        private customValidation: CustomValidationService,
        private companyService: CompanyService
    ) {}

    get freyaDeliveryProductCost(): FormArray {
        return this.form.get('freyaDeliveryProductCost') as FormArray;
    }

    ngOnInit(): void {
        this.getFromRouteParams();
        this.createForm();

        this.form.patchValue({
            company_id: this.company_id,
        });

        if (this.uid) {
            this.getItem();
        }

        if (this.company_uid && !this.uid) {
            this.checkCompanyModules();
        }
    }

    getFromRouteParams(): void {
        this.uid =
            this.route.snapshot.queryParams && this.route.snapshot.queryParams['uid']
                ? this.route.snapshot.queryParams['uid']
                : null;

        this.company_id =
            this.route.snapshot.queryParams && this.route.snapshot.queryParams['company_id']
                ? Number(this.route.snapshot.queryParams['company_id'])
                : null;

        this.company_uid =
            this.route.snapshot.queryParams && this.route.snapshot.queryParams['company_uid']
                ? this.route.snapshot.queryParams['company_uid']
                : null;
    }

    castScheduleToBools(payload: LocationModel) {
        Object.keys(payload.schedule).forEach((key: string) => {
            let allDay = payload.schedule[key].allDay;
            let closed = payload.schedule[key].closed;
            if (payload.schedule[key].hasOwnProperty('allDay')) {
                payload.schedule[key].allDay = allDay === 'true';
                payload.schedule[key].closed = closed === 'true';
            }
        });
    }

    castScheduleToStrings(payload: LocationModel) {
        Object.keys(payload.schedule).forEach((key: string) => {
            if (payload.schedule[key].hasOwnProperty('allDay')) {
                let allDay = payload.schedule[key].allDay;
                let closed = payload.schedule[key].closed;
                if (allDay === true) {
                    payload.schedule[key].allDay = 'true';
                } else {
                    payload.schedule[key].allDay = 'false';
                }
                if (closed === true) {
                    payload.schedule[key].closed = 'true';
                } else {
                    payload.schedule[key].closed = 'false';
                }
            }
        });
    }

    createForm(): void {
        this.form = this.formBuilder.group({
            name: [this.itemModel.name, Validators.required],
            tag: [this.itemModel.tag],
            company_id: [this.itemModel.company_id],
            county: [this.itemModel.county],
            city: [this.itemModel.city, Validators.required],
            fullAddress: [this.itemModel.fullAddress, Validators.required],
            latitude: [this.itemModel.latitude, Validators.required],
            longitude: [this.itemModel.longitude, Validators.required],
            hasDelivery: [this.itemModel.hasDelivery],
            hasPickup: [this.itemModel.hasPickup],
            syncOrders: [this.itemModel.syncOrders],
            overwritePrice: [this.itemModel.overwritePrice],
            allowEcommerce: [this.itemModel.allowEcommerce],
            allowDispatcher: [this.itemModel.allowDispatcher],
            isDefaultLocation: [this.itemModel.isDefaultLocation],

            freyaUrl: [this.itemModel.freyaUrl, Validators.required],
            freyaUser: [this.itemModel.freyaUser, Validators.required],
            freyaPassword: [this.itemModel.freyaPassword, Validators.required],
            freyaApiKey: [this.itemModel.freyaApiKey, Validators.required],
            freyaDeliveryProduct: [this.itemModel.freyaDeliveryProduct],
            freyaDeliveryProductCost: this.formBuilder.array([]),
            ecommerceType: [this.itemModel.ecommerceType],
            ecommerceUrl: [this.itemModel.ecommerceUrl],
            ecommerceUser: [this.itemModel.ecommerceUser],
            ecommercePassword: [this.itemModel.ecommercePassword],

            ecommerceSkipCategorySync: [this.itemModel.ecommerceSkipCategorySync],
            ecommerceSkipProductSync: [this.itemModel.ecommerceSkipProductSync],
            getDescriptionFromFreya: [this.itemModel.getDescriptionFromFreya],
            syncDescriptionToEcommerce: [this.itemModel.syncDescriptionToEcommerce],
            syncNameToEcommerce: [this.itemModel.syncNameToEcommerce],
            syncProductsBarcodes: [this.itemModel.syncProductsBarcodes],

            hasOwnVivaWallet: [this.itemModel.hasOwnVivaWallet],
            vivaWalletTestingMode: [this.itemModel.vivaWalletTestingMode],

            vivaWalletClientId: [this.itemModel.vivaWalletClientId],
            vivaWalletClientSecret: [this.itemModel.vivaWalletClientSecret],
            vivaWalletClientSourceCode: [this.itemModel.vivaWalletClientSourceCode],
            vivaWalletTestClientId: [this.itemModel.vivaWalletTestClientId],
            vivaWalletTestClientSecret: [this.itemModel.vivaWalletTestClientSecret],
            vivaWalletTestClientSourceCode: [this.itemModel.vivaWalletTestClientSourceCode],
            createNewEcommerceProducts: [this.itemModel.createNewEcommerceProducts],
            associatedPosDevice: [this.itemModel.associatedPosDevice],

            isActive: [this.itemModel.isActive],
            callCenterConnectionData: [this.itemModel.callCenterConnectionData],
            schedule: this.formBuilder.group({
                monday: this.formBuilder.group({
                    start: [''],
                    end: [''],
                    closed: [''],
                    allDay: [''],
                }),
                tuesday: this.formBuilder.group({
                    start: [''],
                    end: [''],
                    closed: [''],
                    allDay: [''],
                }),
                wednesday: this.formBuilder.group({
                    start: [''],
                    end: [''],
                    closed: [''],
                    allDay: [''],
                }),
                thursday: this.formBuilder.group({
                    start: [''],
                    end: [''],
                    closed: [''],
                    allDay: [''],
                }),
                friday: this.formBuilder.group({
                    start: [''],
                    end: [''],
                    closed: [''],
                    allDay: [''],
                }),
                saturday: this.formBuilder.group({
                    start: [''],
                    end: [''],
                    closed: [''],
                    allDay: [''],
                }),
                sunday: this.formBuilder.group({
                    start: [''],
                    end: [''],
                    closed: [''],
                    allDay: [''],
                }),
            }),
        });

        // ?????????????????????????????/
        this.form.get('schedule').valueChanges.subscribe((value) => {
            if (value.monday.closed) {
                this.form.get('schedule.monday.start').disable({ emitEvent: false });
                this.form.get('schedule.monday.end').disable({ emitEvent: false });
                this.form.get('schedule.monday.allDay').disable({ emitEvent: false });
            } else if (value.monday.allDay) {
                this.form.get('schedule.monday.start').disable({ emitEvent: false });
                this.form.get('schedule.monday.end').disable({ emitEvent: false });
                this.form.get('schedule.monday.closed').disable({ emitEvent: false });
            } else {
                this.form.get('schedule.monday.start').enable({ emitEvent: false });
                this.form.get('schedule.monday.end').enable({ emitEvent: false });
                this.form.get('schedule.monday.allDay').enable({ emitEvent: false });
                this.form.get('schedule.monday.closed').enable({ emitEvent: false });
            }

            if (value.tuesday.closed) {
                this.form.get('schedule.tuesday.start').disable({ emitEvent: false });
                this.form.get('schedule.tuesday.end').disable({ emitEvent: false });
                this.form.get('schedule.tuesday.allDay').disable({ emitEvent: false });
            } else if (value.tuesday.allDay) {
                this.form.get('schedule.tuesday.start').disable({ emitEvent: false });
                this.form.get('schedule.tuesday.end').disable({ emitEvent: false });
                this.form.get('schedule.tuesday.closed').disable({ emitEvent: false });
            } else {
                this.form.get('schedule.tuesday.start').enable({ emitEvent: false });
                this.form.get('schedule.tuesday.end').enable({ emitEvent: false });
                this.form.get('schedule.tuesday.allDay').enable({ emitEvent: false });
                this.form.get('schedule.tuesday.closed').enable({ emitEvent: false });
            }

            if (value.wednesday.closed) {
                this.form.get('schedule.wednesday.start').disable({ emitEvent: false });
                this.form.get('schedule.wednesday.end').disable({ emitEvent: false });
                this.form.get('schedule.wednesday.allDay').disable({ emitEvent: false });
            } else if (value.wednesday.allDay) {
                this.form.get('schedule.wednesday.start').disable({ emitEvent: false });
                this.form.get('schedule.wednesday.end').disable({ emitEvent: false });
                this.form.get('schedule.wednesday.closed').disable({ emitEvent: false });
            } else {
                this.form.get('schedule.wednesday.start').enable({ emitEvent: false });
                this.form.get('schedule.wednesday.end').enable({ emitEvent: false });
                this.form.get('schedule.wednesday.allDay').enable({ emitEvent: false });
                this.form.get('schedule.wednesday.closed').enable({ emitEvent: false });
            }

            if (value.thursday.closed) {
                this.form.get('schedule.thursday.start').disable({ emitEvent: false });
                this.form.get('schedule.thursday.end').disable({ emitEvent: false });
                this.form.get('schedule.thursday.allDay').disable({ emitEvent: false });
            } else if (value.thursday.allDay) {
                this.form.get('schedule.thursday.start').disable({ emitEvent: false });
                this.form.get('schedule.thursday.end').disable({ emitEvent: false });
                this.form.get('schedule.thursday.closed').disable({ emitEvent: false });
            } else {
                this.form.get('schedule.thursday.start').enable({ emitEvent: false });
                this.form.get('schedule.thursday.end').enable({ emitEvent: false });
                this.form.get('schedule.thursday.allDay').enable({ emitEvent: false });
                this.form.get('schedule.thursday.closed').enable({ emitEvent: false });
            }

            if (value.friday.closed) {
                this.form.get('schedule.friday.start').disable({ emitEvent: false });
                this.form.get('schedule.friday.end').disable({ emitEvent: false });
                this.form.get('schedule.friday.allDay').disable({ emitEvent: false });
            } else if (value.friday.allDay) {
                this.form.get('schedule.friday.start').disable({ emitEvent: false });
                this.form.get('schedule.friday.end').disable({ emitEvent: false });
                this.form.get('schedule.friday.closed').disable({ emitEvent: false });
            } else {
                this.form.get('schedule.friday.start').enable({ emitEvent: false });
                this.form.get('schedule.friday.end').enable({ emitEvent: false });
                this.form.get('schedule.friday.allDay').enable({ emitEvent: false });
                this.form.get('schedule.friday.closed').enable({ emitEvent: false });
            }

            if (value.saturday.closed) {
                this.form.get('schedule.saturday.start').disable({ emitEvent: false });
                this.form.get('schedule.saturday.end').disable({ emitEvent: false });
                this.form.get('schedule.saturday.allDay').disable({ emitEvent: false });
            } else if (value.saturday.allDay) {
                this.form.get('schedule.saturday.start').disable({ emitEvent: false });
                this.form.get('schedule.saturday.end').disable({ emitEvent: false });
                this.form.get('schedule.saturday.closed').disable({ emitEvent: false });
            } else {
                this.form.get('schedule.saturday.start').enable({ emitEvent: false });
                this.form.get('schedule.saturday.end').enable({ emitEvent: false });
                this.form.get('schedule.saturday.allDay').enable({ emitEvent: false });
                this.form.get('schedule.saturday.closed').enable({ emitEvent: false });
            }

            if (value.sunday.closed) {
                this.form.get('schedule.sunday.start').disable({ emitEvent: false });
                this.form.get('schedule.sunday.end').disable({ emitEvent: false });
                this.form.get('schedule.sunday.allDay').disable({ emitEvent: false });
            } else if (value.sunday.allDay) {
                this.form.get('schedule.sunday.start').disable({ emitEvent: false });
                this.form.get('schedule.sunday.end').disable({ emitEvent: false });
                this.form.get('schedule.sunday.closed').disable({ emitEvent: false });
            } else {
                this.form.get('schedule.sunday.start').enable({ emitEvent: false });
                this.form.get('schedule.sunday.end').enable({ emitEvent: false });
                this.form.get('schedule.sunday.allDay').enable({ emitEvent: false });
                this.form.get('schedule.sunday.closed').enable({ emitEvent: false });
            }
        });

        this.form.controls['hasOwnVivaWallet'].valueChanges.subscribe((value: boolean) => {
            this.hasVivaWallet = value;
        });
    }

    mapScheduleDatesToForm(schedule: ScheduleModel): void {
        Object.keys(schedule).forEach((key: string) => {
            const day: ScheduleDayModel = schedule[key];

            const formKey = `schedule.${key}`;

            const formControl = this.form.get(formKey);

            let start = null;
            if (day.start) {
                start = new Date(day.start);
            }

            let end = null;
            if (day.end) {
                end = new Date(day.end);
            }

            formControl.patchValue({
                start: start,
                end: end,
            });
        });
    }

    getItem(): void {
        this.loading = true;
        this.locationService
            .getItem(this.uid)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe((response: ApiResponseModel) => {
                this.castScheduleToBools(response.payload);
                this.itemModel.map(response.payload);
                this.itemModel$.next(this.itemModel);

                if (this.itemModel.company) {
                    this.company_uid = this.itemModel.company.uid;
                    this.hasPolygons = this.itemModel.company.hasPolygons;
                    this.hasFreyaCloud = this.itemModel.company.freyaCloud;
                    if (!this.hasPolygons && response.payload.freyaDeliveryProductCost.length === 0) {
                        this.freyaDeliveryProductCost.push(
                            this.formBuilder.group({
                                polygonUid: [null],
                                productCost: [0, [Validators.required, Validators.min(0)]],
                                hasFreeThreshold: [false],
                                freeDeliveryThreshold: [0, Validators.min(0)],
                                promoFees: [[]],
                            })
                        );
                    }
                }

                if (response.payload.tag) {
                    this.tags = response.payload.tag.split(',');
                } else {
                    this.tags = [];
                }

                if (!this.itemModel) {
                    this.router.navigate(['/notfound']).then();
                }

                this.ecommerceModule = this.itemModel.company.modules.find(
                    (element) => element.module === 'ecommerce'
                ).active;
                this.cloudCompany = this.itemModel.company.freyaCloud;
                this.form.patchValue(this.itemModel);
                this.mapScheduleDatesToForm(response.payload.schedule);
                this.form.get('tag').setValue('');
                this.updateFormArray(response.payload.freyaDeliveryProductCost);
                this.form.patchValue({
                    hasDelivery: Boolean(response.payload.hasDelivery),
                    hasOwnVivaWallet: Boolean(response.payload.hasOwnVivaWallet),
                    hasPickup: Boolean(response.payload.hasPickup),
                    isDefaultLocation: Boolean(response.payload.isDefaultLocation),
                    isFreyaCloud: Boolean(response.payload.isFreyaCloud),
                    isActive: Boolean(response.payload.isActive),
                    overwritePrice: Boolean(response.payload.overwritePrice),
                    syncOrders: Boolean(response.payload.syncOrders),
                    allowDispatcher: Boolean(response.payload.allowDispatcher),
                    allowEcommerce: Boolean(response.payload.allowEcommerce),
                    ecommerceSkipCategorySync: Boolean(response.payload.ecommerceSkipCategorySync),
                    ecommerceSkipProductSync: Boolean(response.payload.ecommerceSkipProductSync),
                });
            });
    }

    updateVivaWallet(value: boolean): void {
        this.hasVivaWallet = value;
    }

    onLocalizationChange(): void {
        const lat = this.form.controls['latitude'].value;
        const lon = this.form.controls['longitude'].value;
        this.itemModel.latitude = lat;
        this.itemModel.longitude = lon;
        if (this.mapComponent) {
            this.mapComponent.addLocationLayerOnMap(lat, lon);
        }
    }

    updateTags(tags: string[]): void {
        this.tags = tags;
    }

    updateFormArray(array: any[]): void {
        for (let i = 0; i < array.length; i++) {
            this.freyaDeliveryProductCost.controls.push(
                this.formBuilder.group({
                    polygonUid: [array[i].polygonUid],
                    productCost: [array[i].productCost, [Validators.required, Validators.min(0)]],
                    hasFreeThreshold: [array[i].hasFreeThreshold],
                    freeDeliveryThreshold: [array[i].freeDeliveryThreshold, Validators.min(0)],
                    promoFees: [array[i].promoFees ? array[i].promoFees : []],
                })
            );
            this.layers.push({
                layerColour: this.coloursArray[i],
                polygonUid: array[i].polygonUid,
                areaNumber: i + 1,
                promoFees: array[i].promoFees ? array[i].promoFees : [],
            });
        }
    }

    create(): void {
        this.loading = true;

        const data = new LocationModel().map(this.form.getRawValue());
        this.castScheduleToStrings(data);

        this.locationService
            .create(data)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe({
                next: (response: ApiResponseModel) => {
                    this.itemModel.map(response.payload);
                    this.itemModel$.next(this.itemModel);
                    this.uid = this.itemModel.uid;
                    this.form.get('tag').setValue('');
                    if (response.status === true) {
                        this.messageService.add({
                            summary: 'Succes!',
                            severity: 'success',
                            detail: 'Locația a fost creată cu succes!',
                        });
                        this.router.navigate(['/nomenclatures/locations/add-edit'], {
                            queryParamsHandling: 'merge',
                            queryParams: { uid: response.payload.uid },
                        });
                    }
                },
                error: (err) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 422) {
                            this.messageService.add({
                                summary: 'Eroare!',
                                severity: 'error',
                                detail: 'Există câmpuri invalide!',
                            });
                        }
                        this.validationMessageService.serverSideValidation(err, this.form, '', true);
                    }
                },
            });
    }

    update(showMessage: boolean = true): void {
        this.loading = true;

        const data = new LocationModel().map(this.form.getRawValue());
        data.uid = this.uid;

        this.castScheduleToStrings(data);

        this.locationService
            .update(data)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe({
                next: (response: ApiResponseModel) => {
                    this.itemModel.map(response.payload);
                    this.itemModel$.next(this.itemModel);
                    this.form.get('tag').setValue('');
                    if (response.status === true) {
                        if (showMessage) {
                            this.messageService.add({
                                summary: 'Succes!',
                                severity: 'success',
                                detail: 'Locația a fost actualizată cu succes!',
                            });
                        }
                    }
                },
                error: (err) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 422) {
                            this.messageService.add({
                                summary: 'Eroare!',
                                severity: 'error',
                                detail: 'Există câmpuri invalide!',
                            });
                        }
                        this.validationMessageService.serverSideValidation(err, this.form, '', true);
                    }
                },
            });
    }

    prepareData(): void {
        if (this.form.getRawValue().freyaDeliveryProductCost.length === 0 && this.uid) {
            this.messageService.add({
                summary: 'Eroare!',
                severity: 'error',
                detail: 'Trebuie să adăugați zonele pentru livrare!',
            });
            return;
        }
        this.freyaDeliveryProductCost.controls.forEach((fdpc: AbstractControl) => {
            if (fdpc.get('freeDeliveryThreshold').value) {
                fdpc.get('hasFreeThreshold').setValue(true);
            }
        });

        this.form.get('tag').setValue(this.tags.toString());

        if (this.form.controls['allowEcommerce'].value === false) {
            const controlsToToggle: string[] = [
                'syncOrders',
                'ecommerceSkipCategorySync',
                'ecommerceSkipProductSync',
                'ecommerceUser',
                'ecommercePassword',
                'ecommerceUrl',
                'ecommerceType',
            ];

            controlsToToggle.forEach((controlKey: string) => {
                this.form.controls[controlKey].patchValue(null);
            });
        }
    }

    onSave(): void {
        this.prepareData();

        if (!this.customValidation.validateOnSubmit(this.form)) return;

        if (this.uid) {
            this.update();
        } else {
            this.create();
        }
    }

    onLayersChanges($event: Layer[]): void {
        if (!this.hasPolygons) return;
        const uids = $event.map((layer: Layer) => layer.polygonUid);
        this.freyaDeliveryProductCost.controls = this.freyaDeliveryProductCost.controls.filter((control: FormGroup) => {
            const uid = control.controls['polygonUid'].value;
            return uids.includes(uid);
        });
        $event.forEach((layer: Layer) => {
            if (
                !this.freyaDeliveryProductCost.controls.find(
                    (control: FormGroup) => control.controls['polygonUid'].value === layer.polygonUid
                )
            ) {
                this.freyaDeliveryProductCost.push(
                    this.formBuilder.group({
                        polygonUid: [layer.polygonUid, Validators.required],
                        productCost: ['0', [Validators.required, Validators.min(0)]],
                        hasFreeThreshold: [false],
                        freeDeliveryThreshold: [0, Validators.min(0)],
                        promoFees: [[]],
                    })
                );
            }
        });

        const tempLayer = this.layers;
        this.layers = [];
        $event.forEach((layer: Layer) => {
            this.layers.push({
                layerColour: layer.borderColour,
                areaNumber: layer.areaNumber,
                polygonUid: layer.polygonUid,
                promoFees: tempLayer.find((oldLayer: Layer) => oldLayer.polygonUid === layer.polygonUid)
                    ? tempLayer.find((oldLayer: Layer) => oldLayer.polygonUid === layer.polygonUid).promoFees
                    : [],
            });
        });
    }

    shouldShowLocations(): boolean {
        return this.uid && this.itemModel.company?.hasPolygons;
    }

    checkCompanyModules(): void {
        this.loading = true;
        this.companyService.getItem(this.company_uid).subscribe({
            next: (res: ApiResponseModel) => {
                this.ecommerceModule = res.payload.modules.find(
                    (element: CompanyModuleModel) => element.module === CompanyModuleEnum.ECOMMERCE
                ).active;
                this.cloudCompany = res.payload.cloudCompany;
                this.loading = false;
            },
        });
    }
}
