<p-button
    [label]="selectedRow ? selectedRow.name : 'Selectează un element'"
    icon="pi pi-search"
    (click)="onOpenOverlay($event)"
></p-button>

<p-overlayPanel #op [showCloseIcon]="true" [style]="{ width: '650px', 'z-index': '99999!important' }">
    <ng-template pTemplate>
        <app-stt-table
            #table
            [endpoint]="endpoint"
            [query]="tableQuery"
            (rowSelect)="onRowSelect($event)"
            [enableSelection]="true"
        ></app-stt-table>
    </ng-template>
</p-overlayPanel>
