import { Component } from '@angular/core';
import { SttTableComponent } from '../../../../shared/components/stt-table/stt-table.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Router } from '@angular/router';
import { ProductsAddEditComponent } from '../products-add-edit/products-add-edit.component';
import { ProductModel } from '../../../../shared/models/nomenclatures/products/product.model';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-products-action-template',
    templateUrl: './products-action-template.component.html',
    styleUrls: ['./products-action-template.component.scss'],
})
export class ProductsActionTemplateComponent {
    public data: ProductModel;
    public table: SttTableComponent;
    public dialog: DynamicDialogRef;

    constructor(public dialogService: DialogService, public router: Router, public messageService: MessageService) {}

    setParams(entity: ProductModel): void {
        this.data = entity;
    }

    setTableReference(table: SttTableComponent): void {
        this.table = table;
    }

    copyToClipboard() {
        navigator.clipboard.writeText(this.data.uid);
        this.messageService.add({
            severity: 'success',
            summary: 'UID-ul produsului a fost copiat!',
        });
    }
}
