<section>
    <h2 class="current-images-header">Imagini curente:</h2>
    <div class="image-container">
        <div class="active-image" *ngIf="defaultImage">
            <h3>Imagine implicita</h3>
            <img [src]="defaultImage.url" class="default-image" alt="imagine-produs" width="500" height="500" />
        </div>
        <div class="active-image" *ngIf="!defaultImage">
            <h2>Produsul nu are imagini!</h2>
        </div>
        <div class="image-list">
            <div class="images">
                <img
                    *ngFor="let image of images"
                    [ngClass]="{ 'default-image': image.uid === localActiveImage.uid }"
                    [ngSrc]="image.url"
                    alt="imagine-produs"
                    width="100"
                    height="100"
                    (click)="setActiveImage(image)"
                />
            </div>
            <div class="set-default-button">
                <button
                    pButton
                    type="button"
                    label="Setează implicită"
                    icon="pi pi-check"
                    (click)="updateDefaultImage()"
                ></button>
                <button
                    pButton
                    type="button"
                    class="p-button-danger"
                    label="Șterge imaginea"
                    icon="pi pi-trash"
                    (click)="deleteImage()"
                ></button>
            </div>
        </div>
    </div>

    <h2>Adaugă imagine</h2>
    <div class="image-upload">
        <p-fileUpload
            #fileUpload
            (onSelect)="onSelect($event)"
            chooseLabel="Alege"
            uploadLabel="Încarcă"
            cancelLabel="Anulează"
            fileLimit="1"
            accept="image/*"
            mode="basic"
        ></p-fileUpload>
        <button
            pButton
            type="button"
            label="Upload"
            class="p-button-success"
            icon="pi pi-cloud-upload"
            (click)="onImageUpload({ files: fileUpload.files })"
        ></button>
        <button
            pButton
            type="button"
            label="Deselectează"
            icon="pi pi-trash"
            class="p-button-danger"
            (click)="onClearImage()"
        ></button>
    </div>
    <img *ngIf="previewImageSrc" [src]="previewImageSrc" alt="imagine selectata" width="300" height="300" />
</section>
