import { Component, OnInit } from '@angular/core';
import { Params, Router } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UserModel } from '../../../../auth/models/user.model';
import { UserService } from '../../../../auth/user.service';
import { SttTableComponent } from '../../../../shared/components/stt-table/stt-table.component';
import { LocationModel } from '../../../../shared/models/nomenclatures/locations/location.model';
import { UsersAddEditComponent } from '../../users/users-add-edit/users-add-edit.component';
import { ApiResponseModel } from '../../../../shared/models/api-response.model';
import { LocationService } from '../../../../shared/services/app/nomenclatures/location.service';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-locations-action-template',
    templateUrl: './locations-action-template.component.html',
    styleUrls: ['./locations-action-template.component.scss'],
})
export class LocationsActionTemplateComponent implements OnInit {
    public data: LocationModel;
    public table: SttTableComponent;
    public dialog: DynamicDialogRef;
    isAdmin: boolean = false;

    constructor(
        public dialogService: DialogService,
        public router: Router,
        private locationService: LocationService,
        private messageService: MessageService,
        private userService: UserService
    ) {}

    ngOnInit() {
        this.isAdmin = this.userService.isAdmin();
    }

    setParams(entity: LocationModel): void {
        this.data = entity;
    }

    setTableReference(table: SttTableComponent): void {
        this.table = table;
    }

    delete() {
        this.locationService.delete(this.data).subscribe((response: ApiResponseModel) => {
            if (response.status === true) {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Locatia a fost stearsa cu succes!',
                });
                this.table.refresh();
            }
        });
    }

    copyToClipboard() {
        navigator.clipboard.writeText(this.data.uid);
        this.messageService.add({
            severity: 'success',
            summary: 'UID-ul locației a fost copiat!',
        });
    }
}
