import { ApiResponseModel } from '../../api-response.model';
import { BaseModel, BaseNomenclatureModel } from '../../base.model';
import { CompanyModel } from '../../../../auth/models/company.model';

import { ProductLocationModel } from './product-location.model';
import { ProductMenuModel } from './product.menu.model';
import { ProductImageModel } from './product-image.model';

export class ProductModel extends BaseNomenclatureModel {
    orderFlag: string;
    short_description: string;
    long_description: string;
    product_category_id: number;
    categoryName: string;
    company_id: number;
    isComplexProduct: boolean;
    productCategory?: ProductCategoryModel;
    productLocation?: ProductLocationModel;
    productLocations?: Array<ProductLocationModel>;
    company?: CompanyModel;
    dispatcher_favorite: boolean;
    weightable: boolean;
    units: number;
    comment: string;
    price: number;
    images: Array<ProductImageModel> = [];
    defaultImage: ProductImageModel | undefined;
    discount: number;
    priceWithDiscount: number;
    percentDiscount: boolean;
    menus: Array<ProductMenuModel>;
}

export class ProductCategoryModel extends BaseNomenclatureModel {
    freyaName: string;
    tags: string;
    custom_order: number;
    active: boolean;
}

export class CompanyCallCenterModel extends BaseModel {
    id: string;
    host: string;
    scheme: string;
    port: number;
    username: string;
    secret: string;
    company_id: number;
}

export class AttributeModel extends BaseNomenclatureModel {
    slug: string;
    description: string;
    company_id: number;
    company?: CompanyModel;
    woocomerce_id?: number;
    terms: Array<AttributeTermModel>;
}

export class AttributeTermModel extends BaseNomenclatureModel {
    product_id: number;
    attribute_id: string;
    attribute: AttributeModel;
    company_id: string;
    company?: CompanyModel;
}

export class ProductVariations extends BaseModel {
    _id: string;
    uid: string;
    company_id: number;
    product_id: number;
    attributes: Array<AttributeModel>;
    items: Array<ProductVariationsItem>;
}

export class ProductVariationsItem extends BaseModel {
    _id: string;
    uid: string;
    product_id: number;
    price: number;
    attributes: Array<ProductVariationsItemAttribute>;
}

export class ProductVariationsItemAttribute {
    _id: string;
    uid: string;
    variation: string;
    attribute: AttributeModel;
    attribute_term: number;
    attribute_term_units: number;
}

export class ProductApiResponseModel extends ApiResponseModel {
    override payload: ProductModel;
}
