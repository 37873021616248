import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UserModel } from '../../../../auth/models/user.model';
import { SttTableComponent } from '../../../../shared/components/stt-table/stt-table.component';
import { UsersAddEditComponent } from '../users-add-edit/users-add-edit.component';
import { ApiResponseModel } from '../../../../shared/models/api-response.model';
import { UserService } from '../../../../auth/user.service';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-users-action-template',
    templateUrl: './users-action-template.component.html',
    styleUrls: ['./users-action-template.component.scss'],
})
export class UsersActionTemplateComponent {
    public data: UserModel;
    public table: SttTableComponent;
    public dialog: DynamicDialogRef;

    constructor(
        public dialogService: DialogService,
        public router: Router,
        private userService: UserService,
        private messageService: MessageService
    ) {}

    setParams(entity: UserModel): void {
        this.data = entity;
    }

    setTableReference(table: SttTableComponent): void {
        this.table = table;
    }

    showDialog() {
        this.dialog = this.dialogService.open(UsersAddEditComponent, {
            header: 'Editare utilizator',
            width: '70%',
            contentStyle: { 'max-height': '70vh' },
            height: 'fit-content',
            baseZIndex: 10000,
            closeOnEscape: false,
            data: { uid: this.data.uid },
        });

        this.dialog.onClose.subscribe((user: UserModel) => {
            if (user) {
                this.table.refresh();
            }
        });
    }

    delete() {
        this.userService.delete(this.data).subscribe((response: ApiResponseModel) => {
            if (response.status === true) {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Utilizatorul a fost șters cu succes!',
                });
                this.table.refresh();
            }
        });
    }

    copyToClipboard() {
        navigator.clipboard.writeText(this.data.uid);
        this.messageService.add({
            severity: 'success',
            summary: 'UID-ul utilizatorului a fost copiat!',
        });
    }
}
