import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompanyApiResponseModel, CompanyModel } from '../../../../auth/models/company.model';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { CustomValidationService } from '../../../../shared/services/app/custom-validation.service';
import { ValidationMessageService } from '../../../../shared/services/base/validation-message.service';
import { MessageService } from 'primeng/api';
import { CompanyService } from '../../../../shared/services/app/nomenclatures/company.service';
import { finalize } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyModuleEnum } from 'src/app/shared/enum/company-module.enum';
import { CompanyModuleService } from '../../../../shared/services/app/company-module.service';

@Component({
    selector: 'app-companies-add-edit',
    templateUrl: './companies-add-edit.component.html',
    styleUrls: ['./companies-add-edit.component.scss'],
})
export class CompaniesAddEditComponent implements OnInit {
    form: FormGroup;
    itemModel: CompanyModel = new CompanyModel();
    loading: boolean = false;
    uid: string = null;
    callCenterData: FormGroup;
    hasFreyaCloud: boolean = false;
    hasGoogleGeo: boolean = false;
    digitalMenuModule: boolean = false;
    dispecerModule: boolean = false;
    @Input() onMyCompany = false;
    constructor(
        private formBuilder: FormBuilder,
        public dialog: DynamicDialogRef,
        private route: ActivatedRoute,
        private router: Router,
        private companyService: CompanyService,
        private validationMessageService: ValidationMessageService,
        private messageService: MessageService,
        private customValidation: CustomValidationService,
        private readonly companyModuleService: CompanyModuleService
    ) {}

    ngOnInit(): void {
        if (!this.onMyCompany) {
            if (this.route.snapshot.queryParams.hasOwnProperty('uid')) {
                this.uid = this.route.snapshot.queryParams['uid'];
            }
        } else {
            this.uid = this.companyService.company.uid;
        }

        this.createForm();

        this.form.controls['freyaType'].valueChanges.subscribe((value: string) => {
            switch (value) {
                case 'local':
                    this.hasFreyaCloud = false;
                    break;
                case 'cloud':
                    this.hasFreyaCloud = true;
                    break;
                default:
                    console.error(`Eroare la detectarea tipului de Freya, am primit ${value}.`);
                    break;
            }
        });

        this.form.controls['usesGoogleGeo'].valueChanges.subscribe((value: boolean) => {
            const apiKeyInput: AbstractControl = this.form.controls['googleApiKey'];
            if (value === true) {
                this.hasGoogleGeo = true;
                apiKeyInput.enable();
            } else {
                this.hasGoogleGeo = false;
                apiKeyInput.disable();
            }
        });

        if (this.uid) {
            this.getItem();
        }
    }

    createForm(): void {
        this.form = this.formBuilder.group({
            uid: [this.itemModel.uid],
            name: [this.itemModel.name, Validators.required],
            uniqueCode: [this.itemModel.uniqueCode, Validators.required],
            nrRegCom: [this.itemModel.nrRegCom, Validators.required],
            email: [this.itemModel.email],
            phone: [this.itemModel.phone],
            locationsLimit: [
                this.itemModel.locationsLimit,
                [Validators.required, Validators.min(1), Validators.max(100)],
            ],
            usersLimit: [this.itemModel.usersLimit, Validators.required],
            website: [this.itemModel.website],
            isActive: [this.itemModel.isActive],
            usesGoogleGeo: [this.itemModel.usesGoogleGeo],
            googleApiKey: [this.itemModel.googleApiKey],
            hasPolygons: [this.itemModel.hasPolygons],
            county_id: [this.itemModel.county_id],
            freyaType: [this.itemModel.freyaType],
            freyaCloudUsername: [this.itemModel.freyaCloudUsername],
            freyaCloudPassword: [this.itemModel.freyaCloudPassword],
            freyaCloudUrl: [this.itemModel.freyaCloudUrl],
            freyaCloudApiKey: [this.itemModel.freyaCloudApiKey],
            freyaCloudPaymentType: [this.itemModel.freyaCloudPaymentType],
            freyaCloudOutputNoticeType: [this.itemModel.freyaCloudOutputNoticeType],
            freyaCloudRepositoryUid: [this.itemModel.freyaCloudRepositoryUid],
            syncStocks: [false],
            sendPaymentOnOrderDescription: [this.itemModel.sendPaymentOnOrderDescription],
            sendBuildingDataOnOrderDescription: [this.itemModel.sendBuildingDataOnOrderDescription],
            sendOrderToKitchen: [this.itemModel.sendOrderToKitchen],
            callCenterData: this.formBuilder.group({
                host: [this.itemModel.callCenterData.host],
                scheme: [this.itemModel.callCenterData.scheme],
                port: [this.itemModel.callCenterData.port],
                username: [this.itemModel.callCenterData.username],
                secret: [this.itemModel.callCenterData.secret],
            }),
            modules: this.formBuilder.array([]),
            sendMappingSummaries: [this.itemModel.sendMappingSummaries],
            mappingSummaryEmail: [this.itemModel.mappingSummaryEmail],
        });

        this.callCenterData = this.form.controls['callCenterData'] as FormGroup;

        Object.values(CompanyModuleEnum).forEach((value) => {
            this.addModule(value, false);
        });
    }

    getItem(): void {
        this.loading = true;
        this.companyService
            .getItem(this.uid)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe((response: CompanyApiResponseModel) => {
                const freyaLocal: boolean = Boolean(response.payload.freyaLocal);
                const freyaCloud: boolean = Boolean(response.payload.freyaCloud);
                const isActive: boolean = Boolean(response.payload.isActive);

                const freyaType: string = this.getFreyaType(freyaLocal, freyaCloud);

                this.itemModel.map(response.payload);
                this.form.patchValue({
                    freyaType: freyaType,
                    isActive: isActive,
                });
                this.form.patchValue(this.itemModel);
                this.checkActiveModules();
            });
    }

    getFreyaType(freyaLocal: boolean, freyaCloud: boolean): string {
        if (freyaLocal === true) {
            return 'local';
        } else if (freyaCloud === true) {
            return 'cloud';
        } else {
            return '';
        }
    }

    get modules() {
        return this.form.get('modules') as FormArray;
    }

    checkActiveModules() {
        this.digitalMenuModule = this.companyModuleService.hasModule(CompanyModuleEnum.DIGITAL_MENU, this.itemModel);
        this.dispecerModule = this.companyModuleService.hasModule(CompanyModuleEnum.DISPATCHER, this.itemModel);
    }

    addModule(moduleType: CompanyModuleEnum, active: boolean) {
        const moduleForm = this.formBuilder.group({
            module: [moduleType, Validators.required],
            active: [active, Validators.required],
        });

        this.modules.push(moduleForm);
    }

    setFreyaType(data: CompanyModel): void {
        if (data.freyaType === 'local') {
            data.freyaLocal = true;
            data.freyaCloud = false;
        } else if (data.freyaType === 'cloud') {
            data.freyaLocal = false;
            data.freyaCloud = true;
        }

        delete data.freyaType;
    }

    removeGoogleInfo(data: CompanyModel): void {
        if (!this.hasGoogleGeo) {
            data.googleApiKey = null;
        }
    }

    removeFreyaCloudInfo(data: CompanyModel): void {
        if (!this.hasFreyaCloud) {
            data.freyaCloudUrl = null;
            data.freyaCloudUsername = null;
            data.freyaCloudPassword = null;
            data.freyaCloudApiKey = null;
        }
    }

    prepareData(data: CompanyModel): void {
        this.removeFreyaCloudInfo(data);
        this.setFreyaType(data);
        this.removeGoogleInfo(data);
    }

    onSubmit(): void {
        if (!this.customValidation.validateOnSubmit(this.form)) return;

        let data: CompanyModel = this.form.getRawValue();
        this.prepareData(data);

        if (!this.uid) {
            this.create(data);
        } else {
            this.update(data);
        }

        this.loading = true;
    }

    create(data: any): void {
        this.loading = true;
        this.companyService
            .create(data)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe({
                next: (response: CompanyApiResponseModel) => {
                    this.itemModel.map(response.payload);
                    this.form.patchValue(this.itemModel);
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Compania a fost salvată cu success!',
                    });
                    this.dialog.close(this.itemModel);
                },
                error: (err) => {
                    if (err instanceof HttpErrorResponse) {
                        this.validationMessageService.serverSideValidation(err, this.form, '', true);
                    }
                },
            });
    }

    update(data: any): void {
        this.loading = true;
        this.companyService
            .update(data)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe({
                next: (response: CompanyApiResponseModel) => {
                    this.itemModel.map(response.payload);
                    this.form.patchValue(this.itemModel);
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Compania a fost editată cu succes!',
                    });
                    if (!this.onMyCompany) {
                        this.router.navigate(['/nomenclatures/companies/list']);
                        return;
                    }
                    this.checkActiveModules();
                },
                error: (err) => {
                    if (err instanceof HttpErrorResponse) {
                        this.validationMessageService.serverSideValidation(err, this.form, '', true);
                    }
                },
            });
    }
}
