import Pusher, { Options } from 'pusher-js';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpApiService } from '../http-api.service';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class PusherService {
    public pusher: Pusher;
    public channel: any;

    private appKey = 'freya_sync';
    private secret = 'freya_sync_secret';
    private appId = '1477';
    private cluster = 'eu';
    private httpPort = 80;
    private httpPath = 'websockets';
    private wsPort = environment.wsPort;
    private wsPath = '';

    private endPointUrl = environment.endPointUrl;
    private authEndpoint = environment.authEndpoint;
    private httpHost = environment.httpHost;
    private wsHost = environment.wsHost;

    constructor(private http: HttpApiService) {
        this.connect();
    }

    connect(): void {
        Pusher.logToConsole = false;

        const options: Options = {
            channelAuthorization: {
                endpoint: this.authEndpoint,
                transport: 'ajax',
                headers: {
                    Accept: '*/*',
                    Authorization: this.http.getAuthToken(),
                    'Access-Control-Allow-Headers': '*',
                    'Access-Control-Allow-Origin': '*',
                    'x-app-id': this.appId,
                },
            },
            cluster: this.cluster,
            httpHost: this.httpHost,
            httpPort: this.httpPort,
            httpPath: this.httpPath,
            wsHost: this.wsHost,
            wsPort: this.wsPort,
            wsPath: this.wsPath,

            wssPort: this.wsPort,

            enabledTransports: ['ws'],
            disabledTransports: ['xhr_streaming', 'xhr_polling', 'sockjs', 'wss'],
            forceTLS: false,
        };

        this.pusher = new Pusher(this.appKey, options);
    }

    sendData(channel: string, event: string, object: any): Observable<any> {
        const formData = new FormData();
        formData.append('key', this.appKey);
        formData.append('appId', this.appId);
        formData.append('secret', this.secret);
        formData.append('channel', channel);
        formData.append('event', event);
        formData.append('data', JSON.stringify(object));

        return this.http.httpSocketPost(this.endPointUrl, formData);
    }
}
