import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponseModel } from '../../../models/api-response.model';
import { HttpApiService } from '../../base/http-api.service';

@Injectable({
    providedIn: 'root',
})
export class OrderService {
    constructor(private http: HttpApiService) {}

    public get(order_uid: string): Observable<ApiResponseModel> {
        return this.http.httpGet('order/show', {
            uid: order_uid,
        });
    }
}
