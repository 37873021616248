import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ProductLocationModel } from '../../../../../shared/models/nomenclatures/products/product-location.model';
import { ProductsAddEditComponent } from '../products-add-edit.component';
import { CompanyModel } from 'src/app/auth/models/company.model';

@Component({
    selector: 'app-products-add-edit-data',
    templateUrl: './products-add-edit-data.component.html',
    styleUrls: ['./products-add-edit-data.component.scss'],
})
export class ProductsAddEditDataComponent implements OnInit {
    @Input() form: FormGroup;
    @Input() dataset: ProductLocationModel[];
    @Input() productUid: string;
    @Input() company: CompanyModel;

    locations: FormArray;

    constructor() {}

    ngOnInit() {
        this.locations = this.form.controls['locations'] as FormArray;
    }
}
