import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { BootstrapService } from '../shared/services/app/bootstrap.service';
import { BootstrapCodeEnum } from '../shared/enum/bootstrap-code.enum';
import { UserService } from '../auth/user.service';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
    private loaded$ = new ReplaySubject<boolean>(1);

    constructor(
        private readonly router: Router,
        private readonly bootstrapService: BootstrapService,
        private readonly userService: UserService
    ) {}

    get loaded(): Observable<boolean> {
        return this.loaded$.asObservable();
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        this.loaded$.next(false);
        return new Promise((resolve) => {
            if (this.userService.user) {
                if (route.data.needsAdmin && !this.userService.isAdmin()) {
                    resolve(this.router.parseUrl('/dashboard'));
                    this.loaded$.next(true);
                    return;
                }
                resolve(true);
                this.loaded$.next(true);
                return;
            }
            this.bootstrapService.bootstrap(
                () => {
                    if (this.router.url === '/unavailable') {
                        const urlTree: UrlTree = this.router.parseUrl('/dashboard');
                        resolve(urlTree);
                        this.loaded$.next(true);
                        return;
                    }

                    if (route.data.needsAdmin && !this.userService.isAdmin()) {
                        resolve(this.router.parseUrl('/dashboard'));
                        this.loaded$.next(true);
                        return;
                    }
                    this.loaded$.next(true);
                    resolve(true);
                },
                (code: BootstrapCodeEnum) => {
                    switch (code) {
                        case BootstrapCodeEnum.UNAVAILABLE_API:
                            if (this.router.url === '/unavailable') {
                                resolve(false);
                                this.loaded$.next(true);
                                return;
                            }
                            resolve(this.router.parseUrl('unavailable'));
                            this.loaded$.next(true);
                            break;
                        case BootstrapCodeEnum.UNAUTHORIZED:
                            const urlTree: UrlTree = this.router.parseUrl(`/auth?returnUrl=${state.url}`);
                            resolve(urlTree);
                            this.loaded$.next(true);
                            return;
                        default:
                            resolve(false);
                            this.loaded$.next(true);
                    }
                }
            );
        });
    }
}
