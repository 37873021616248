<div class="grid mt-2" [formGroup]="form">
    <div class="grid col-12">
        <div class="col-6 pr-3">
            <app-stt-input
                [control]="form.controls['name']"
                type="text"
                inputId="name"
                icon="pi pi-home"
                label="Denumire"
            ></app-stt-input>
        </div>
        <div class="col-6 pl-3 border-left-1 border-gray-400">
            <app-stt-dropdown
                endPoint="company/index"
                [readonly]="company_id"
                [fields]="{ text: 'name', value: 'id' }"
                label="Companie"
                [formControlParent]="form.controls['company_id']"
                icon="pi pi-building"
            ></app-stt-dropdown>
        </div>
    </div>
    <div class="col-12 grid">
        <div class="col-12">
            <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                    <i class="pi pi-hashtag"></i>
                </span>
                <div class="p-float-label">
                    <p-chips
                        [(ngModel)]="tags"
                        [ngModelOptions]="{ standalone: true }"
                        (onAdd)="tagsChanged()"
                        (onRemove)="tagsChanged()"
                        (onClear)="tagsChanged()"
                        inputId="tags"
                        separator=","
                    ></p-chips>
                    <label for="tags">Etichete</label>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 grid">
        <div
            [ngClass]="{
                'col-6': shouldShowLocationProduct,
                'col-12': !shouldShowLocationProduct
            }"
        >
            <app-stt-input
                [control]="form.controls['callCenterConnectionData']"
                label="Date call center"
                inputId="callCenterConnectionData"
                icon="pi pi-phone"
                type="text"
            ></app-stt-input>
        </div>
        <div *ngIf="shouldShowLocationProduct" class="col-6">
            <app-stt-dropdown
                [endPoint]="'freya-sync/getProducts/' + locationUid"
                [fields]="{ text: 'name', value: 'id' }"
                label="Produs livrare (Freya)"
                [formControlParent]="form.controls['freyaDeliveryProduct']"
                icon="pi pi-box"
            ></app-stt-dropdown>
        </div>
    </div>
    <div class="col-12">
        <p-divider></p-divider>
    </div>
    <div class="col-12 grid">
        <div class="col-6">
            <div class="checkboxes">
                <p-checkbox label="Oferă livrare" [binary]="true" formControlName="hasDelivery"></p-checkbox>
                <p-checkbox label="Oferă comandă cu ridicare" [binary]="true" formControlName="hasPickup"></p-checkbox>
            </div>
        </div>
        <div class="col-6">
            <div class="checkboxes">
                <p-checkbox
                    label="Cont propriu de Viva Wallet"
                    [binary]="true"
                    formControlName="hasOwnVivaWallet"
                ></p-checkbox>
                <p-checkbox label="Activă" [binary]="true" formControlName="isActive"></p-checkbox>
            </div>
        </div>
    </div>
</div>
