import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ComplexProductConfigTypeEnum } from '../../../../../shared/enum/complex-product-config-type.enum';
import { CompanyService } from '../../../../../shared/services/app/nomenclatures/company.service';
import { ProductModel } from '../../../../../shared/models/nomenclatures/products/product.model';

@Component({
    selector: 'app-complex-config-type',
    templateUrl: './complex-config-type.component.html',
    styleUrls: ['./complex-config-type.component.scss'],
})
export class ComplexConfigTypeComponent implements OnInit {
    locationEndpoint: string;

    @Input() configType: ComplexProductConfigTypeEnum;
    @Input() form: FormGroup;
    @Input() product: ProductModel;

    @Output() configTypeChange: EventEmitter<ComplexProductConfigTypeEnum> =
        new EventEmitter<ComplexProductConfigTypeEnum>();
    constructor(private companyService: CompanyService) {}

    ngOnInit() {
        this.locationEndpoint = 'location/getLocationsForProduct?productId=' + this.product.id;
    }

    updateConfigType(type: ComplexProductConfigTypeEnum) {
        this.configType = type;
        this.configTypeChange.emit(type);
    }
}
