export enum AreaColourEnum {
    AZURE_DARK = '#1a84a8',
    BLUE_DARK = '#385de7',
    FOREST_GREEN_DARK = '#1d641c',
    FOREST_LIGHT_GREEN_DARK = '#2ca128',
    GOLDENROD_DARK = '#e8c238',
    FIREBRICK_DARK = '#a83e1b',
    SADDLE_BROWN_DARK = '#671c15',
}

export enum BorderColourEnum {
    AZURE_LIGHT = '#1a9a98',
    BLUE_LIGHT = '#387ae7',
    FOREST_GREEN_LIGHT = '#1d511c',
    FOREST_LIGHT_GREEN_LIGHT = '#40a128',
    GOLDENROD_LIGHT = '#e8d138',
    FIREBIRCK_LIGHT = '#a8561b',
    SADDLE_BROWN_LIGHT = '#7c2219',
}
