import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UserModel } from '../../../auth/models/user.model';
import { SttTableService } from '../../../shared/components/stt-table/stt-table.service';
import { SttTableComponent } from '../../../shared/components/stt-table/stt-table.component';
import { UsersActionTemplateComponent } from './users-action-template/users-action-template.component';
import { UsersAddEditComponent } from './users-add-edit/users-add-edit.component';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
    public tableEndpoint = 'user/index';
    public tableQuery: SttTableService;
    public actionTemplate = UsersActionTemplateComponent;
    public dialog: DynamicDialogRef;

    @ViewChild('table') table: SttTableComponent;

    constructor(private dialogService: DialogService) {}

    ngOnInit(): void {
        this.tableQuery = new SttTableService();
    }

    onAdd(): void {
        this.dialog = this.dialogService.open(UsersAddEditComponent, {
            header: 'Adaugare utilizator',
            width: '70%',
            contentStyle: { 'max-height': '70vh' },
            height: 'fit-content',
            baseZIndex: 10000,
            closeOnEscape: false,
            data: null,
        });

        this.dialog.onClose.subscribe((user: UserModel) => {
            if (user) {
                this.table.refresh();
            }
        });
    }
}
