import { Component, OnInit, Input } from '@angular/core';
import { finalize } from 'rxjs';
import { ApiResponseModel } from '../../../../../shared/models/api-response.model';
import { LocationModel } from '../../../../../shared/models/nomenclatures/locations/location.model';
import { ProductLocationModel } from '../../../../../shared/models/nomenclatures/products/product-location.model';
import {
    ProductMenuItemModel,
    ProductMenuModel,
} from '../../../../../shared/models/nomenclatures/products/product.menu.model';
import { ProductModel } from '../../../../../shared/models/nomenclatures/products/product.model';
import { ProductService } from '../../../../../shared/services/app/nomenclatures/product.service';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { LoginComponent } from '../../../../../auth/login/login.component';

@Component({
    selector: 'app-complex-rules',
    templateUrl: './complex-rules.component.html',
    styleUrls: ['./complex-rules.component.scss'],
})
export class ComplexRulesComponent implements OnInit {
    location: LocationModel;
    loading: boolean = false;
    menus: Array<ProductMenuModel>;
    checkedValues: Map<string, boolean> = new Map<string, boolean>();
    configurableProductIdInFreya: string = null;
    selectedMenu: string;
    configurableSteps: Array<ProductMenuModel> = new Array<ProductMenuModel>();
    restrictionNumber: Map<string, number> = new Map<string, number>();
    existingRestrictions: Map<string, Array<string>> = new Map<string, Array<string>>();
    isDialog = false;
    @Input() product: ProductModel;
    @Input() locationId: number;

    productLocation: ProductLocationModel;

    constructor(private productService: ProductService, private dialogConfig: DynamicDialogConfig) {}

    ngOnInit(): void {
        if (this.dialogConfig.data) {
            this.setDataFromDialogConfig();
            this.isDialog = true;
        }
        this.loading = true;
        this.configurableProductIdInFreya = undefined;
        const productLocations = this.product.productLocations;
        this.productLocation = productLocations.find(
            (productLocation: ProductLocationModel) => productLocation.location_id === this.locationId
        );
        this.productService.getProductMenus(this.productLocation.id).subscribe((response: ApiResponseModel) => {
            this.menus = response.payload;
            this.menus.forEach((menu: ProductMenuModel) => {
                menu.products.forEach((product: ProductMenuItemModel) => {
                    this.checkedValues.set(menu.id + '_' + product.id, false);
                });
            });
        });
        this.getItem(this.locationId);
    }

    setDataFromDialogConfig() {
        this.product = this.dialogConfig.data['product'];
        this.locationId = this.dialogConfig.data['location_id'];
    }

    private getItem(location_id: number): void {
        this.loading = true;
        this.productService
            .getProductRestrictions(this.product.id, location_id)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe((response: ApiResponseModel) => {
                if (!response.payload.rules) return;
                Object.entries(response.payload.rules).forEach(([menu, productRestriction]) => {
                    Object.entries(productRestriction as object).forEach(([product, restrictionValue]) => {
                        let restrictionNumber = 0;
                        const restrictedItems = new Array<string>();

                        Object.entries(restrictionValue as object).forEach(
                            ([restrictedMenu, restrictedProduct], index: number) => {
                                if (typeof restrictedProduct === 'string') {
                                    restrictedProduct = JSON.parse(restrictedProduct);
                                } else if (typeof restrictedProduct === 'object') {
                                    restrictedProduct = Object.values(restrictedProduct as {});
                                }
                                restrictedProduct.forEach((productName: string) => {
                                    restrictionNumber++;
                                    restrictedItems.push(restrictedMenu + '_' + productName);
                                });
                            }
                        );

                        this.restrictionNumber.set(menu + '_' + product, restrictionNumber);
                        this.existingRestrictions.set(menu + '_' + product, restrictedItems);
                    });
                });
            });
    }

    getConfigurations(menuCode: string, productCode: string): void {
        this.configurableSteps = new Array<ProductMenuModel>();
        this.configurableProductIdInFreya = productCode;
        this.selectedMenu = menuCode;

        let found = false;

        let currentRestrictions = this.existingRestrictions.get(
            this.selectedMenu + '_' + this.configurableProductIdInFreya
        );

        this.menus.forEach((menu: ProductMenuModel) => {
            menu.products.forEach((product: ProductMenuItemModel) => {
                if (currentRestrictions) {
                    product.selected = currentRestrictions.includes(menu.code + '_' + product.code);
                } else {
                    product.selected = false;
                }
            });

            if (found) {
                this.configurableSteps.push(menu);
            }

            if (menu.code === menuCode) {
                found = true;
            }
        });
    }

    getProductModelFromMenuById(productCode: string): ProductMenuItemModel {
        let productObject = null;

        this.menus.forEach((menu: ProductMenuModel) => {
            menu.products.forEach((product: ProductMenuItemModel) => {
                if (product.code === productCode) {
                    productObject = product;
                    return;
                }
                if (productObject) {
                    return;
                }
            });
        });
        return productObject;
    }

    public onSave() {
        const allowedProducts = new Map<string, Array<string>>();
        this.configurableSteps.forEach((menu: ProductMenuModel) => {
            const productId = menu.products.map((product: ProductMenuItemModel) => {
                if (product.selected === true) {
                    return product.code;
                } else {
                    return undefined;
                }
            });
            allowedProducts.set(menu.code, productId);
        });

        this.saveRestrictions(allowedProducts);
    }

    onClear(): void {
        this.configurableSteps.forEach((menu: ProductMenuModel) => {
            menu.products.forEach((product: ProductMenuItemModel) => {
                product.selected = false;
            });
        });

        const allowedProducts = new Map<string, Array<string>>();
        this.configurableSteps.forEach((menus: ProductMenuModel) => {
            allowedProducts.set(menus.code, undefined);
        });

        this.saveRestrictions(allowedProducts);
    }

    private saveRestrictions(allowedProducts: Map<string, Array<string>>) {
        const location_id = this.locationId;

        this.productService
            .saveProductRestrictions(
                this.productLocation.id,
                location_id,
                this.selectedMenu,
                this.configurableProductIdInFreya,
                allowedProducts
            )
            .subscribe(() => {
                this.selectedMenu = null;
                this.configurableProductIdInFreya = null;
                const location_id = this.locationId;
                this.getItem(location_id);
            });
    }
}
