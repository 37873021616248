<form (ngSubmit)="onSave()">
    <p-tabView>
        <p-tabPanel header="Date client" leftIcon="pi pi-user">
            <app-customers-add-edit-data [form]="form"></app-customers-add-edit-data>
        </p-tabPanel>
        <p-tabPanel header="Adrese client" leftIcon="pi pi-home" [disabled]="!this.uid">
            <ng-template pTemplate="content">
                <app-customers-add-edit-addresses
                    (addressAdded)="addAddress()"
                    [form]="form"
                ></app-customers-add-edit-addresses>
            </ng-template>
        </p-tabPanel>
    </p-tabView>

    <p-progressBar mode="indeterminate" *ngIf="loading"></p-progressBar>
    <p-divider></p-divider>
    <div class="actions-area">
        <div class="actions-left">
            <button
                pButton
                pRipple
                type="button"
                label="Anulare"
                class="p-button-raised p-button-danger"
                icon="pi pi-ban"
                [loading]="loading"
                (click)="onCancel()"
            ></button>
        </div>
        <div class="actions-right">
            <button
                pButton
                pRipple
                type="submit"
                label="Salveaza"
                class="p-button-raised p-button-success p-outlined-button"
                icon="pi pi-save"
                [loading]="loading"
            ></button>
        </div>
    </div>
</form>
