import { Component, OnInit } from '@angular/core';
import { UserModel } from '../../auth/models/user.model';
import { UserService } from '../../auth/user.service';

@Component({
    selector: 'app-user-info',
    templateUrl: './user-info.component.html',
    styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent implements OnInit {
    user: UserModel;
    expand: boolean = false;
    userName: string;
    initials: string = '';

    constructor(public userService: UserService) {}

    ngOnInit(): void {
        this.user = this.userService.user;
        this.userName = this.user.name;
        let temp = this.userName.split(' ');
        temp.forEach((name: string) => {
            this.initials += name[0];
        });
    }
}
