import { BaseModel } from './base.model';

export class ApiResponseModel extends BaseModel {
    status: boolean;
    message: string;
    payload: any;
}

export class ApiOdataResponseModel extends BaseModel {
    value: any;
}
