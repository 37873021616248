import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PropagationResultModel } from '../../../../../shared/models/propagation-result.model';

@Component({
    selector: 'app-location-info',
    templateUrl: './location-info.component.html',
    styleUrls: ['./location-info.component.scss'],
})
export class LocationInfoComponent {
    @Input() locationData: Array<{ name: string; id: number }>;
    @Input() propagationResult: Array<PropagationResultModel>;
    @Output() propagationResultChange = new EventEmitter<Array<PropagationResultModel>>();
    @Input() selectedLocations: Array<number>;
    @Output() selectedLocationsChange = new EventEmitter<Array<number>>();
    @Output() openManualConfiguration = new EventEmitter<number>();

    constructor() {}

    updateActive(data: { name: string; id: number }) {
        if (this.selectedLocations.includes(data.id)) {
            this.selectedLocations = this.selectedLocations.filter((id: number) => id !== data.id);
        } else {
            this.selectedLocations.push(data.id);
        }
        this.selectedLocationsChange.emit(this.selectedLocations);
    }

    openRuleConfig(location_id: number): void {
        this.openManualConfiguration.emit(location_id);
    }

    hasFailed(locationId: number): boolean {
        const result = this.propagationResult.find(
            (result: PropagationResultModel) => result.location_id === locationId
        );
        if (!result || result.status) return false;
        return true;
    }
}
