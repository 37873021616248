import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs';
import { ApiResponseModel } from '../../../../shared/models/api-response.model';
import { LocationModel } from '../../../../shared/models/nomenclatures/locations/location.model';
import { ProductLocationModel } from '../../../../shared/models/nomenclatures/products/product-location.model';
import {
    ProductApiResponseModel,
    ProductCategoryModel,
    ProductModel,
} from '../../../../shared/models/nomenclatures/products/product.model';
import { CustomValidationService } from '../../../../shared/services/app/custom-validation.service';
import { LocationService } from '../../../../shared/services/app/nomenclatures/location.service';
import { ProductService } from '../../../../shared/services/app/nomenclatures/product.service';
import { ValidationMessageService } from '../../../../shared/services/base/validation-message.service';
import { ActivatedRoute, Route } from '@angular/router';
import { ProductCategoriesAddEditDataComponent } from '../product-categories-add-edit-data/product-categories-add-edit-data.component';
import { CategoryService } from 'src/app/shared/services/app/nomenclatures/category.service';
import { Router } from '@angular/router';
import { ResetPasswordRequestModel } from 'src/app/auth/models/recover-password-request.model';
@Component({
    selector: 'app-product-categories-add-edit',
    templateUrl: './product-categories-add-edit.component.html',
    styleUrls: ['./product-categories-add-edit.component.scss'],
})
export class ProductCategoriesAddEditComponent implements OnInit {
    form: FormGroup;
    itemModel: ProductCategoryModel = new ProductCategoryModel();
    loading: boolean = false;
    uid: string;
    tags: string[] = [];

    constructor(
        private formBuilder: FormBuilder,
        private categoryService: CategoryService,
        private locationService: LocationService,
        private messageService: MessageService,
        private validationMessageService: ValidationMessageService,
        private customValidation: CustomValidationService,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit(): void {
        if (this.route.snapshot.queryParams['uid']) {
            this.uid = this.route.snapshot.queryParams['uid'];
        }
        if (this.uid) {
            this.getItem();
        }
        this.createForm();
    }

    createForm(): void {
        this.form = this.formBuilder.group({
            uid: [this.itemModel.uid],
            name: [this.itemModel.name],
            custom_order: [this.itemModel.custom_order],
            active: [this.itemModel.active],
            tags: [this.itemModel.tags],
        });
    }

    getItem(): void {
        this.loading = true;
        this.categoryService
            .getItem(this.uid)
            .pipe(
                finalize(() => {
                    this.loading = false;
                })
            )
            .subscribe((response: ApiResponseModel) => {
                if (response.payload.tags) {
                    this.tags = response.payload.tags.split(',');
                    response.payload.tags = this.tags;
                } else {
                    this.tags = [];
                }
                this.itemModel.map(response.payload);
                this.form.patchValue(this.itemModel);
            });
    }

    onSubmit(): void {
        if (!this.customValidation.validateOnSubmit(this.form)) return;
        this.loading = true;
        this.form.get('tags').setValue(this.tags.toString());
        const data = this.form.getRawValue() as ProductCategoryModel;
        this.categoryService
            .saveItem(data)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe({
                next: (response: ProductApiResponseModel) => {
                    this.itemModel.map(response.payload);
                    this.form.patchValue(this.itemModel);
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Categoria a fost salvată cu success!',
                    });
                },
                error: (err) => {
                    if (err instanceof HttpErrorResponse) {
                        this.validationMessageService.serverSideValidation(err, this.form, '', true);
                    }
                },
            });
    }

    updateTags(tags: string[]): void {
        this.tags = tags;
    }
}
