import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthModule } from './auth/auth.module';
import { OrdersComponent } from './freya-sync/dashboard/orders.component';
import { OrdersModule } from './freya-sync/dashboard/orders.module';
import { NomenclatureModule } from './freya-sync/nomenclature/nomenclature.module';
import { AuthGuardService } from './guards/auth-guard.service';
import { NotfoundComponent } from './layout/notfound/notfound.component';
import { MainComponent } from './main.component';
import { SyncComponent } from './freya-sync/sync/sync.component';
import { SettingsComponent } from './freya-sync/settings/settings.component';
import { MyCompanyComponent } from './freya-sync/my-company/my-company.component';
import { UnavailableComponent } from './freya-sync/unavailable/unavailable.component';

const routes: Routes = [
    { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    {
        path: '',
        component: MainComponent,
        children: [
            {
                path: 'dashboard',
                component: OrdersComponent,
                canActivate: [AuthGuardService],
            },
            {
                path: 'mycompany',
                component: MyCompanyComponent,
                canActivate: [AuthGuardService],
            },
            { path: 'sync', component: SyncComponent, canActivate: [AuthGuardService] },
            { path: 'settings', component: SettingsComponent, canActivate: [AuthGuardService] },
        ],
    },
    { path: 'unavailable', component: UnavailableComponent },
    { path: '**', redirectTo: '/notfound' },
    { path: 'notfound', component: NotfoundComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes), OrdersModule, NomenclatureModule, AuthModule],
    exports: [RouterModule],
})
export class AppRoutingModule {}
