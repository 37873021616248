import { Component } from '@angular/core';
import { MenuModel, RestrictedMenuItem } from '../../shared/models/menu.model';
import { version } from '../../../../version';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
    model: RestrictedMenuItem[] = MenuModel;
    appVersion = version;
    constructor() {}
}
