<div class="grid">
    <div class="col-12 xl:col-6 xl:pr-3 transition-duration-200 transition-all">
        <h3>Descriere scurtă</h3>
        <p-editor [formControl]="shortDescription" [style]="{ height: '15rem' }">
            <ng-template pTemplate="header">
                <div id="toolbar">
                    <span class="ql-formats">
                        <button class="ql-bold" pTooltip="Bold" type="button"></button>
                        <button class="ql-italic" pTooltip="Italic" type="button"></button>
                        <button class="ql-underline" pTooltip="Subliniat" type="button"></button>
                        <button class="ql-strike" pTooltip="Taiat" type="button"></button>
                    </span>
                    <span class="ql-formats">
                        <select name="Font" id="font" class="ql-font"></select>
                        <select name="Marime" id="size" class="ql-size"></select>
                    </span>
                    <span class="ql-formats">
                        <select name="color" id="color" class="ql-color"></select>
                    </span>
                    <span class="ql-formats">
                        <button class="ql-list" value="ordered" type="button"></button>
                        <button class="ql-list" value="bullet" type="button"></button>
                        <select name="align" id="align" class="ql-align"></select>
                    </span>
                </div>
            </ng-template>
        </p-editor>
    </div>
    <div class="col-12 xl:col-6 xl:border-left-1 border-gray-400 xl:pl-3 transition-duration-200 transition-all">
        <h3>Descriere lungă</h3>
        <p-editor [formControl]="longDescription" [style]="{ height: '15rem' }">
            <ng-template pTemplate="header">
                <div id="toolbarLongDescription">
                    <span class="ql-formats">
                        <button class="ql-bold" pTooltip="Bold" type="button"></button>
                        <button class="ql-italic" pTooltip="Italic" type="button"></button>
                        <button class="ql-underline" pTooltip="Subliniat" type="button"></button>
                        <button class="ql-strike" pTooltip="Taiat" type="button"></button>
                    </span>
                    <span class="ql-formats">
                        <select name="Font" id="fontLongDesc" class="ql-font"></select>
                        <select name="Marime" id="sizeLongDesc" class="ql-size"></select>
                    </span>
                    <span class="ql-formats">
                        <select name="color" id="colorLongDesc" class="ql-color"></select>
                    </span>
                    <span class="ql-formats">
                        <button class="ql-list" value="ordered" type="button"></button>
                        <button class="ql-list" value="bullet" type="button"></button>
                        <select name="align" id="alignLongDesc" class="ql-align"></select>
                    </span>
                </div>
            </ng-template>
        </p-editor>
    </div>
</div>
