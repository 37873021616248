<div class="container">
    <button
        pButton
        pRipple
        type="button"
        (click)="showDialog()"
        icon="fas fa-pencil"
        pTooltip="Editare"
        tooltipPosition="left"
    ></button>
    <button
        pButton
        pRipple
        type="button"
        (click)="copyToClipboard()"
        icon="fas fa-copy"
        pTooltip="Copiază UID"
        tooltipPosition="left"
    ></button>
    <button
        pButton
        pRipple
        type="button"
        class="p-button-danger"
        (click)="overlay.toggle($event)"
        icon="fas fa-trash"
        pTooltip="Șterge"
        tooltipPosition="left"
    >
        <p-overlayPanel #overlay>
            <h3>Esti sigur că vrei să ștergi acest utilizator?</h3>
            <div class="buttons">
                <button pButton pRipple type="button" class="p-button-danger" (click)="delete()" label="Da"></button>
                <button pButton pRipple type="button" (click)="overlay.hide()" label="Nu"></button>
            </div>
        </p-overlayPanel>
    </button>
</div>
