import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SttTableService } from '../../../shared/components/stt-table/stt-table.service';
import { SttTableComponent } from '../../../shared/components/stt-table/stt-table.component';
import { CompanyService } from '../../../shared/services/app/nomenclatures/company.service';
import { LocationsActionTemplateComponent } from './locations-action-template/locations-action-template.component';
import { Router } from '@angular/router';

@Component({
    selector: 'app-locations',
    templateUrl: './locations.component.html',
    styleUrls: ['./locations.component.scss'],
})
export class LocationsComponent implements OnInit {
    public tableEndpoint = 'location/companyLocations/';
    public tableQuery: SttTableService;
    public actionTemplate = LocationsActionTemplateComponent;

    isStandalone: boolean = false;
    companyName: string = '';

    @Input() companyId: number;
    @Input() companyUid: string;

    @ViewChild('table') table: SttTableComponent;

    constructor(private router: Router, private companyService: CompanyService) {}

    ngOnInit(): void {
        this.tableQuery = new SttTableService();
        if (!this.companyId) {
            this.companyId = this.companyService.company.id;
            this.companyName = this.companyService.company.name;
            this.companyUid = this.companyService.company.uid;
            this.isStandalone = true;
        }

        this.tableEndpoint += this.companyId;
    }

    onAdd(): void {
        this.router.navigate(['/nomenclatures/locations/add-edit'], {
            queryParams: {
                company_uid: this.companyUid,
                company_id: this.companyId,
            },
        });
    }
}
