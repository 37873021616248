<h3>Configurare produs per locație</h3>
<p-table [autoLayout]="true" [value]="locationsForm.controls" *ngIf="!loading">
    <ng-template pTemplate="header">
        <tr class="header-row">
            <th>Locație</th>
            <th *ngIf="seenInDispecerModule">Dispecer</th>
            <th *ngIf="seenInEcommerceModule">E-Commerce</th>
            <th *ngIf="seenInDigitalMenuModule">Digital menu</th>
            <th style="min-width: 20rem">Produs - Freya</th>
            <th *ngIf="seenInEcommerceModule" style="min-width: 20rem">Produs - E-Commerce</th>
            <th style="min-width: 8rem">Preț</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-index>
        <tr>
            <td>{{ rowData.controls['name'].value }}</td>
            <td *ngIf="seenInDispecerModule">
                <div class="flex justify-content-center align-items-center">
                    <p-checkbox [formControl]="rowData.controls['seenInDispecer']" [binary]="true"></p-checkbox>
                </div>
            </td>
            <td *ngIf="seenInEcommerceModule">
                <div class="flex justify-content-center align-items-center">
                    <p-checkbox [formControl]="rowData.controls['seenInEcomerce']" [binary]="true"></p-checkbox>
                </div>
            </td>
            <td *ngIf="seenInDigitalMenuModule">
                <div class="flex justify-content-center align-items-center">
                    <p-checkbox
                        [formControl]="rowData.controls['seenInDigitalMenu']"
                        [binary]="true"
                        pTooltip="Bifați dacă doriți ca acest produs să fie trimis în Digital menu!"
                    ></p-checkbox>
                </div>
            </td>
            <td>
                <app-stt-dropdown
                    [formControlParent]="rowData.controls['freya_id']"
                    [endPoint]="'freya-sync/getProducts/' + rowData.get('locationUid').value"
                    [fields]="{ text: 'name', value: 'id' }"
                ></app-stt-dropdown>
            </td>
            <td *ngIf="seenInEcommerceModule">
                <app-stt-dropdown
                    [formControlParent]="rowData.controls['ecommerce_id']"
                    [endPoint]="'ecommerce-sync/getProducts/' + rowData.get('locationUid').value"
                    [fields]="{ text: 'name', value: 'id' }"
                ></app-stt-dropdown>
            </td>
            <td>
                <app-stt-input [control]="rowData.controls['price']" type="number"></app-stt-input>
            </td>
        </tr>
    </ng-template>
</p-table>
<p-progressBar mode="indeterminate" *ngIf="loading"></p-progressBar>
