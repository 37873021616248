<div class="container">
    <img class="logo" src="../../../assets/images/logos/freya_white.svg" alt="Freya logo" />
    <div class="card">
        <form (ngSubmit)="onSend()" autocomplete="off">
            <h1 class="login-header">Autentificare</h1>
            <p-divider></p-divider>
            <br />
            <div class="grid" [formGroup]="form">
                <div class="col-12">
                    <app-stt-input
                        [control]="form.controls['email']"
                        icon="pi pi-envelope"
                        label="Email"
                        type="email"
                        inputId="email"
                    ></app-stt-input>
                </div>
                <div class="col-12 mt-2">
                    <app-stt-input
                        [control]="form.controls['password']"
                        icon="pi pi-key"
                        label="Parola"
                        type="password"
                        [feedback]="false"
                        inputId="password"
                    ></app-stt-input>
                </div>
            </div>
            <p-divider></p-divider>
            <div class="grid">
                <div class="col-12 p-fluid">
                    <button type="submit" pButton pRipple label="Autentificare"></button>
                </div>
            </div>
        </form>
    </div>
</div>
