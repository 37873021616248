<p-divider></p-divider>
<div class="header">
    <h2 *ngIf="companyName; else noCompany">Comenzi pentru compania: {{ companyName }}</h2>
    <ng-template #noCompany>
        <h2>Selectați o companie!</h2>
    </ng-template>
</div>
<p-divider></p-divider>

<div class="grid" *ngIf="companyName">
    <div class="col-12">
        <div class="card">
            <app-stt-table #table [endpoint]="tableEndpoint" [query]="tableQuery" [actionTemplate]="actionComponent">
            </app-stt-table>
        </div>
    </div>
</div>
