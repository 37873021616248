import { Injectable } from '@angular/core';
import { CompanyService } from './nomenclatures/company.service';
import { CompanyModuleEnum } from '../../enum/company-module.enum';
import { CompanyModel, CompanyModuleModel } from '../../../auth/models/company.model';

@Injectable({
    providedIn: 'root',
})
export class CompanyModuleService {
    constructor(private readonly companyService: CompanyService) {}

    /**
     * Returns if a company has a module. If no company is passed, the one stored in the system is used
     * @param module
     * @param company
     */
    hasModule(module: CompanyModuleEnum, company?: CompanyModel): boolean {
        if (!company) {
            company = this.companyService.company;
        }
        if (!company.modules) {
            return false;
        }

        return company.modules.find((element: CompanyModuleModel) => element.module === module)?.active;
    }
}
