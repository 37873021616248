import { Component, Input, OnInit } from '@angular/core';
import { finalize } from 'rxjs';
import { ApiResponseModel } from '../../../../../shared/models/api-response.model';
import { ProductLocationModel } from '../../../../../shared/models/nomenclatures/products/product-location.model';
import {
    ProductMenuItemModel,
    ProductMenuModel,
} from '../../../../../shared/models/nomenclatures/products/product.menu.model';
import { ProductModel } from '../../../../../shared/models/nomenclatures/products/product.model';
import { ComplexDetailsModel } from '../../../../../shared/models/nomenclatures/products/complex-details.model';
import { ProductService } from '../../../../../shared/services/app/nomenclatures/product.service';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-complex-variations',
    templateUrl: './complex-variations.component.html',
    styleUrls: ['./complex-variations.component.scss'],
})
export class ComplexVariationsComponent implements OnInit {
    variations: Array<ComplexDetailsModel>;
    loading: boolean = false;
    perPage: number = 10;
    page: number = 0;
    count: number = 0;
    productLocation: ProductLocationModel;
    menus: ProductMenuModel[];

    @Input() product: ProductModel;
    @Input() locationId: number;

    constructor(private productService: ProductService, private dialogConfig: DynamicDialogConfig) {}

    ngOnInit(): void {
        if (this.dialogConfig.data) {
            this.setDataFromDialogConfig();
        }

        const productLocations = this.product.productLocations;
        this.productLocation = productLocations.find(
            (productLocation: ProductLocationModel) => productLocation.location_id === this.locationId
        );
        this.productService.getProductMenus(this.productLocation.id).subscribe((response: ApiResponseModel) => {
            this.menus = response.payload;
        });
        this.getVariations(this.perPage, this.page);
    }

    setDataFromDialogConfig() {
        this.product = this.dialogConfig.data['product'];
        this.locationId = this.dialogConfig.data['location_id'];
    }

    onPriceChange(event: Event, id: string): void {
        const newPrice: number = Number((<HTMLInputElement>event.target).value);
        this.productService.updateVariationPrice(id, newPrice).subscribe({
            next: () => {},
            error: () => {
                (<HTMLInputElement>event.target).value = '';
            },
        });
    }

    getServerData(event: { first: number; rows: number }): void {
        this.page = (event.first + this.perPage) / this.perPage;

        this.getVariations(this.perPage, this.page);
    }

    getVariations(pageSize: number, pageIndex: number): void {
        this.variations = new Array<ComplexDetailsModel>();
        this.perPage = pageSize;
        this.page = pageIndex;
        this.loading = true;
        this.productService
            .getGeneratedVariations(this.product.id, this.productLocation.location_id, pageSize, pageIndex)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe((response: ApiResponseModel) => {
                this.variations = response.payload.menus;
                this.count = response.payload.count;
            });
    }
}
