<h2 *ngIf="uid">Editare categorie</h2>
<form class="flex flex-column card" autocomplete="off" [formGroup]="form" (ngSubmit)="onSubmit()">
    <p-tabView *ngIf="!loading">
        <p-tabPanel>
            <ng-template pTemplate="header">
                <div class="tab-header"><i class="pi pi-box"></i><span>Date categorie</span></div>
            </ng-template>
            <ng-template pTemplate="body">
                <app-product-categories-add-edit-data
                    [form]="form"
                    [categoryUid]="uid"
                    [tags]="tags"
                    (tagChanged)="updateTags($event)"
                ></app-product-categories-add-edit-data>
            </ng-template>
        </p-tabPanel>
    </p-tabView>
    <p-progressBar mode="indeterminate" *ngIf="loading"></p-progressBar>
    <p-divider></p-divider>
    <div class="actions-area">
        <div class="actions-left">
            <a [routerLink]="['/nomenclatures/product-categories/list']">
                <button
                    pButton
                    pRipple
                    type="button"
                    label="Anulare"
                    class="p-button-raised p-button-danger"
                    icon="pi pi-ban"
                    [loading]="loading"
                ></button>
            </a>
        </div>

        <div class="actions-right">
            <button
                pButton
                pRipple
                type="submit"
                label="Salvează"
                class="p-button-raised p-button-success p-outlined-button"
                icon="pi pi-save"
                [loading]="loading"
            ></button>
        </div>
    </div>
</form>
