import { Component, Input } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

interface GeolocationType {
    name: string;
    value: boolean;
}

@Component({
    selector: 'app-companies-add-edit-settings',
    templateUrl: './companies-add-edit-settings.component.html',
    styleUrls: ['./companies-add-edit-settings.component.scss'],
})
export class CompaniesAddEditSettingsComponent {
    @Input() form: FormGroup;
    items: GeolocationType[] = [
        { name: 'Nominatim', value: false },
        { name: 'Google', value: true },
    ];
    constructor() {}
}
