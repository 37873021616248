import { Component, OnInit, ViewChild } from '@angular/core';
import { SttTableComponent } from '../../shared/components/stt-table/stt-table.component';
import { SttTableService } from '../../shared/components/stt-table/stt-table.service';
import { CompanyService } from '../../shared/services/app/nomenclatures/company.service';
import { OrdersActionTemplateComponent } from './orders-action-template/orders-action-template.component';
@Component({
    selector: 'app-dashboard',
    templateUrl: './orders.component.html',
    styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit {
    public tableEndpoint = 'order/index';
    public tableQuery: SttTableService;
    actionComponent = OrdersActionTemplateComponent;
    companyName: string;
    @ViewChild('table') table: SttTableComponent;

    constructor(private companyService: CompanyService) {}

    ngOnInit(): void {
        this.companyName = this.companyService.company.name;
        this.tableQuery = new SttTableService().addParam('companyUid', this.companyService.company.uid);
    }
}
