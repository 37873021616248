import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-products-add-edit-description',
    templateUrl: './products-add-edit-description.component.html',
    styleUrls: ['./products-add-edit-description.component.scss'],
})
export class ProductsAddEditDescriptionComponent implements OnInit {
    @Input() form: FormGroup;
    shortDescription: FormControl;
    longDescription: FormControl;

    ngOnInit() {
        this.shortDescription = this.form.controls['short_description'] as FormControl;
        this.longDescription = this.form.controls['long_description'] as FormControl;
    }
}
