import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CompanyModel } from './auth/models/company.model';
import { CompanyService } from './shared/services/app/nomenclatures/company.service';
import { UserService } from './auth/user.service';
import { Subject } from 'rxjs/internal/Subject';
import { UserModel } from './auth/models/user.model';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
    menuHidden = false;
    form: FormGroup;
    company: CompanyModel;
    user?: UserModel;
    lastSelectedCompany?: CompanyModel;
    destroy$ = new Subject<void>();
    isAdmin: boolean = false;

    constructor(
        private formBuilder: FormBuilder,
        private readonly companyService: CompanyService,
        private readonly userService: UserService
    ) {}

    ngOnInit(): void {
        this.company = this.companyService.company;
        this.isAdmin = this.userService.isAdmin();
        this.user = this.userService.user;
        this.createForm();
    }

    createForm(): void {
        this.form = this.formBuilder.group({
            company_id: [this.company.id],
        });
    }

    onChangeCompany(event: CompanyModel | undefined) {
        if (!this.isAdmin) return;
        this.lastSelectedCompany = event;
    }

    onSelectCompany() {
        if (!this.isAdmin) return;
        const company = this.lastSelectedCompany;
        if (!this.isAdmin) {
            return;
        }
        if (!this.lastSelectedCompany) {
            this.companyService.clearCustomCompanyUid();
        } else {
            this.companyService.setCustomCompanyUid(company.uid);
        }
        window.location.reload();
    }
}
