import { Component, OnInit, ViewChild } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { SttTableService } from 'src/app/shared/components/stt-table/stt-table.service';
import { SttTableComponent } from 'src/app/shared/components/stt-table/stt-table.component';
import { CompanyService } from '../../../shared/services/app/nomenclatures/company.service';
import { CustomersActionTemplateComponent } from './customers-action-template/customers-action-template.component';

@Component({
    selector: 'app-customers',
    templateUrl: './customers.component.html',
    styleUrls: ['./customers.component.scss'],
})
export class CustomersComponent implements OnInit {
    public tableEndpoint = 'customer/index';
    public tableQuery: SttTableService;
    public actionTemplate = CustomersActionTemplateComponent;
    public dialog: DynamicDialogRef;
    companyName: string = '';

    public selectedCompany: boolean = false;

    @ViewChild('table') table: SttTableComponent;

    constructor(private companyService: CompanyService) {}

    ngOnInit(): void {
        this.companyName = this.companyService.company.name;
        this.tableQuery = new SttTableService().addParam('company_id', this.companyService.company.id);
        this.selectedCompany = true;
    }
}
