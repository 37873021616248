<p-divider></p-divider>
<div class="header">
    <div class="header-text">
        <h2>Profil utilizator</h2>
    </div>
</div>
<p-divider></p-divider>
<div class="card">
    <form (ngSubmit)="onSaveProfile()" autocomplete="off">
        <div class="grid">
            <div class="col-6 pr-3">
                <app-stt-input
                    inputId="email"
                    [control]="form.controls['email']"
                    icon="pi pi-envelope"
                    type="email"
                    label="Email"
                ></app-stt-input>
            </div>
            <div class="col-6 pl-3 border-left-1 border-gray-400">
                <app-stt-input
                    inputId="name"
                    [control]="form.controls['name']"
                    icon="pi pi-user"
                    type="text"
                    label="Nume"
                ></app-stt-input>
            </div>
        </div>
        <div class="grid">
            <div class="col-6 pr-3">
                <app-stt-input
                    inputId="password"
                    [control]="form.controls['password']"
                    icon="pi pi-key"
                    type="password"
                    label="Parola noua"
                >
                </app-stt-input>
            </div>
            <div class="col-6 pl-3 border-left-1 border-gray-400">
                <app-stt-input
                    inputId="passwordConfirmation"
                    [control]="form.controls['password_confirmation']"
                    [feedback]="false"
                    icon="pi pi-key"
                    type="password"
                    label="Confirma Parola"
                >
                </app-stt-input>
            </div>
        </div>
        <div class="grid-flex-end">
            <button pButton class="p-button-success" label="Salveaza" icon="pi pi-check" type="submit"></button>
        </div>
    </form>
</div>
