import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-customers-add-edit-data',
    templateUrl: './customers-add-edit-data.component.html',
    styleUrls: ['./customers-add-edit-data.component.scss'],
})
export class CustomersAddEditDataComponent {
    @Input() form: FormGroup;

    constructor() {}
}
