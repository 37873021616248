import { ApiResponseModel } from '../../api-response.model';
import { BaseModel } from '../../base.model';
import { CompanyModel } from '../../../../auth/models/company.model';
import { ScheduleModel } from '../customers/schedule.model';

export class LocationModel extends BaseModel {
    id: number;
    name: string;
    uid: string;
    tag: string;
    fullAddress: string;
    latitude: string;
    longitude: string;
    hasDelivery: boolean = true;
    hasPickup: boolean = true;
    allowEcommerce: boolean = false;
    allowDispatcher: boolean;
    isDefaultLocation: boolean;
    isFreyaCloud: boolean;
    isActive: boolean = true;
    freyaUrl: string;
    freyaUser: string;
    freyaPassword: string;
    freyaApiKey: string;
    freyaDeliveryProduct: any;
    freyaDeliveryProductCost: any[] = [];
    ecommerceType: number;
    ecommerceUrl: string;
    ecommerceUser: string;
    ecommercePassword: string;
    ecommerceSkipProductSync: boolean = false;
    ecommerceSkipCategorySync: boolean = false;
    company_id: number;
    company?: CompanyModel;
    county: string;
    city: string;
    hasOwnVivaWallet: boolean = false;
    vivaWalletTestingMode: boolean;
    vivaWalletClientId: string;
    vivaWalletClientSecret: string;
    vivaWalletClientSourceCode: string;

    vivaWalletTestClientId: string;
    vivaWalletTestClientSecret: string;
    vivaWalletTestClientSourceCode: string;
    schedule: ScheduleModel = new ScheduleModel();
    callCenterConnectionData: string;
    syncOrders: boolean = false;
    overwritePrice: boolean = false;
    getDescriptionFromFreya: boolean = true;
    syncDescriptionToEcommerce: boolean = true;
    syncNameToEcommerce: boolean = true;
    syncProductsBarcodes: boolean = false;
    createNewEcommerceProducts: boolean = true;
    associatedPosDevice: string | undefined;
}

export class LocationApiResponseModel extends ApiResponseModel {
    override payload: LocationModel;
}
