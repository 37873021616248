<div class="wrapper">
    <div
        class="container"
        [ngClass]="{ selected: selectedLocations.includes(data.id), failed: hasFailed(data.id) }"
        *ngFor="let data of locationData"
        (click)="updateActive(data)"
    >
        <i class="fas fa-shop fa-6x"></i>
        <span>{{ data.name }}</span>
        <button
            pButton
            pRipple
            label="Configurează manual"
            *ngIf="hasFailed(data.id)"
            class="p-button-danger"
            (click)="openRuleConfig(data.id)"
        ></button>
    </div>
</div>
