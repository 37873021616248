import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SttTableService } from '../../../../../shared/components/stt-table/stt-table.service';
import { UsersActionTemplateComponent } from '../../../users/users-action-template/users-action-template.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SttTableComponent } from '../../../../../shared/components/stt-table/stt-table.component';
import { UsersAddEditComponent } from '../../../users/users-add-edit/users-add-edit.component';
import { UserModel } from '../../../../../auth/models/user.model';
import { CompaniesAddEditUsersActionsComponent } from './companies-add-edit-users-actions/companies-add-edit-users-actions.component';

@Component({
    selector: 'app-companies-add-edit-users',
    templateUrl: './companies-add-edit-users.component.html',
    styleUrls: ['./companies-add-edit-users.component.scss'],
})
export class CompaniesAddEditUsersComponent implements OnInit {
    public tableEndpoint = 'company/companyUsersList/';
    public tableQuery: SttTableService;
    public actionTemplate = CompaniesAddEditUsersActionsComponent;
    public dialog: DynamicDialogRef;

    @Input() companyId: number;

    @ViewChild('table') table: SttTableComponent;

    constructor(private dialogService: DialogService) {}

    ngOnInit(): void {
        // TODO add filtering
        this.tableQuery = new SttTableService();
        this.tableEndpoint += this.companyId;
    }

    onAdd(): void {
        this.dialog = this.dialogService.open(UsersAddEditComponent, {
            header: 'Adaugare utilizator',
            width: '70%',
            contentStyle: { 'max-height': '70vh' },
            height: 'fit-content',
            baseZIndex: 10000,
            closeOnEscape: false,
            data: {
                company_id: this.companyId,
            },
        });

        this.dialog.onClose.subscribe((user: UserModel) => {
            if (user) {
                this.table.refresh();
            }
        });
    }
}
