import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CalendarModule } from 'primeng/calendar';
import { DividerModule } from 'primeng/divider';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PasswordModule } from 'primeng/password';
import { ProgressBarModule } from 'primeng/progressbar';
import { RippleModule } from 'primeng/ripple';
import { StyleClassModule } from 'primeng/styleclass';
import { TableModule } from 'primeng/table';
import { SttTableService } from './components/stt-table/stt-table.service';
import { SttTableComponent } from './components/stt-table/stt-table.component';
import { SttTableActionsComponent } from './components/stt-table/stt-table-actions/stt-table-actions.component';
import { SttDropdownComponent } from './components/stt-dropdown/stt-dropdown.component';
import { SttInputComponent } from './components/stt-input/stt-input.component';
import { SttCalendarComponent } from './components/stt-calendar/stt-calendar.component';
import { SttOverlayTableComponent } from './components/stt-overlay-table/stt-overlay-table.component';
import { SttTableRawExpandComponent } from './components/stt-table/stt-table-raw-expand/stt-table-raw-expand.component';
import { SttValidatorMessageComponent } from './components/stt-validator-message/stt-validator-message.component';
import { SttTableFieldFiltersComponent } from './components/stt-table/stt-table-field-filters/stt-table-field-filters.component';
import { SttTextareaComponent } from './components/stt-textarea/stt-textarea.component';
import { PaginatorModule } from 'primeng/paginator';
import { TableCommunicationService } from './services/base/table-communication.service';
import { HttpClientModule } from '@angular/common/http';
import { MessageService } from 'primeng/api';

@NgModule({
    declarations: [
        SttTableFieldFiltersComponent,
        SttTextareaComponent,
        SttDropdownComponent,
        SttInputComponent,
        SttTableRawExpandComponent,
        SttTableComponent,
        SttValidatorMessageComponent,
        SttOverlayTableComponent,
        SttCalendarComponent,
        SttTableActionsComponent,
    ],
    exports: [
        SttTableFieldFiltersComponent,
        SttTextareaComponent,
        SttDropdownComponent,
        SttInputComponent,
        SttTableRawExpandComponent,
        SttTableComponent,
        SttValidatorMessageComponent,
        SttOverlayTableComponent,
        SttCalendarComponent,
        SttTableActionsComponent,
    ],
    imports: [
        CommonModule,
        CalendarModule,
        NgSelectModule,
        OverlayPanelModule,
        PasswordModule,
        ReactiveFormsModule,
        InputTextModule,
        ProgressBarModule,
        TableModule,
        HttpClientModule,
        StyleClassModule,
        FormsModule,
        PaginatorModule,
        RippleModule,
        DividerModule,
        InputTextareaModule,
    ],
    providers: [
        DatePipe,
        DecimalPipe,
        SttTableService,
        TableCommunicationService,
        DialogService,
        MessageService,
        DynamicDialogRef,
        DynamicDialogConfig,
    ],
})
export class SharedModule {}
