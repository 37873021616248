import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs/operators';
import { UserModel, UserApiResponseModel } from '../../../../auth/models/user.model';
import { UserService } from '../../../../auth/user.service';
import { CustomValidationService } from '../../../../shared/services/app/custom-validation.service';
import { CustomerService } from '../../../../shared/services/app/nomenclatures/customer.service';
import { ValidationMessageService } from '../../../../shared/services/base/validation-message.service';
import { LocationService } from '../../../../shared/services/app/nomenclatures/location.service';
import { LocationModel } from '../../../../shared/models/nomenclatures/locations/location.model';
import { ApiResponseModel } from '../../../../shared/models/api-response.model';

@Component({
    selector: 'app-users-add-edit',
    templateUrl: './users-add-edit.component.html',
    styleUrls: ['./users-add-edit.component.scss'],
})
export class UsersAddEditComponent implements OnInit {
    public form: FormGroup;
    public itemModel: UserModel = new UserModel();
    public uid: string = null;
    company_id: number = null;
    public loading = false;

    locations: LocationModel[];

    constructor(
        private formBuilder: FormBuilder,
        public dialog: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private cd: ChangeDetectorRef,
        private validationMessageService: ValidationMessageService,
        private userService: UserService,
        private customerService: CustomerService,
        private locationService: LocationService,
        private messageService: MessageService,
        private customValidators: CustomValidationService
    ) {}

    ngOnInit(): void {
        this.uid = this.config.data && this.config.data.hasOwnProperty('uid') ? this.config.data.uid : null;
        this.company_id =
            this.config.data && this.config.data.hasOwnProperty('company_id') ? this.config.data.company_id : null;
        this.createForm();
        if (this.company_id) {
            this.form.patchValue({
                company_id: this.company_id,
            });
        }

        if (this.uid) {
            this.getItem();
        }
    }

    createForm() {
        this.form = this.formBuilder.group(
            {
                uid: [this.itemModel.uid],
                name: [this.itemModel.name, [Validators.required, Validators.maxLength(200)]],
                email: [
                    { value: this.itemModel.email, disabled: this.uid },
                    [Validators.required, Validators.email, Validators.maxLength(255)],
                ],
                company_id: [this.itemModel.company_id, Validators.required],
                password: [
                    '',
                    !this.uid
                        ? [Validators.required, Validators.minLength(6), Validators.maxLength(255)]
                        : [Validators.minLength(6), Validators.maxLength(255)],
                ],
                password_confirmation: ['', !this.uid ? [Validators.required] : []],
                isActive: [this.itemModel.isActive],
                hasCallCenter: [this.itemModel.hasCallCenter],
                callCenterExtension: [{ value: this.itemModel.callCenterExtension, disabled: true }],
                locations: [],
            },
            {
                validators: this.customValidators.matchPasswords('password', 'password_confirmation'),
            }
        );

        this.form.controls['company_id'].valueChanges.subscribe((value: number) => {
            if (value) {
                this.getLocations(value);
            } else {
                this.locations = [];
            }
        });

        // Disable callCenterExtension based on hasCallCenter
        this.form.controls['hasCallCenter'].valueChanges.subscribe((value: boolean) => {
            if (value === true) {
                this.form.controls['callCenterExtension'].enable();
            } else {
                this.form.controls['callCenterExtension'].disable();
                this.form.controls['callCenterExtension'].patchValue('');
            }
        });
    }

    getLocations(companyId?: number): void {
        // Reset locations first.
        this.locations = [];
        this.locationService.getLocations(companyId).subscribe((response: ApiResponseModel) => {
            this.locations = response.payload;
        });
    }

    getItem(): void {
        this.loading = true;
        this.userService
            .get(this.uid)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe((response: UserApiResponseModel) => {
                this.itemModel.map(response.payload);
                this.form.patchValue(this.itemModel);
            });
    }

    onCancel(): void {
        this.dialog.close(null);
    }

    onSave(): void {
        if (!this.customValidators.validateOnSubmit(this.form)) return;
        const data = this.form.getRawValue();
        if (data.locations === null) {
            data.locations = [];
        }
        if (this.uid) {
            this.update(data);
        } else {
            this.create(data);
        }
    }

    create(data: any): void {
        this.loading = true;

        this.userService
            .create(data)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe({
                next: (response: UserApiResponseModel) => {
                    this.itemModel.map(response.payload);
                    this.form.patchValue(this.itemModel);
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Utilizatorul a fost salvat cu success!',
                    });
                    this.dialog.close(this.itemModel);
                },
                error: (err) => {
                    if (err instanceof HttpErrorResponse) {
                        this.validationMessageService.serverSideValidation(err, this.form, '', true);
                    }
                },
            });
    }

    update(data: any): void {
        this.loading = true;

        this.userService
            .update(data)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe({
                next: (response: UserApiResponseModel) => {
                    this.itemModel.map(response.payload);
                    this.form.patchValue(this.itemModel);
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Utilizatorul a fost actualizat cu success!',
                    });
                    this.dialog.close(this.itemModel);
                },
                error: (err) => {
                    if (err instanceof HttpErrorResponse) {
                        this.validationMessageService.serverSideValidation(err, this.form, '', true);
                    }
                },
            });
    }
}
