import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
    selector: 'app-stt-validator-message',
    templateUrl: './stt-validator-message.component.html',
    styleUrls: ['./stt-validator-message.component.scss'],
})
export class SttValidatorMessageComponent {
    @Input() control?: AbstractControl;
    constructor() {}
}
