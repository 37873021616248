import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpApiService } from '../shared/services/base/http-api.service';
import { AuthRequestModel, AuthResponseModel } from './models/auth.model';
import { UserApiResponseModel, UserModel } from './models/user.model';
import { ApiResponseModel } from '../shared/models/api-response.model';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    public tokenLocalStorageKey = 'auth-token';
    public redirectUrl = '/';
    private storedUser!: UserModel;

    constructor(public httpApiService: HttpApiService, private router: Router) {}

    public setUser(user: UserModel): void {
        this.storedUser = user;
    }

    public get user() {
        return this.storedUser;
    }

    public checkAuth(): boolean {
        const token = localStorage.getItem(this.tokenLocalStorageKey);

        return !!token;
    }

    public setUserDetails(userDetails: AuthResponseModel): void {
        localStorage.setItem(this.tokenLocalStorageKey, userDetails.access_token);
        localStorage.setItem('user_name', userDetails.user.name);
        localStorage.setItem('auth_expire_in', userDetails.expires_in.toString());
        localStorage.setItem('auth_expire_at', userDetails.expire_at.toString());
    }

    public isAdmin(): boolean {
        return this.user?.isAdmin;
    }

    public me(): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('auth/me');
    }

    public setRedirectUrl(url: string): void {
        this.redirectUrl = url;
    }

    public logout(): void {
        this.httpApiService.httpPost('auth/logout').subscribe(() => {});
        localStorage.removeItem(this.tokenLocalStorageKey);
        localStorage.removeItem('auth_expire_in');
        localStorage.removeItem('auth_expire_at');

        this.router.navigateByUrl('/auth/login');
    }

    public login(authModel: AuthRequestModel): Observable<any> {
        return this.httpApiService.httpPost('auth/login', authModel);
    }

    public register(registerModel: any): Observable<any> {
        return this.httpApiService.httpPost('auth/register', registerModel);
    }

    public getUserProfile(uid?: string): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('user/show', { uid });
    }

    public get(uid: string): Observable<UserApiResponseModel> {
        return this.httpApiService.httpGet('user/edit', { uid });
    }

    public create(data: UserModel): Observable<ApiResponseModel> {
        return this.httpApiService.httpPost('user/store', data);
    }

    public update(data: UserModel): Observable<ApiResponseModel> {
        return this.httpApiService.httpPost('user/update', data);
    }

    public delete(data: UserModel): Observable<ApiResponseModel> {
        return this.httpApiService.httpDelete('user/destroy', data);
    }

    public restore(data: UserModel): Observable<ApiResponseModel> {
        return this.httpApiService.httpPost('user/restore', data);
    }
}
