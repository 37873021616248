export class ProductMenuModel {
    id: number;
    code: string;
    orderFlag: number;
    name: string;
    products: Array<ProductMenuItemModel>;
}

export class ProductMenuItemModel {
    id: number
    name: string;
    code: string;
    selected: boolean = false;
}
