<div class="p-inputgroup">
    <span class="p-inputgroup-addon" *ngIf="icon && iconPosition === 'left'">
        <i [ngClass]="icon"></i>
    </span>
    <div class="p-float-label">
        <ng-select
            typeToSearchText="Introduceți cel putin două caractere"
            class="p-component p-inputwrapper p-inputwrapper-filled"
            [class.icon-left]="iconPosition === 'left' && icon"
            [class.icon-right]="iconPosition === 'right' && icon"
            [items]="items"
            [bindLabel]="fields.text"
            [bindValue]="fields.value"
            [formControl]="control"
            [searchWhileComposing]="false"
            [minTermLength]="2"
            appendTo="body"
            tabIndex="10000"
            [searchable]="true"
            [loading]="loading"
            [clearable]="true"
            (change)="onChange($event)"
            (search)="onSearch($event)"
            [placeholder]="placeholder"
            [readonly]="readonly"
        >
        </ng-select>
        <label
            >{{ label }}
            <span *ngIf="hasRequired(formControlParent)" style="color: red; font-style: italic">*</span>
        </label>
    </div>
    <span class="p-inputgroup-addon" *ngIf="icon && iconPosition === 'right'">
        <i [ngClass]="icon"></i>
    </span>
</div>
<app-stt-validator-message [control]="formControlParent"></app-stt-validator-message>
