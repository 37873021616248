<section [formGroup]="form" class="mt-2">
    <div class="grid">
        <div class="col-6 pr-3">
            <app-stt-input
                [control]="form.controls['firstName']"
                label="Prenume"
                icon="pi pi-user"
                inputId="firstName"
                type="text"
            ></app-stt-input>
        </div>
        <div class="col-6 pl-3 border-left-1 border-gray-400">
            <app-stt-input
                [control]="form.controls['lastName']"
                label="Nume"
                inputId="lastName"
                icon="pi pi-user"
                type="text"
            ></app-stt-input>
        </div>
    </div>
    <div class="grid mt-2">
        <div class="col-6 pr-3">
            <app-stt-input
                [control]="form.controls['phone']"
                label="Telefon"
                icon="pi pi-phone"
                inputId="phone"
                type="text"
            ></app-stt-input>
        </div>
        <div class="col-6 pl-3 border-left-1 border-gray-400">
            <app-stt-input
                [control]="form.controls['email']"
                label="Email"
                inputId="email"
                icon="pi pi-user"
                type="email"
            ></app-stt-input>
        </div>
    </div>
</section>
