<div class="container">
    <div class="box" id="banner-container">
        <div class="text">
            <h2>Încarcă Banner</h2>
        </div>
        <p-fileUpload
            chooseLabel="Alege Banner"
            uploadLabel="Încarcă"
            cancelLabel="Anulează"
            name="banner"
            accept="image/*"
            maxFileSize="1000000"
            customUpload="true"
            (uploadHandler)="onUploadBanner($event)"
        ></p-fileUpload>
    </div>
    <div class="box" id="ban-container">
        <div class="text">
            <h2>Încarcă Logo</h2>
        </div>
        <p-fileUpload
            chooseLabel="Alege Logo"
            uploadLabel="Încarcă"
            cancelLabel="Anulează"
            name="logo"
            accept="image/*"
            maxFileSize="1000000"
            customUpload="true"
            (uploadHandler)="onUploadLogo($event)"
        ></p-fileUpload>
    </div>
</div>
