import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { filter } from 'rxjs';
import { UserService } from '../../../auth/user.service';
import { RestrictedMenuItem } from '../../../shared/models/menu.model';

@Component({
    selector: 'app-menu-item',
    templateUrl: './menu-item.component.html',
    styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent implements AfterViewInit, OnInit {
    @Input() item: RestrictedMenuItem;

    @ViewChildren('dropdownHeaders') dropdownHeaders: QueryList<ElementRef>;
    @ViewChildren('dropdownContents') dropdownContents: QueryList<ElementRef>;

    active: boolean = false;
    dropdownCount: number = 0;

    constructor(private router: Router, private cd: ChangeDetectorRef, private userService: UserService) {}

    ngOnInit(): void {
        this.isTabActive();
        this.router.events
            .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                this.isTabActive();
            });
    }

    ngAfterViewInit(): void {
        this.dropdownCount = this.countDropdowns();
    }

    switchDropdown(dropdown: HTMLElement): void {
        dropdown.classList.toggle('activated');
    }

    shouldRender(element: RestrictedMenuItem): boolean {
        const isAdmin = this.userService.isAdmin();
        if (element.needsAdmin === undefined) return true;
        return !(element.needsAdmin && !isAdmin);
    }

    countDropdowns(): number {
        if (!this.item.items) {
            return 0;
        }
        const items = this.item.items;
        const dropdowns = items.filter((item: RestrictedMenuItem) => item.items);
        return dropdowns.length;
        // const dropdownHeaders = this.dropdownHeaders.toArray();
        // let count = 0;
        // for (let header of dropdownHeaders) {
        //     count++;
        // }
        // return count;
    }

    dropdownHasActiveItems(index: number): boolean {
        let returnValue: boolean = false;
        const dropdownModel: RestrictedMenuItem = this.item.items[index];
        dropdownModel.items.forEach((item: RestrictedMenuItem) => {
            if (this.sameLink(this.router.url, item.routerLink[0])) {
                returnValue = true;
            }
        });
        return returnValue;
    }

    isTabActive(): void {
        let i = 0;
        const items = this.item.items;
        // case - no items in accordion
        if (!items) {
            if (this.sameLink(this.item.routerLink[0], this.router.url)) {
                this.active = true;
            }
            return;
        }
        items.forEach((item: RestrictedMenuItem) => {
            // case - items in dropdown in accordion
            if (item.items) {
                item.items.forEach((child: RestrictedMenuItem) => {
                    if (this.sameLink(child.routerLink[0], this.router.url)) {
                        this.active = true;
                        return;
                    }
                });
            } else if (item.routerLink) {
                if (this.sameLink(item.routerLink[0], this.router.url)) {
                    this.active = true;
                    return;
                }
            }
            i++;
        });
    }

    minimum(x: number, y: number): number {
        if (x < y) {
            return x;
        } else {
            return y;
        }
    }

    sameLink(x: string, y: string): boolean {
        const firstLink = x.split('/');
        const secondLink = y.split('/');
        firstLink.shift();
        secondLink.shift();

        const smallerSize = this.minimum(firstLink.length, secondLink.length);
        let value = true;
        for (let i = 0; i < smallerSize; i++) {
            if (firstLink[i] !== secondLink[i]) {
                value = false;
            }
        }
        return value;
    }
}
