<section>
    <div class="items mt-3" *ngIf="zoneRules.length > 0">
        <div *ngFor="let rule of zoneRules; let i = index">
            <div class="grid">
                <div class="col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-money-bill"></i>
                        </span>
                        <div class="p-float-label">
                            <input pInputText type="number" id="promotionFee" [(ngModel)]="rule.promotionFee" />
                            <label for="promotionFee">Taxă livrare</label>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-money-bill"></i>
                        </span>
                        <div class="p-float-label">
                            <input
                                pInputText
                                type="number"
                                id="promotionThreshold"
                                [(ngModel)]="rule.promotionThreshold"
                            />
                            <label for="promotionThreshold">Comanda minimă</label>
                        </div>
                    </div>
                </div>
                <div class="col-1">
                    <button pButton class="p-button-danger" icon="pi pi-trash" (click)="removePromotion(i)"></button>
                </div>
            </div>
        </div>
    </div>
    <div
        class="flex align-items-center"
        [ngClass]="{ 'justify-content-end': zoneRules.length > 0, 'justify-content-between': zoneRules.length === 0 }"
    >
        <p *ngIf="zoneRules.length === 0">Nu există promoții.</p>
        <div class="manage-rules">
            <button
                *ngIf="zoneRules.length > 0"
                pButton
                type="button"
                label="Șterge reguli"
                class="p-button-danger"
                icon="pi pi-trash"
                (click)="clearPromotions()"
            ></button>
            <button pButton label="Adauga" icon="pi pi-plus" type="button" (click)="addPromotion()"></button>
        </div>
    </div>
</section>
<p-divider></p-divider>
<section class="flex justify-content-end">
    <button
        pButton
        autofocus
        type="button"
        class="p-button-success"
        (click)="onSave()"
        label="Salvează"
        icon="pi pi-save"
    ></button>
</section>
