import { Component, OnInit } from '@angular/core';
import { CompaniesAddEditComponent } from '../nomenclature/companies/companies-add-edit/companies-add-edit.component';

@Component({
    selector: 'app-my-company',
    templateUrl: './my-company.component.html',
    styleUrls: ['./my-company.component.scss'],
})
export class MyCompanyComponent {
    constructor() {}

    onMyCompany = true;
}
