import { Component } from '@angular/core';
import { Params, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CompanyModel } from '../../../../auth/models/company.model';
import { SttTableComponent } from '../../../../shared/components/stt-table/stt-table.component';
import { ApiResponseModel } from '../../../../shared/models/api-response.model';
import { CompanyService } from '../../../../shared/services/app/nomenclatures/company.service';

@Component({
    selector: 'app-companies-action-template',
    templateUrl: './companies-action-template.component.html',
    styleUrls: ['./companies-action-template.component.scss'],
})
export class CompaniesActionTemplateComponent {
    public data: CompanyModel;
    public table: SttTableComponent;
    public dialog: DynamicDialogRef;

    constructor(
        public dialogService: DialogService,
        public router: Router,
        private companyService: CompanyService,
        private messageService: MessageService
    ) {}

    setParams(entity: CompanyModel): void {
        this.data = entity;
    }

    setTableReference(table: SttTableComponent): void {
        this.table = table;
    }

    delete() {
        this.companyService.delete(this.data).subscribe((response: ApiResponseModel) => {
            if (response.status === true) {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Compania a fost ștearsă cu succes!',
                });
                this.table.refresh();
            }
        });
    }

    copyToClipboard() {
        navigator.clipboard.writeText(this.data.uid);
        this.messageService.add({
            severity: 'success',
            summary: 'UID-ul companiei a fost copiat!',
        });
    }
}
