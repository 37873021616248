import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { SelectableRow, Table } from 'primeng/table';
import { HttpApiService } from '../../services/base/http-api.service';
import { finalize } from 'rxjs/operators';
import { SttTableService } from './stt-table.service';
import { LazyLoadEvent } from 'primeng/api';
import { DatePipe, DecimalPipe } from '@angular/common';
import { ApiResponseModel } from '../../models/api-response.model';

interface TableColumn {
    field: string;
    name: string;
    header: string;
    sortable: boolean;
    filterable: boolean;
    format: string;
    frozen: 'left' | 'right' | null;
    template?: string;
}

@Component({
    selector: 'app-stt-table',
    templateUrl: './stt-table.component.html',
    styleUrls: ['./stt-table.component.scss'],
})
export class SttTableComponent implements OnInit, AfterViewInit {
    public tableColumns: Array<TableColumn> = [];
    public tableItems: Array<any> = [];
    public lastLazyLoadEvent: LazyLoadEvent;
    public loading = false;
    public tableLoading = false;
    public expandedRowKeys: any;

    @ViewChild('grid', { static: false }) grid: Table;
    @ViewChild('container', { static: false }) container: ElementRef;
    @ViewChild('expandReference', { static: false }) expandReference: ElementRef;

    setup(value: any, id: string) {
        if (value != null) {
            this.grid.filters[id][0].value = value;
        }
    }

    @Input() query: SttTableService;
    @Input() endpoint: string;
    @Input() tableColumnsEndpoint: string;
    @Input() actionTemplate: any;
    @Input() selectionMode = 'single';
    @Input() enableSelection = false;
    @Input() expandComponent: any = null;

    @Output() lazyLoad = new EventEmitter<LazyLoadEvent>();
    @Output() itemsLoad = new EventEmitter<any>();
    @Output() rowSelect = new EventEmitter<SelectableRow>();
    @Output() emitExpandReference = new EventEmitter<any>();

    public selectedItems: Array<any> = [];

    public containerWith: number = null;
    public expandComputedMargin = 0;

    constructor(
        private httpApiService: HttpApiService,
        private datePipe: DatePipe,
        private decimalPipe: DecimalPipe,
        private cd: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.emitExpandReference.emit(this.emitExpandReference);
        this.getTableColumns();
    }

    ngAfterViewInit(): void {
        // Fix margin left of expand container
        const querySelector = this.grid.el.nativeElement.querySelector('.p-datatable-wrapper');
        const context = this;
        querySelector.addEventListener('scroll', () => {
            context.expandComputedMargin = querySelector.scrollLeft;
        });
    }

    alignEndColumn(col: any): boolean {
        return col.format === 'int' || col.format === 'float' || col.format === 'datetime' || col.name === 'actions';
    }

    getTableColumns(): void {
        this.loading = true;
        this.httpApiService
            .httpGet(this.endpoint, { onlyColumns: true })
            .pipe(finalize(() => (this.loading = false)))
            .subscribe((response: any) => {
                this.tableColumns = response.payload;
                this.containerWith = this.container.nativeElement.offsetWidth - 50;
            });
    }

    getTableItems(params: any): void {
        this.tableLoading = true;
        this.httpApiService
            .httpPost(this.endpoint, this.query.getParams(this.lastLazyLoadEvent))
            .pipe(finalize(() => (this.tableLoading = false)))
            .subscribe((response: ApiResponseModel) => {
                this.query.total = response.payload.total;
                this.tableItems = response.payload.data;
                this.itemsLoad.subscribe(this.tableItems);
            });
    }

    clear(table: Table): void {
        table.clear();
    }

    onLazyLoad($event: LazyLoadEvent) {
        this.lastLazyLoadEvent = $event;
        this.getTableItems(this.query.getParams($event));
        this.cd.detectChanges();
        this.lazyLoad.subscribe($event);
    }

    getElementValue(obj: any, is: any, value?: string): any {
        try {
            if (typeof is === 'string') {
                return this.getElementValue(obj, is.split('.'), value);
            } else if (is.length === 1 && value !== undefined) {
                return (obj[is[0]] = value);
            } else if (is.length === 0) {
                return obj;
            } else {
                return this.getElementValue(obj[is[0]], is.slice(1), value);
            }
        } catch (e) {
            return ' - ';
        }
    }

    formatValue(value: any, format: any): any {
        switch (format) {
            case 'translate':
                // TODO Translate implementation
                return value + ' - trans';
            case 'datetime':
                return this.datePipe.transform(value, 'yyyy-MM-dd HH:mm');
            case 'date':
                return this.datePipe.transform(value, 'yyyy-MM-dd');
            case 'float':
                return this.decimalPipe.transform(value, '1.2-2');
            case 'boolean':
                return value === true || value === 1 ? 'Da' : 'Nu';
            case 'boolean_null':
                return value ? 'Nu' : 'Da';
            default:
                return value;
        }
    }

    renderValue(rowValue: any, column: any): string {
        const value = this.getElementValue(rowValue, column.field);
        return this.formatValue(value, column.format);
    }

    getFiledType(col: any): string {
        switch (col.format) {
            case 'int': {
                return 'numeric';
            }
            case 'date': {
                return 'date';
            }
            case 'datetime': {
                return 'date';
            }
            case 'string': {
                return 'text';
            }
            default: {
                return 'text';
            }
        }
    }

    checkIfHasFilters(): boolean {
        const filterableColumns = this.tableColumns.filter((column: TableColumn) => column.filterable === true);
        return filterableColumns.length > 0;
    }

    public refresh(): void {
        this.onLazyLoad(this.lastLazyLoadEvent);
    }

    onRowSelect($event: any) {
        this.rowSelect.emit($event);
    }
}
