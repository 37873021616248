import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
    selector: 'app-stt-calendar',
    templateUrl: './stt-calendar.component.html',
    styleUrls: ['./stt-calendar.component.scss'],
})
export class SttCalendarComponent implements OnInit {
    @Input() control?: AbstractControl;
    @Input() icon: string;
    @Input() inputId: string;
    @Input() label = 'Data';
    @Input() iconPosition = 'left';
    @Input() disabledDays: Array<number>;
    @Input() minimumDate: Date;

    convertedControl: FormControl;

    constructor() {}

    hasRequired(abstractControl: AbstractControl): boolean {
        if (abstractControl && abstractControl.validator) {
            const validator = abstractControl.validator({} as AbstractControl);
            if (validator && validator['required']) {
                return true;
            }
        }
        return false;
    }

    ngOnInit(): void {
        this.convertedControl = this.control as FormControl;
    }
}
