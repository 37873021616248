<div class="p-inputgroup">
    <span class="p-inputgroup-addon" *ngIf="icon && iconPosition === 'left'">
        <i [class]="icon"></i>
    </span>
    <div class="p-float-label">
        <textarea
            [id]="inputId"
            [formControl]="convertedControl"
            pInputTextarea
            class="description"
            [ngStyle]="{ 'min-height': minHeight }"
        ></textarea>
        <label [for]="inputId">
            {{ label }}
            <span *ngIf="hasRequired(control)" style="color: red; font-style: italic">*</span>
        </label>
    </div>
    <span class="p-inputgroup-addon" *ngIf="icon && iconPosition === 'right'">
        <i [class]="icon"></i>
    </span>
</div>
<app-stt-validator-message [control]="control"></app-stt-validator-message>
