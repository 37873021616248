<section [formGroup]="form" class="grid mt-2">
    <div class="col-12 grid">
        <div class="col-6 pr-3">
            <app-stt-input
                [control]="form.controls['freyaUrl']"
                label="URL Freya"
                icon="pi pi-globe"
                inputId="freyaUrl"
                type="text"
            ></app-stt-input>
        </div>
        <div class="col-6 pl-3 border-left-1 border-gray-400">
            <app-stt-input
                [control]="form.controls['freyaApiKey']"
                label="Cheie API Freya"
                icon="pi pi-database"
                inputId="freyaApiKey"
                type="text"
            ></app-stt-input>
        </div>
    </div>
    <div class="col-12 grid">
        <div class="col-6 pr-3">
            <app-stt-input
                [control]="form.controls['freyaUser']"
                label="Utilizator Freya"
                icon="pi pi-user"
                inputId="freyaUser"
                type="text"
            ></app-stt-input>
        </div>
        <div class="col-6 pl-3 border-gray-400 border-left-1">
            <app-stt-input
                [control]="form.controls['freyaPassword']"
                label="Parola Freya"
                icon="pi pi-key"
                inputId="freyaPassword"
                type="text"
            ></app-stt-input>
        </div>
    </div>
    <div class="col-12 grid" *ngIf="cloudCompany">
        <div class="col-6">
            <app-stt-dropdown
                icon="fas fa-building fa-fw"
                [formControlParent]="form.controls['associatedPosDevice']"
                [fields]="{ text: 'name', value: 'uid' }"
                label="POS Asociat"
                [endPoint]="posDeviceEndpoint"
            ></app-stt-dropdown>
        </div>
    </div>
    <div class="col-12 grid">
        <div class="col-6 checkboxes">
            <p-checkbox
                formControlName="allowDispatcher"
                pTooltip="Bifați dacă doriți să folosiți FreyaSync Dispecer."
                tooltipPosition="left"
                label="Sincronizare Dispecer"
                [binary]="true"
            ></p-checkbox>
        </div>
        <div class="col-6 checkboxes">
            <p-checkbox
                formControlName="overwritePrice"
                pTooltip="Bifați dacă doriți sa modificați prețurile din FreyaSync cu cele din Freya."
                tooltipPosition="left"
                label="Suprascriere preț din Freya"
                [binary]="true"
            ></p-checkbox>
            <p-checkbox
                formControlName="getDescriptionFromFreya"
                pTooltip="Bifați dacă doriți să preluați descrierile produselor din Freya."
                tooltipPosition="left"
                label="Preluare descrieri produse din Freya"
                [binary]="true"
            ></p-checkbox>
        </div>
    </div>
    <div class="col-12">
        <p-divider></p-divider>
    </div>
    <div class="col-12 grid mt-1" *ngIf="ecommerceModule">
        <div class="col-6 pr-3">
            <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                    <i class="pi pi-table"></i>
                </span>
                <div class="p-float-label">
                    <ng-select
                        #eCommerceDropdown
                        [items]="eCommerceTypes"
                        bindLabel="name"
                        bindValue="id"
                        formControlName="ecommerceType"
                    ></ng-select>
                    <label>Tip E-Commerce</label>
                </div>
            </div>
        </div>
        <div class="col-6 pl-3 border-gray-400 border-left-1">
            <app-stt-input
                [control]="form.controls['ecommerceUrl']"
                label="URL E-Commerce"
                icon="pi pi-globe"
                inputId="ecommerceUrl"
                type="text"
            ></app-stt-input>
        </div>
    </div>
    <div class="col-12 grid" *ngIf="ecommerceModule">
        <div class="col-6 pr-3">
            <app-stt-input
                [control]="form.controls['ecommerceUser']"
                label="Cheie E-Commerce"
                icon="pi pi-key"
                inputId="ecommerceUser"
                type="text"
            ></app-stt-input>
        </div>
        <div class="col-6 pl-3 border-gray-400 border-left-1">
            <app-stt-input
                [control]="form.controls['ecommercePassword']"
                label="Secret E-Commerce"
                icon="pi pi-eye"
                inputId="ecommercePassword"
                type="text"
            ></app-stt-input>
        </div>
    </div>
    <div class="col-12 grid" *ngIf="ecommerceModule">
        <div class="col-6 checkboxes">
            <p-checkbox
                formControlName="allowEcommerce"
                pTooltip="Bifați dacă doriți să folosiți o platformă de E-Commerce."
                tooltipPosition="left"
                label="Sincronizare E-Commerce"
                [binary]="true"
            ></p-checkbox>
            <p-checkbox
                formControlName="syncOrders"
                pTooltip="Bifați dacă doriți să sincronizați comenzile din E-Commerce în Freya."
                tooltipPosition="left"
                label="Sincronizare Comenzi"
                [binary]="true"
            ></p-checkbox>
            <p-checkbox
                formControlName="syncDescriptionToEcommerce"
                pTooltip="Bifați dacă doriți să sincronizați descrierea produselor din FreyaSync în E-Commerce."
                tooltipPosition="left"
                label="Sincronizare descriere produse în E-Commerce"
                [binary]="true"
            ></p-checkbox>
            <p-checkbox
                formControlName="createNewEcommerceProducts"
                pTooltip="Bifați dacă doriți să fie create în site produsele inexistente"
                tooltipPosition="left"
                label="Creare automată de produse în site"
                [binary]="true"
            ></p-checkbox>
        </div>
        <div class="col-6 checkboxes">
            <p-checkbox
                formControlName="ecommerceSkipCategorySync"
                label="Omite sincronizarea de categorii in E-Commerce"
                pTooltip="Bifați dacă NU doriți să trimiteți categoriile din FreyaSync în platforma de E-Commerce."
                tooltipPosition="left"
                [binary]="true"
            ></p-checkbox>
            <p-checkbox
                formControlName="ecommerceSkipProductSync"
                label="Omite sincronizarea automata de produse in E-Commerce"
                pTooltip="Bifați dacă NU doriți să trimiteți produsele din FreyaSync în platforma de E-Commerce."
                tooltipPosition="left"
                [binary]="true"
            ></p-checkbox>
            <p-checkbox
                formControlName="syncNameToEcommerce"
                label="Sincronizare nume produse in E-Commerce"
                pTooltip="Bifați dacă doriți să sincronizați numele produselor în E-Commerce, altfel, se va păstra numele din E-Commerce."
                tooltipPosition="left"
                [binary]="true"
            ></p-checkbox>
            <p-checkbox
                *ngIf="cloudCompany"
                formControlName="syncProductsBarcodes"
                label="Sincronizare automată produse pe baza codului de bare"
                pTooltip="Bifați dacă doriți să sincronizați automat produsele cu cele din Freya în baza codului de bare."
                tooltipPosition="left"
                [binary]="true"
            ></p-checkbox>
        </div>
    </div>
    <div class="col-12">
        <p-divider *ngIf="ecommerceModule"></p-divider>
    </div>
    <div class="col-12 grid mt-1">
        <div class="col-3 justify-content-center flex">
            <button
                pButton
                type="button"
                (click)="testFreyaConnection()"
                icon="pi pi-wrench"
                pTooltip="Apăsați dacă doriți să testați conexiunea către Freya."
                tooltipPosition="top"
                [loading]="loading"
                [disabled]="!uid"
                label="Testează conexiunea Freya"
            ></button>
        </div>
        <div class="col-3 justify-content-center flex">
            <button
                *ngIf="ecommerceModule"
                #testECommerceButton
                pButton
                type="button"
                icon="pi pi-wrench"
                pTooltip="Apăsați dacă doriți să testați conexiunea cu platforma de E-Commerce."
                tooltipPosition="top"
                [loading]="loading"
                [disabled]="!uid"
                (click)="testEcommerceConnection()"
                label="Testează conexiunea E-Commerce"
            ></button>
        </div>
        <div class="col-3 justify-content-center flex">
            <button
                pButton
                type="button"
                icon="pi pi-box"
                pTooltip="Apăsați pentru a prelua produsele din Freya (3 / Cloud)."
                tooltipPosition="top"
                [disabled]="!uid"
                [loading]="loading"
                (click)="getProducsFromFreya()"
                label="Preluare produse din Freya"
            ></button>
        </div>
        <div class="col-3 justify-content-center flex">
            <button
                *ngIf="ecommerceModule"
                #getEcommerceProductsButton
                pButton
                type="button"
                icon="pi pi-box"
                pTooltip="Apăsați pentru a prelua produsele din platforma de E-Commerce."
                tooltipPosition="top"
                [loading]="loading"
                [disabled]="!uid"
                (click)="getProducsFromEcomerce()"
                label="Preluare produse din E-Commerce"
            ></button>
        </div>
    </div>
</section>
