<div *ngIf="control && control.invalid && control.dirty">
    <p class="red-text" *ngIf="control.errors?.serverError; else clientErrors">
        {{ control.errors?.serverError }}
    </p>
    <ng-template #clientErrors>
        <p class="red-text" *ngIf="control.hasError('required')">Acest câmp este obligatoriu!</p>
        <p class="red-text" *ngIf="control.hasError('email')">Acesta nu este un email valid!</p>
        <p class="red-text" *ngIf="control.hasError('minlength')">
            Acest câmp permite minim {{ control.errors.minlength.requiredLength }} caractere!
        </p>
        <p class="red-text" *ngIf="control.hasError('maxlength')">
            Acest câmp permite maxim {{ control.errors.maxlength.requiredLength }} caractere!
        </p>
        <p class="red-text" *ngIf="control.hasError('passwordsMatch')">Parolele nu sunt similare!</p>
        <p class="red-text" *ngIf="control.hasError('min')">
            Acest câmp trebuie să aibe o valoare mai mare sau egală cu {{ this.control.errors['min'].min }}!
        </p>
        <p class="red-text" *ngIf="control.hasError('max')">
            Acest câmp trebuie să aibe o valoare mai mare sau egală cu {{ this.control.errors['max'].max }}!
        </p>
    </ng-template>
</div>
