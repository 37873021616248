<section [formGroup]="form" class="mt-2">
    <div
        class="flex align-items-center"
        [ngClass]="{ 'justify-content-between': !hasAddresses, 'justify-content-end': hasAddresses }"
    >
        <p *ngIf="!hasAddresses">Acest client nu are adrese asociate.</p>
        <button pButton label="Adauga" type="button" icon="pi pi-plus" (click)="addAddress()"></button>
    </div>
    <p-divider *ngIf="hasAddresses"></p-divider>
    <p-accordion *ngIf="hasAddresses" formArrayName="addresses">
        <p-accordionTab
            *ngFor="let address of formGroups; let i = index"
            [formGroupName]="i"
            [header]="addresses[i] ? addresses[i] : 'Adresa noua'"
        >
            <div class="grid mt-2">
                <div class="col-12">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-home"></i>
                        </span>
                        <div class="p-float-label">
                            <p-autoComplete
                                formControlName="fullAddress"
                                inputId="fullAddress"
                                [suggestions]="results"
                                (onSelect)="onAutocompleteOptionSelected($event, i)"
                                name="fullAddress"
                                (completeMethod)="onAddressChange(i)"
                            ></p-autoComplete>
                            <label for="fullAddress">Adresa</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="grid mt-2">
                <div class="col-6">
                    <app-stt-input
                        [control]="formGroups[i].controls['county']"
                        label="Judet"
                        inputId="county"
                        icon="pi pi-map"
                        type="text"
                    ></app-stt-input>
                </div>
                <div class="col-6">
                    <app-stt-input
                        [control]="formGroups[i].controls['city']"
                        label="Oras"
                        inputId="city"
                        icon="pi pi-building"
                        type="text"
                    ></app-stt-input>
                </div>
            </div>
            <div class="grid mt-2">
                <div class="col-4">
                    <app-stt-input
                        [control]="formGroups[i].controls['street']"
                        label="Strada"
                        inputId="street"
                        icon="pi pi-directions"
                        type="text"
                    ></app-stt-input>
                </div>
                <div class="col-4">
                    <app-stt-input
                        [control]="formGroups[i].controls['number']"
                        label="Numar"
                        inputId="number"
                        icon="pi pi-hashtag"
                        type="text"
                    ></app-stt-input>
                </div>
                <div class="col-4">
                    <app-stt-input
                        [control]="formGroups[i].controls['block']"
                        label="Bloc"
                        inputId="block"
                        icon="pi pi-building"
                        type="text"
                    ></app-stt-input>
                </div>
            </div>
            <div class="grid mt-2">
                <div class="col-4">
                    <app-stt-input
                        [control]="formGroups[i].controls['staircase']"
                        label="Scara"
                        inputId="staircase"
                        icon="pi pi-building"
                        type="text"
                    ></app-stt-input>
                </div>
                <div class="col-4">
                    <app-stt-input
                        [control]="formGroups[i].controls['floor']"
                        label="Etaj"
                        inputId="floor"
                        icon="pi pi-building"
                        type="text"
                    ></app-stt-input>
                </div>
                <div class="col-4">
                    <app-stt-input
                        [control]="formGroups[i].controls['apartments']"
                        label="Apartament"
                        inputId="apartments"
                        icon="pi pi-building"
                        type="text"
                    ></app-stt-input>
                </div>
            </div>
            <div class="grid mt-2">
                <div class="col-12">
                    <app-stt-input
                        [control]="formGroups[i].controls['otherDetails']"
                        label="Alte detalii"
                        inputId="otherDetails"
                        icon="pi pi-exclamation-circle"
                        type="text"
                    ></app-stt-input>
                </div>
            </div>
            <p-progressBar *ngIf="loading" mode="indeterminate"></p-progressBar>
            <div class="buttons">
                <button
                    type="button"
                    [disabled]="!isCreated(i)"
                    (click)="overlay.toggle($event)"
                    pButton
                    pRipple
                    [loading]="loading"
                    class="p-button-danger"
                    label="Sterge"
                    icon="pi pi-trash"
                ></button>
                <button
                    type="button"
                    (click)="saveAddress(i)"
                    pButton
                    [loading]="loading"
                    pRipple
                    class="p-button-success"
                    label="Salveaza"
                    icon="pi pi-save"
                ></button>
                <p-overlayPanel #overlay>
                    <h3>Esti sigur ca vrei sa stergi aceasta adresa?</h3>
                    <div class="buttons">
                        <button
                            pButton
                            pRipple
                            type="button"
                            class="p-button-danger"
                            (click)="deleteAddress(i)"
                            label="Da"
                        ></button>
                        <button pButton pRipple type="button" (click)="overlay.hide()" label="Nu"></button>
                    </div>
                </p-overlayPanel>
            </div>
        </p-accordionTab>
    </p-accordion>
</section>
