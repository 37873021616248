import { ApiResponseModel } from '../../api-response.model';
import { BaseNomenclatureModel } from '../../base.model';
import { CompanyModel } from '../../../../auth/models/company.model';
import { ProductModel } from './product.model';
import { LocationModel } from '../locations/location.model';

export class ProductLocationModel extends BaseNomenclatureModel {
    product_id: number;
    company_id: number;
    location_id: number;

    company?: CompanyModel;
    location?: LocationModel;
    product?: ProductModel;

    ecommerce_id?: number;
    freya_id?: number;

    freyaName?: string;
    freyaCode?: string;
    price?: number;

    seenInEcomerce: boolean;
    seenInDispecer: boolean;
    seenInDigitalMenu: boolean;

    lastSyncDateOnFreya?: Date;
    lastSyncDateOnEcommerce?: Date;
    menus: any;
}

export class ProductLocationApiResponseModel extends ApiResponseModel {
    override payload: ProductLocationModel | ProductLocationModel[];
}
