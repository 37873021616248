<div *ngIf="shouldRender(item)">
    <p-accordion *ngIf="item.items; else onlyLink">
        <p-accordionTab [selected]="active">
            <ng-template pTemplate="header">
                <div class="tab-header">
                    <i [class]="item.icon" class="fa-2x" [class.active-header]="active"></i>
                    <span [class.active-header]="active">{{ item.label }}</span>
                </div>
            </ng-template>
            <ng-template pTemplate="body">
                <div *ngFor="let elem of item.items; let i = index">
                    <div *ngIf="shouldRender(elem)">
                        <div *ngIf="!elem.items">
                            <a
                                [routerLink]="elem.routerLink[0]"
                                class="accordion-item-link"
                                routerLinkActive="selected"
                            >
                                <i routerLinkActive="active-header" [ngClass]="elem.icon"></i>
                                <p routerLinkActive="active-header">
                                    {{ elem.label }}
                                </p>
                            </a>
                        </div>
                        <div *ngIf="elem.items">
                            <div class="dropdown">
                                <div
                                    #dropdownHeaders
                                    class="dropdown-header accordion-item-link"
                                    (click)="switchDropdown(dropdownContents)"
                                >
                                    <i [class.active-header]="dropdownHasActiveItems(i)" [ngClass]="elem.icon"></i>
                                    <p [class.active-header]="dropdownHasActiveItems(i)">{{ elem.label }}</p>
                                </div>
                            </div>
                            <ul class="dropdown-content" #dropdownContents>
                                <li *ngFor="let dropdownItem of elem.items">
                                    <a
                                        [routerLink]="dropdownItem.routerLink[0]"
                                        class="accordion-item-link"
                                        routerLinkActive="selected"
                                        *ngIf="shouldRender(dropdownItem)"
                                    >
                                        <i routerLinkActive="active-header" [ngClass]="dropdownItem.icon"></i>
                                        <p routerLinkActive="active-header">
                                            {{ dropdownItem.label }}
                                        </p>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </ng-template>
        </p-accordionTab>
    </p-accordion>

    <ng-template #onlyLink>
        <a [routerLink]="item.routerLink" class="item-link">
            <span class="tab-header">
                <i [ngClass]="item.icon" class="fa-2x" routerLinkActive="active-header"></i>
                <p routerLinkActive="active-header">{{ item.label }}</p>
            </span>
        </a>
    </ng-template>
</div>
