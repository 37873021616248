<p class="text-center">
    Aceasta optiune necesita instalarea pluginului <strong>'Viva Wallet for WooCommerce - Multi Account'</strong>
</p>
<section [formGroup]="form">
    <div class="flex justify-content-center">
        <p-checkbox
            [binary]="true"
            label="Activeaza modul de testare"
            formControlName="vivaWalletTestingMode"
        ></p-checkbox>
    </div>
    <div class="grid">
        <div class="col-6 column">
            <h4 class="text-center">Setari productie</h4>
            <div class="inputs">
                <app-stt-input
                    [control]="form.controls['vivaWalletClientId']"
                    label="ID Client"
                    icon="pi pi-user"
                    inputId="vivaWalletClientId"
                    type="text"
                ></app-stt-input>
                <app-stt-input
                    [control]="form.controls['vivaWalletClientSecret']"
                    label="Secret Client"
                    icon="pi pi-key"
                    inputId="vivaWalletClientSecret"
                    type="text"
                ></app-stt-input>
                <app-stt-input
                    [control]="form.controls['vivaWalletClientSourceCode']"
                    label="Source Code Client"
                    icon="pi pi-code"
                    inputId="vivaWalletClientSourceCode"
                    type="text"
                ></app-stt-input>
            </div>
        </div>
        <div class="col-6 column">
            <h4 class="text-center">Setari testare</h4>
            <div class="inputs">
                <app-stt-input
                    [control]="form.controls['vivaWalletTestClientId']"
                    label="ID Client"
                    icon="pi pi-user"
                    inputId="vivaWalletTestClientId"
                    type="text"
                ></app-stt-input>
                <app-stt-input
                    [control]="form.controls['vivaWalletTestClientSecret']"
                    label="Secret Client"
                    icon="pi pi-key"
                    inputId="vivaWalletTestClientSecret"
                    type="text"
                ></app-stt-input>
                <app-stt-input
                    [control]="form.controls['vivaWalletTestClientSourceCode']"
                    label="Source Code Client"
                    icon="pi pi-code"
                    inputId="vivaWalletTestClientSourceCode"
                    type="text"
                ></app-stt-input>
            </div>
        </div>
    </div>
</section>
