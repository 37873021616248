<h2 *ngIf="uid && !onMyCompany">Editare companie</h2>
<h2 *ngIf="onMyCompany">Compania mea</h2>
<form autocomplete="off" [class.card]="uid" (ngSubmit)="onSubmit()" [formGroup]="form" class="mt-2">
    <p-tabView>
        <p-tabPanel [disabled]="loading" header="Date companie" leftIcon="pi pi-building">
            <app-companies-add-edit-data [form]="form" [onMyCompany]="onMyCompany"></app-companies-add-edit-data>
        </p-tabPanel>
        <p-tabPanel [disabled]="loading" *ngIf="uid" header="Utilizatori" leftIcon="pi pi-user-edit">
            <ng-template pTemplate="content">
                <app-companies-add-edit-users [companyId]="itemModel.id"></app-companies-add-edit-users>
            </ng-template>
        </p-tabPanel>
        <p-tabPanel [disabled]="loading" *ngIf="uid" header="Locații" leftIcon="pi pi-home">
            <ng-template pTemplate="content">
                <app-locations [companyId]="itemModel.id" [companyUid]="itemModel.uid"></app-locations>
            </ng-template>
        </p-tabPanel>
        <p-tabPanel [disabled]="loading" header="Setări" leftIcon="pi pi-cog">
            <app-companies-add-edit-settings [form]="form"></app-companies-add-edit-settings>
        </p-tabPanel>
        <p-tabPanel [disabled]="loading || !dispecerModule" header="Call center" leftIcon="pi pi-phone">
            <app-companies-add-edit-call-center [form]="callCenterData"></app-companies-add-edit-call-center>
        </p-tabPanel>
        <p-tabPanel [disabled]="loading || !digitalMenuModule" header="Imagini" leftIcon="pi pi-image">
            <app-companies-add-edit-photos [companyUid]="itemModel.uid"></app-companies-add-edit-photos>
        </p-tabPanel>
        <p-tabPanel [disabled]="loading" header="Freya Cloud" leftIcon="pi pi-cloud-upload" *ngIf="hasFreyaCloud">
            <app-companies-add-edit-freya-cloud
                [companyUid]="itemModel.uid"
                [form]="form"
            ></app-companies-add-edit-freya-cloud>
        </p-tabPanel>
    </p-tabView>

    <p-progressBar mode="indeterminate" *ngIf="loading"></p-progressBar>
    <p-divider></p-divider>
    <div class="actions-area">
        <div class="actions-left">
            <a [routerLink]="['/nomenclatures/companies']">
                <button
                    *ngIf="!uid; else editButton"
                    pButton
                    pRipple
                    type="button"
                    label="Anulare"
                    class="p-button-raised p-button-danger"
                    icon="pi pi-ban"
                    [loading]="loading"
                ></button>
            </a>
            <ng-template #editButton>
                <a [routerLink]="['/nomenclatures/companies']">
                    <button
                        *ngIf="!onMyCompany"
                        pButton
                        pRipple
                        type="button"
                        label="Anulare"
                        class="p-button-raised p-button-danger"
                        icon="pi pi-ban"
                        [loading]="loading"
                    ></button>
                </a>
            </ng-template>
        </div>

        <div class="actions-right">
            <button
                pButton
                pRipple
                type="submit"
                label="Salvează"
                class="p-button-raised p-button-success p-outlined-button"
                icon="pi pi-save"
                [loading]="loading"
            ></button>
        </div>
    </div>
</form>
