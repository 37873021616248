import { ApiResponseModel } from '../../shared/models/api-response.model';
import { BaseModel } from '../../shared/models/base.model';
import { LocationModel } from '../../shared/models/nomenclatures/locations/location.model';
import { FreyaCloudPaymentTypeEnum } from '../../shared/enum/freya-cloud/freya-cloud-payment-type.enum';
import { FreyaCloudOutputNoticeTypeEnum } from '../../shared/enum/freya-cloud/freya-cloud-output-notice-type.enum';
import { CompanyModuleEnum } from 'src/app/shared/enum/company-module.enum';

export class CompanyModel extends BaseModel {
    id: number;
    name: string;
    uid: string;
    uniqueCode: string;
    locationsLimit: number;
    usersLimit: number;
    nrRegCom: string;
    email: string;
    phone: string;
    county_id: number;
    website: string;
    isActive: boolean = true;
    usesGoogleGeo: boolean;
    googleApiKey?: string;
    hasPolygons: boolean = true;
    locations: Array<LocationModel> = [];
    callCenterData: CompanyCallCenterModel = new CompanyCallCenterModel();
    freyaType?: string;
    freyaLocal: boolean;
    freyaCloud: boolean;
    freyaCloudUsername: string;
    freyaCloudPassword: string;
    freyaCloudUrl: string;
    freyaCloudApiKey: string;
    freyaCloudPaymentType: FreyaCloudPaymentTypeEnum = FreyaCloudPaymentTypeEnum.NO_PAYMENT_ON_ORDER;
    freyaCloudOutputNoticeType: FreyaCloudOutputNoticeTypeEnum = FreyaCloudOutputNoticeTypeEnum.NO_OUTPUT_NOTICE;
    freyaCloudRepositoryUid?: string;
    syncStocks: boolean = false;
    sendPaymentOnOrderDescription: boolean = false;
    sendBuildingDataOnOrderDescription: boolean = false;
    sendOrderToKitchen: boolean = false;
    modules: Array<CompanyModuleModel> = [];
    sendMappingSummaries: boolean;
    mappingSummaryEmail: string;

    setFreyaType(): void {
        if (this.freyaType === 'local') {
            this.freyaLocal = true;
            this.freyaCloud = false;
        } else if (this.freyaType === 'cloud') {
            this.freyaLocal = false;
            this.freyaCloud = true;
        }
    }
}

export class CompanyCallCenterModel {
    host: string;
    scheme: string;
    port: number;
    username: string;
    secret: string;
}

export class CompanyApiResponseModel extends ApiResponseModel {
    override payload: CompanyModel;
}

export class CompanyModuleModel {
    module: CompanyModuleEnum;
    active: boolean;
}
