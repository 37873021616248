<div class="grid mt-2" [formGroup]="form">
    <div class="col-12">
        <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
                <i class="pi pi-home"></i>
            </span>
            <div class="p-float-label">
                <p-autoComplete
                    formControlName="fullAddress"
                    inputId="fullAddress"
                    [suggestions]="results"
                    (onSelect)="onAutocompleteOptionSelected($event)"
                    name="fullAddress"
                    (completeMethod)="onAddressChange()"
                ></p-autoComplete>
                <label for="fullAddress">Adresă</label>
            </div>
        </div>
        <app-stt-validator-message [control]="form.controls['fullAddress']"></app-stt-validator-message>
    </div>
    <div class="col-12">
        <p-divider></p-divider>
    </div>
    <div *ngIf="loading" class="loader-container col-12 mb-4">
        <p-progressBar mode="indeterminate"></p-progressBar>
    </div>
    <div class="col-12 grid">
        <div class="col-6 pr-3">
            <app-stt-input
                label="Județ"
                icon="pi pi-directions"
                type="text"
                [readonly]="true"
                [control]="form.controls['county']"
                inputId="county"
            ></app-stt-input>
        </div>
        <div class="col-6 pl-3 border-gray-400 border-left-1">
            <app-stt-input
                label="Oraș"
                icon="pi pi-directions"
                type="text"
                [readonly]="true"
                [control]="form.controls['city']"
                inputId="city"
            ></app-stt-input>
        </div>
    </div>
    <div class="col-12 mt-1 grid">
        <div class="col-6 pr-3">
            <app-stt-input
                label="Latitudine"
                icon="pi pi-globe"
                type="text"
                [readonly]="true"
                [control]="form.controls['latitude']"
                inputId="latitude"
            ></app-stt-input>
        </div>
        <div class="col-6 pl-3 border-gray-400 border-left-1">
            <app-stt-input
                label="Longitudine"
                icon="pi pi-globe"
                type="text"
                [readonly]="true"
                [control]="form.controls['longitude']"
                inputId="longitude"
            ></app-stt-input>
        </div>
    </div>
</div>
