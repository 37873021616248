<div class="grid lg:px-3 xl:px-4 transition-all transition-duration-200 mt-2" [formGroup]="form">
    <div class="col-12">
        <div class="grid">
            <div class="col-6 pr-3">
                <app-stt-input
                    inputId="freyaCloudUsername"
                    [control]="form.controls['freyaCloudUsername']"
                    icon="pi pi-user"
                    type="text"
                    label="Username"
                ></app-stt-input>
            </div>
            <div class="col-6 border-gray-400 border-left-1 pl-3 pr-3">
                <app-stt-input
                    inputId="freyaCloudPassword"
                    [control]="form.controls['freyaCloudPassword']"
                    icon="pi pi-key"
                    type="password"
                    label="Parola"
                ></app-stt-input>
            </div>
        </div>
        <div class="grid mt-4">
            <div class="col-6 pr-3">
                <app-stt-input
                    inputId="freyaCloudUrl"
                    [control]="form.controls['freyaCloudUrl']"
                    icon="pi pi-globe"
                    type="text"
                    label="URL"
                ></app-stt-input>
            </div>
            <div class="col-6 border-gray-400 border-left-1 pl-3">
                <app-stt-input
                    inputId="freyaCloudApiKey"
                    [control]="form.controls['freyaCloudApiKey']"
                    icon="pi pi-cloud"
                    type="text"
                    label="API Key"
                ></app-stt-input>
            </div>
        </div>
        <p-divider></p-divider>
        <div class="grid mt-4">
            <div class="pr-3 col-6">
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        <i class="pi pi-money-bill"></i>
                    </span>
                    <div class="p-float-label">
                        <ng-select
                            [items]="outputNoticeDropdownItems"
                            bindLabel="name"
                            bindValue="value"
                            formControlName="freyaCloudOutputNoticeType"
                        ></ng-select>
                        <label>Generare document stoc</label>
                    </div>
                </div>
            </div>
            <div class="border-gray-400 col-6 border-left-1 pl-3">
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        <i class="pi pi-money-bill"></i>
                    </span>
                    <div class="p-float-label">
                        <ng-select
                            [items]="paymentDropdownItems"
                            bindLabel="name"
                            bindValue="value"
                            formControlName="freyaCloudPaymentType"
                        ></ng-select>
                        <label>Trimitere plată pe comenzi</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid mt-4" *ngIf="companyUid">
            <div class="col-6 border-gray-400 pr-3">
                <app-stt-dropdown
                    label="Gestiune"
                    icon="pi pi-box"
                    [formControlParent]="form.controls['freyaCloudRepositoryUid']"
                    [fields]="{ text: 'name', value: 'uid' }"
                    [endPoint]="getRepositoryEndpoint"
                    pTooltip="Gestiunea este folosită pentru operații de stoc (documente stoc, sincronizare stoc în site)"
                ></app-stt-dropdown>
            </div>
            <div class="col-6 border-gray-400 border-left-1 pl-4">
                <div class="grid">
                    <div class="checkboxes">
                        <p-checkbox
                            formControlName="sendOrderToKitchen"
                            pTooltip="Bifați pentru a trimite automat comenzile în bucătărie"
                            tooltipPosition="left"
                            label="Trimite automat comenzi în bucătărie"
                            [binary]="true"
                        ></p-checkbox>
                        <p-checkbox
                            formControlName="syncStocks"
                            pTooltip="Bifați dacă doriți să sincronizați stocurile în site"
                            tooltipPosition="left"
                            label="Sincronizare stocuri în site"
                            [binary]="true"
                        ></p-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
