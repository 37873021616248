import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { finalize } from 'rxjs';
import { ApiResponseModel } from '../../../../../shared/models/api-response.model';
import { LocationModel } from '../../../../../shared/models/nomenclatures/locations/location.model';
import { ProductLocationModel } from '../../../../../shared/models/nomenclatures/products/product-location.model';
import { LocationService } from '../../../../../shared/services/app/nomenclatures/location.service';
import { ProductService } from '../../../../../shared/services/app/nomenclatures/product.service';
import { CompanyService } from 'src/app/shared/services/app/nomenclatures/company.service';
import { CompanyModuleModel } from 'src/app/auth/models/company.model';
import { CompanyModuleEnum } from 'src/app/shared/enum/company-module.enum';
import { CompanyModel } from 'src/app/auth/models/company.model';
import { ProductsAddEditComponent } from '../products-add-edit.component';
import { ProductsAddEditDataComponent } from '../products-add-edit-data/products-add-edit-data.component';
@Component({
    selector: 'app-products-add-edit-location-data',
    templateUrl: './products-add-edit-location-data.component.html',
    styleUrls: ['./products-add-edit-location-data.component.scss'],
})
export class ProductsAddEditLocationDataComponent implements OnInit {
    @Input() locationsForm: FormArray;
    @Input() dataset: ProductLocationModel[];
    @Input() productUid: string;
    @Input() company: CompanyModel;
    @Input() locations: Array<LocationModel> = [];

    loading: boolean = true;
    items: Array<FormGroup>;

    seenInEcommerceModule: boolean;
    seenInDigitalMenuModule: boolean;
    seenInDispecerModule: boolean;

    constructor(
        private locationService: LocationService,
        private formBuilder: FormBuilder,
        private productService: ProductService,
        private messageService: MessageService,
        private companyService: CompanyService
    ) {}

    ngOnInit() {
        this.seenInEcommerceModule = this.company.modules.find(
            (element) => element.module === CompanyModuleEnum.ECOMMERCE
        )?.active;
        this.seenInDigitalMenuModule = this.company.modules.find(
            (element) => element.module === CompanyModuleEnum.DIGITAL_MENU
        )?.active;
        this.seenInDispecerModule = this.company.modules.find(
            (element) => element.module === CompanyModuleEnum.DISPATCHER
        )?.active;

        this.loading = true;
        this.items = this.locationsForm.controls as FormGroup[];
        this.locationsForm.clear();
        this.dataset.forEach((item: ProductLocationModel) => {
            this.addLocation(item);
        });
        this.loading = false;
    }

    addLocation(location: ProductLocationModel): void {
        const formGroup: FormGroup = this.formBuilder.group({
            name: [location.location.name],
            price: [location.price],
            seenInDispecer: [location.seenInDispecer],
            seenInEcomerce: [location.seenInEcomerce],
            seenInDigitalMenu: [location.seenInDigitalMenu],
            freya_id: [location.freya_id],
            ecommerce_id: [location.ecommerce_id],
            locationUid: [location.location.uid],
        });

        this.locationsForm.push(formGroup);
    }
}
