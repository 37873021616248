<p-divider></p-divider>
<div class="header">
    <h2>Utilizatori</h2>
    <button pButton pRipple icon="pi pi-plus" type="button" label="Adaugă" (click)="onAdd()"></button>
</div>
<p-divider></p-divider>

<div class="grid">
    <div class="col-12">
        <div class="card">
            <app-stt-table #table [actionTemplate]="actionTemplate" [endpoint]="tableEndpoint" [query]="tableQuery">
            </app-stt-table>
        </div>
    </div>
</div>
