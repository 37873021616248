<div class="grid lg:px-3 xl:px-4 transition-all transition-duration-200 mt-2" [formGroup]="form">
    <div class="col-12">
        <div class="grid">
            <div class="col-4 lg:col-5 pr-3 transition-all transition-duration-200">
                <app-stt-input
                    inputId="host"
                    [control]="form.controls['host']"
                    icon="pi pi-globe"
                    type="text"
                    label="Host"
                ></app-stt-input>
            </div>
            <div class="col-4 border-gray-400 border-left-1 px-3">
                <app-stt-input
                    inputId="scheme"
                    [control]="form.controls['scheme']"
                    icon="pi pi-sitemap"
                    type="text"
                    label="Protocol"
                ></app-stt-input>
            </div>
            <div class="col-4 lg:col-3 border-gray-400 border-left-1 pl-3 transition-all transition-duration-200">
                <app-stt-input
                    inputId="port"
                    [control]="form.controls['port']"
                    icon="pi pi-desktop"
                    type="number"
                    label="Port"
                ></app-stt-input>
            </div>
        </div>
        <p-divider></p-divider>
        <div class="grid mt-4">
            <div class="col-6 pr-3">
                <app-stt-input
                    inputId="username"
                    [control]="form.controls['username']"
                    icon="pi pi-user"
                    type="text"
                    label="Username"
                ></app-stt-input>
            </div>
            <div class="col-6 border-gray-400 border-left-1 pl-3">
                <app-stt-input
                    inputId="secret"
                    [control]="form.controls['secret']"
                    icon="pi pi-key"
                    type="text"
                    label="Secret"
                ></app-stt-input>
            </div>
        </div>
    </div>
</div>
