import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { CompanyModuleEnum } from 'src/app/shared/enum/company-module.enum';
import { UserService } from '../../../../../auth/user.service';
@Component({
    selector: 'app-companies-add-edit-data',
    templateUrl: './companies-add-edit-data.component.html',
    styleUrls: ['./companies-add-edit-data.component.scss'],
})
export class CompaniesAddEditDataComponent implements OnInit {
    constructor(private readonly userService: UserService) {}

    isAdmin = false;

    @Input() form: FormGroup;
    @Input() onMyCompany = false;

    ngOnInit() {
        this.isAdmin = this.userService.isAdmin();
    }

    get modules() {
        return this.form.get('modules') as FormArray;
    }

    getLabel(value: CompanyModuleEnum) {
        switch (value) {
            case CompanyModuleEnum.DIGITAL_MENU:
                return 'Digital menu';
            case CompanyModuleEnum.DISPATCHER:
                return 'Dispecer';
            case CompanyModuleEnum.ECOMMERCE:
                return 'Sincronizare site';
        }
    }

    getTooltip(value: CompanyModuleEnum) {
        switch (value) {
            case CompanyModuleEnum.DIGITAL_MENU:
                return 'Bifați dacă doriți „Digital menu” activat';
            case CompanyModuleEnum.DISPATCHER:
                return 'Bifați dacă doriți „Dispecer” activat';
            case CompanyModuleEnum.ECOMMERCE:
                return 'Bifați dacă doriți „Sincronizare site” activată';
        }
    }
}
