import { Component } from '@angular/core';
import { ProductCategoryModel } from 'src/app/shared/models/nomenclatures/products/product.model';
import { Router } from '@angular/router';
import { ProductCategoriesAddEditComponent } from '../product-categories-add-edit/product-categories-add-edit.component';
import { DialogService } from 'primeng/dynamicdialog';
import { CompanyService } from 'src/app/shared/services/app/nomenclatures/company.service';
import { SttTableComponent } from 'src/app/shared/components/stt-table/stt-table.component';

@Component({
    selector: 'app-product-categories-action-table',
    templateUrl: './product-categories-action-table.component.html',
    styleUrls: ['./product-categories-action-table.component.scss'],
})
export class ProductCategoriesActionTableComponent {
    public data: ProductCategoryModel;
    public table: SttTableComponent;
    constructor(private readonly router: Router, private readonly companyService: CompanyService) {}

    setParams(entity: ProductCategoryModel): void {
        this.data = entity;
    }

    setTableReference(table: SttTableComponent): void {
        this.table = table;
    }
}
