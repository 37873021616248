import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { MessageService } from 'primeng/api';

@Injectable({
    providedIn: 'root',
})
export class ValidationMessageService {
    constructor(private messageService: MessageService) {}

    public serverSideValidation(
        err: HttpErrorResponse,
        form: FormGroup,
        prefix = '',
        showOtherErrors: boolean = true
    ): void {
        if (err.status === 422) {
            this.messageService.add({
                severity: 'error',
                summary: 'Eroare de validare',
                detail: 'Datele introduse nu sunt corecte!',
            });
            const errors: Array<any> = err.error.payload.errors;
            Object.keys(errors).forEach((propertyName: any) => {
                const prop = propertyName.replace(prefix, '');

                const formControl = form.get(prop);

                if (formControl) {
                    let message = '';

                    if (Symbol.iterator in Object(errors[propertyName])) {
                        errors[propertyName].forEach((value: string, index: number) => {
                            message += value.replace(prefix, '') + '\r\n';
                        });
                    } else {
                        message = errors[propertyName].replace(prefix, '') + '\r\n';
                    }

                    formControl.setErrors({
                        serverError: message,
                    });
                } else {
                    if (showOtherErrors === true) {
                        let message = '';

                        errors[propertyName].forEach((value: string, index: number) => {
                            message += value;
                        });

                        this.messageService.add({
                            severity: 'error',
                            summary: 'Eroare la validarea datelor',
                            detail: message,
                        });
                    }
                }
            });
        }
    }

    getFormControlValidationErrors(formControl: FormControl): void {
        const controlErrors: ValidationErrors = formControl.errors;
        if (controlErrors != null) {
            Object.keys(controlErrors).forEach((keyError) => {
                console.log('keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
            });
        }
    }

    getFormValidationErrors(form: FormGroup): void {
        Object.keys(form.controls).forEach((key) => {
            const controlErrors: ValidationErrors = form.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach((keyError) => {
                    console.log(
                        'Key control: ' + key + ', keyError: ' + keyError + ', err value: ',
                        controlErrors[keyError]
                    );
                });
            }
        });
    }
}
