import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { LocationModel } from 'src/app/shared/models/nomenclatures/locations/location.model';

@Component({
    selector: 'app-locations-add-edit-data',
    templateUrl: './locations-add-edit-data.component.html',
    styleUrls: ['./locations-add-edit-data.component.scss'],
})
export class LocationsAddEditDataComponent implements OnInit, OnDestroy {
    shouldShowLocationProduct?: boolean;

    locationUid: string;
    locationSub: Subscription;

    @Input() form: FormGroup;
    @Input() tags: string[];
    @Input() company_id: number | null = null;
    @Input() location$: Observable<LocationModel>;

    @Output() tagChanged: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();
    constructor() {}

    ngOnInit(): void {
        this.locationSub = this.location$.subscribe((location?: LocationModel) => {
            if (!location) {
                this.shouldShowLocationProduct = false;
                return;
            }
            this.shouldShowLocationProduct = !location.company?.hasPolygons && location.company?.freyaLocal;
            this.locationUid = location.uid;
        });
    }

    ngOnDestroy(): void {
        this.locationSub.unsubscribe();
    }

    tagsChanged(): void {
        this.tagChanged.emit(this.tags);
    }
}
