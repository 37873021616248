import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponseModel } from '../../../models/api-response.model';
import { HttpApiService } from '../../base/http-api.service';
import { CompanyModel } from '../../../../auth/models/company.model';

@Injectable({
    providedIn: 'root',
})
export class CompanyService {
    private storedCompany!: CompanyModel;

    constructor(public httpApiService: HttpApiService) {}

    setCompany(company: CompanyModel) {
        this.storedCompany = company;
    }

    get company(): CompanyModel {
        return this.storedCompany;
    }

    hasCustomCompany(): boolean {
        const companyUid = localStorage.getItem('fss_custom_company');
        return companyUid !== null;
    }

    get customCompanyUid(): string | null {
        return localStorage.getItem('fss_custom_company');
    }

    setCustomCompanyUid(uid: string) {
        localStorage.setItem('fss_custom_company', uid);
    }

    clearCustomCompanyUid() {
        localStorage.removeItem('fss_custom_company');
    }

    getItem(uid: string): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('company/show', { uid: uid });
    }

    create(data: CompanyModel): Observable<ApiResponseModel> {
        return this.httpApiService.httpPost('company/store', data);
    }

    update(data: CompanyModel): Observable<ApiResponseModel> {
        return this.httpApiService.httpPost('company/update', data);
    }

    delete(data: CompanyModel): Observable<ApiResponseModel> {
        return this.httpApiService.httpDelete('company/destroy', data);
    }

    restore(data: CompanyModel): Observable<ApiResponseModel> {
        return this.httpApiService.httpPost('company/restore', data);
    }

    getInfoFromUniqueCode(cui: string): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('public/company/getExternalData', { cui: cui });
    }

    getAllCompanies(): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('company/index');
    }
}
