import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Route } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { RippleModule } from 'primeng/ripple';
import { SharedModule } from '../shared/shared.module';
import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';

const routes: Route[] = [
    { path: 'auth', redirectTo: 'auth/login', pathMatch: 'full' },
    {
        path: 'auth',
        component: AuthComponent,
        children: [{ path: 'login', component: LoginComponent }],
    },
];

@NgModule({
    declarations: [AuthComponent, LoginComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forRoot(routes),
        CardModule,
        SharedModule,
        DividerModule,
        ButtonModule,
        RippleModule,
        CheckboxModule,
    ],
})
export class AuthModule {}
