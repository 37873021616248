<div class="container">
    <a [routerLink]="['/nomenclatures/companies/add-edit']" [queryParams]="{ uid: this.data.uid }">
        <button pButton pRipple type="button" icon="fas fa-pencil" pTooltip="Editare" tooltipPosition="left"></button>
    </a>
    <button
        pButton
        pRipple
        type="button"
        (click)="copyToClipboard()"
        icon="fas fa-copy"
        pTooltip="Copiază UID"
        tooltipPosition="left"
    ></button>
    <button
        pButton
        pRipple
        type="button"
        class="p-button-danger"
        (click)="overlay.toggle($event)"
        icon="fas fa-trash"
        pTooltip="Șterge"
        tooltipPosition="left"
    >
        <p-overlayPanel #overlay>
            <h3>Ești sigur că vrei să ștergi această companie?</h3>
            <div class="buttons">
                <button pButton pRipple type="button" class="p-button-danger" (click)="delete()" label="Da"></button>
                <button pButton pRipple type="button" (click)="overlay.hide()" label="Nu"></button>
            </div>
        </p-overlayPanel>
    </button>
</div>
