import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerModel } from '../../../../shared/models/nomenclatures/customers/customer.model';
import { CustomerAddressModel } from '../../../../shared/models/nomenclatures/customers/customer-address.model';
import { CustomValidationService } from '../../../../shared/services/app/custom-validation.service';
import { CustomerService } from '../../../../shared/services/app/nomenclatures/customer.service';
import { finalize } from 'rxjs/operators';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ApiResponseModel } from '../../../../shared/models/api-response.model';
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { ValidationMessageService } from '../../../../shared/services/base/validation-message.service';
import { CompanyService } from '../../../../shared/services/app/nomenclatures/company.service';

@Component({
    selector: 'app-customers-add-edit',
    templateUrl: './customers-add-edit.component.html',
    styleUrls: ['./customers-add-edit.component.scss'],
})
export class CustomersAddEditComponent implements OnInit {
    public loading: boolean = false;
    public form: FormGroup;

    public uid: string = null;
    public itemModel: CustomerModel = new CustomerModel();
    public companyId: number = null;

    pattern: string = '^\\+(?:[0-9]●?){6,14}[0-9]$|^07(?:[0-9]{8})';

    constructor(
        private formBuilder: FormBuilder,
        private customerService: CustomerService,
        public config: DynamicDialogConfig,
        public dialog: DynamicDialogRef,
        private readonly companyService: CompanyService,
        private messageService: MessageService,
        private validationMessageService: ValidationMessageService,
        private customValidation: CustomValidationService
    ) {}

    get formAddresses(): FormArray {
        return this.form.controls['addresses'] as FormArray;
    }

    ngOnInit() {
        if (this.config.data) {
            this.uid = this.config.data.uid;
        }

        this.createForm();

        if (this.uid) {
            this.getItem();
        }
    }

    createForm(): void {
        this.form = this.formBuilder.group({
            uid: [this.uid],
            phone: [
                this.itemModel.phone,
                [
                    Validators.required,
                    Validators.minLength(10),
                    Validators.maxLength(20),
                    Validators.pattern('^[0-9().+-_]*$'),
                ],
            ],
            email: [this.itemModel.email, [Validators.email]],
            firstName: [this.itemModel.firstName, Validators.required],
            lastName: [this.itemModel.lastName],
            addresses: this.formBuilder.array([]),
        });
    }

    getItem(): void {
        this.loading = true;
        this.customerService
            .getCustomer(this.uid)
            .pipe(
                finalize(() => {
                    this.loading = false;
                })
            )
            .subscribe((response: ApiResponseModel) => {
                this.companyId = response.payload.company_id;
                this.itemModel.map(response.payload);
                this.form.patchValue(this.itemModel);
                this.itemModel.addresses.forEach((address: CustomerAddressModel) => {
                    this.addAddress(address);
                });
            });
    }

    addAddress(address?: CustomerAddressModel): void {
        this.formAddresses.insert(
            0,
            this.formBuilder.group({
                uid: [address ? address.uid : null],
                fullAddress: [address ? address.display_name : null],
                county: [''],
                district: [
                    {
                        value: address ? address.district : null,
                        disabled: true,
                    },
                    [Validators.required, Validators.minLength(3), Validators.maxLength(50)],
                ],
                city: [
                    {
                        value: address ? address.city : null,
                        disabled: true,
                    },
                    [Validators.required, Validators.minLength(3), Validators.maxLength(50)],
                ],
                display_name: [
                    {
                        value: address ? address.display_name : null,
                        disabled: true,
                    },
                    [Validators.required, Validators.minLength(3), Validators.maxLength(500)],
                ],
                street: [
                    {
                        value: address ? address.street : null,
                        disabled: true,
                    },
                    [Validators.required, Validators.minLength(5), Validators.maxLength(50)],
                ],
                number: [
                    {
                        value: address ? address.number : null,
                        disabled: true,
                    },
                    [Validators.minLength(1), Validators.maxLength(50)],
                ],
                block: [address ? address.block : '-', [Validators.minLength(1), Validators.maxLength(50)]],
                staircase: [address ? address.staircase : '-', [Validators.minLength(1), Validators.maxLength(50)]],
                floor: [address ? address.floor : '-', [Validators.minLength(1), Validators.maxLength(50)]],
                apartments: [address ? address.apartments : '-', [Validators.minLength(1), Validators.maxLength(50)]],
                otherDetails: [
                    address ? address.otherDetails : '-',
                    [Validators.minLength(0), Validators.maxLength(500)],
                ],
                lat: [address ? address.lat : '-', [Validators.required]],
                lon: [address ? address.lon : '-', Validators.required],
                type: [address ? address.type : '-', Validators.required],
                class: [address ? address.class : '-', Validators.required],
                boundingbox: [address ? JSON.parse(address.boundingbox.toString()) : '-'],
                osm_id: [address ? address.osm_id : '-', Validators.required],
                place_id: [address ? address.place_id : '-', Validators.required],
                customerUid: [this.uid],
            })
        );
    }

    onSave(): void {
        if (!this.customValidation.validateOnSubmit(this.form)) return;
        const data: CustomerModel = this.form.getRawValue();
        if (this.uid) {
            data.company = this.itemModel.company;
            data.company_id = this.itemModel.company_id;
        } else {
            data.company = this.companyService.company;
            data.company_id = data.company.id;
        }

        data.isCompany = 0;

        this.customerService
            .create(data)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe({
                next: (response: ApiResponseModel) => {
                    if (response.status === true) {
                        this.uid = response.payload.uid;
                        this.messageService.add({
                            summary: 'Salvare client',
                            detail: 'Clientul a fost salvat cu succes!',
                            severity: 'success',
                        });
                        this.dialog.close(this.itemModel);
                    }
                },
                error: (err) => {
                    if (err instanceof HttpErrorResponse) {
                        this.validationMessageService.serverSideValidation(err, this.form, '', true);
                        this.messageService.add({
                            summary: 'Eroare!',
                            detail: 'A aparut o eroare la salvare',
                            severity: 'error',
                        });
                    }
                },
            });
    }

    onCancel(): void {
        this.dialog.close();
    }
}
