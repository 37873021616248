import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MenuItem, MessageService } from 'primeng/api';
import { ApiResponseModel } from '../../../../shared/models/api-response.model';
import { ProductApiResponseModel, ProductModel } from '../../../../shared/models/nomenclatures/products/product.model';
import { finalize } from 'rxjs';
import { ProductLocationModel } from '../../../../shared/models/nomenclatures/products/product-location.model';
import { ProductService } from '../../../../shared/services/app/nomenclatures/product.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ComplexProductConfigTypeEnum } from '../../../../shared/enum/complex-product-config-type.enum';
import { PropagationResultModel } from '../../../../shared/models/propagation-result.model';

@Component({
    selector: 'app-complex-products',
    templateUrl: './complex-products.component.html',
    styleUrls: ['./complex-products.component.scss'],
})
export class ComplexProductsComponent implements OnInit {
    configType: ComplexProductConfigTypeEnum = ComplexProductConfigTypeEnum.FOR_THIS_LOCATION;
    loading: boolean = false;
    product: ProductModel;
    productLocations: ProductLocationModel[];
    companyId: number;
    failedToLoad: boolean = false;
    uid: string | undefined;
    form: FormGroup;

    // Propagation (step 3)

    selectedForPropagation = new Array<number>();
    rulePropagationResult = new Array<PropagationResultModel>();
    variationPropagationResult = new Array<PropagationResultModel>();
    hasPropagatedOnce: boolean;

    // Pagination

    steps: MenuItem[] = [
        { label: 'Tip configurare' },
        { label: 'Configurare reguli' },
        { label: 'Configurare varietăți' },
    ];
    singleLocationSteps: MenuItem[] = [
        { label: 'Tip configurare' },
        { label: 'Configurare reguli' },
        { label: 'Configurare varietăți' },
    ];
    allLocationSteps: MenuItem[] = [
        { label: 'Tip configurare' },
        { label: 'Configurare reguli' },
        { label: 'Configurare varietăți' },
        { label: 'Propagare reguli' },
        { label: 'Propagare varietăți' },
    ];
    activeIndex = 0;
    maxActiveIndex = 2;
    maxStep: number = 0;

    constructor(
        private productService: ProductService,
        private route: ActivatedRoute,
        private router: Router,
        private formBuilder: FormBuilder,
        private messageService: MessageService
    ) {}

    ngOnInit(): void {
        const snapshot = this.route.snapshot;
        this.uid = snapshot.queryParams['product_uid'] ? snapshot.queryParams['product_uid'] : undefined;

        this.createForm();

        if (this.uid) {
            this.getItem();
        } else {
            this.failedToLoad = true;
        }
    }

    createForm(): void {
        this.form = this.formBuilder.group({
            location_id: null,
        });

        this.form.controls['location_id'].valueChanges.subscribe(() => (this.maxStep = 0));
    }

    getItem(): void {
        this.loading = true;
        this.productService
            .getItem(this.uid)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe((response: ProductApiResponseModel) => {
                this.product = response.payload;
                this.companyId = response.payload.company_id;
                this.productLocations = this.product.productLocations as Array<ProductLocationModel>;
            });
    }

    onConfigTypeUpdate(type: ComplexProductConfigTypeEnum) {
        switch (type) {
            case ComplexProductConfigTypeEnum.FOR_THIS_LOCATION:
                this.steps = this.singleLocationSteps;
                this.maxActiveIndex = this.singleLocationSteps.length - 1;
                break;
            case ComplexProductConfigTypeEnum.FOR_ALL_LOCATIONS:
                this.steps = this.allLocationSteps;
                this.maxActiveIndex = this.allLocationSteps.length - 1;
                break;
        }
    }

    onPropagationLocationsChange(locationIds: Array<number>) {
        this.selectedForPropagation = locationIds;
    }

    handlePropagationStep(stepType: 'rule' | 'variation') {
        const shouldPropagate = this.selectedForPropagation.length > 0;

        const locationId = this.form.controls['location_id'].value;
        if (!locationId) {
            this.messageService.add({
                severity: 'error',
                summary: 'Nu ați selectat o locație de referință!',
                detail: 'Nu am detectat că ați selectat o locație de referință. Dacă ați selectat una, contactați un Administrator.',
            });
            return;
        }
        if (this.hasPropagatedOnce) {
            let failedPropagations = new Array<PropagationResultModel>();
            switch (stepType) {
                case 'rule':
                    failedPropagations = this.rulePropagationResult.filter(
                        (result: PropagationResultModel) => !result.status
                    );
                    break;
                case 'variation':
                    failedPropagations = this.variationPropagationResult.filter(
                        (result: PropagationResultModel) => !result.status
                    );
                    break;
            }

            if (failedPropagations.length === 0) {
                switch (stepType) {
                    case 'rule':
                        this.activeIndex++;
                        this.maxStep = this.activeIndex;

                        this.selectedForPropagation = [];
                        this.rulePropagationResult = [];
                        this.hasPropagatedOnce = false;
                        break;
                    case 'variation':
                        this.onFinish();
                        break;
                }
                return;
            }
            this.messageService.add({
                severity: 'error',
                summary: 'Trebuie să configurați toate locațiile la care propagarea a eșuat!',
            });
            return;
        }
        if (shouldPropagate) {
            switch (stepType) {
                case 'rule':
                    this.propagateRules();
                    break;
                case 'variation':
                    this.propagateVariations();
                    break;
            }
            return;
        }
        this.messageService.add({
            severity: 'error',
            summary: 'Selectați cel puțin o locație pentru propagare.',
        });
    }

    propagateRules(): void {
        this.rulePropagationResult = [];
        const locationId = this.form.controls['location_id'].value;
        this.loading = true;
        this.productService
            .propagateRules(locationId, this.product.id, this.selectedForPropagation)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe((response: ApiResponseModel) => {
                const result = response.payload as PropagationResultModel[];
                this.handlePropagationResult(result, 'rule');
            });
    }

    propagateVariations(): void {
        this.variationPropagationResult = [];
        const locationId = this.form.controls['location_id'].value;
        this.loading = true;
        this.productService
            .propagateVariations(locationId, this.product.id, this.selectedForPropagation)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe((response: ApiResponseModel) => {
                const result = response.payload as PropagationResultModel[];
                this.handlePropagationResult(result, 'variation');
            });
    }

    handleStep(newStep: number) {
        if (newStep <= this.maxStep) {
            this.activeIndex++;
            return;
        }
        switch (newStep) {
            case 1:
                if (!this.form.controls['location_id'].value) {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Selectați o locație',
                    });
                    break;
                }
                this.activeIndex++;
                this.maxStep = this.activeIndex;
                break;
            case 3:
                if (this.maxActiveIndex === 2) {
                    this.onFinish();
                }
                break;
            case 4:
                this.handlePropagationStep('rule');
                break;
            case 5:
                this.handlePropagationStep('variation');
                break;
            default:
                this.activeIndex++;
                this.maxStep = this.activeIndex;
                break;
        }
    }

    /**
     * Handles the propagation result
     *
     * @param result the result received from the API
     * @param propagationType if the propagation was a rule or variation propagation
     *
     * @return void
     */
    handlePropagationResult(result: PropagationResultModel[], propagationType: 'rule' | 'variation'): void {
        switch (propagationType) {
            case 'rule':
                this.rulePropagationResult = result;
                break;
            case 'variation':
                this.variationPropagationResult = result;
                break;
        }

        const failedPropagations = result.filter((locationResult: PropagationResultModel) => !locationResult.status);
        if (failedPropagations.length > 0) {
            this.messageService.add({
                severity: 'error',
                summary: 'Propagarea a eșuat în unele dintre locații',
                detail: 'Puteți configura aceste locații manual prin apăsarea butonului de configurare',
            });
            if (!this.hasPropagatedOnce) this.hasPropagatedOnce = true;
        } else {
            this.messageService.add({
                severity: 'success',
                summary: 'Propagarea s-a terminat cu succes!',
            });
            switch (propagationType) {
                case 'rule':
                    this.activeIndex++;
                    this.maxStep = this.activeIndex;

                    this.selectedForPropagation = [];
                    this.rulePropagationResult = [];
                    break;
                case 'variation':
                    this.onFinish();
                    break;
            }
        }
    }

    getNextButtonIcon(): string {
        if (this.activeIndex + 1 <= this.maxStep) {
            return 'fas fa-arrow-right';
        }

        if ((this.activeIndex === 3 || this.activeIndex === 4) && !this.hasPropagatedOnce) {
            return 'fas fa-sync';
        }

        if (this.activeIndex === this.maxActiveIndex) {
            return 'fas fa-flag-checkered';
        }

        return 'fas fa-arrow-right';
    }

    getNextButtonLabel(): string {
        if (this.activeIndex + 1 <= this.maxStep) {
            return 'Înainte';
        }

        if ((this.activeIndex === 3 || this.activeIndex === 4) && !this.hasPropagatedOnce) {
            return 'Propagare';
        }
        if (this.activeIndex === this.maxActiveIndex) {
            return 'Finalizare';
        }

        return 'Înainte';
    }

    shouldNextButtonBeGreen(): boolean {
        if (this.maxActiveIndex === 2) {
            return this.activeIndex >= this.maxActiveIndex;
        }
        return this.activeIndex >= this.maxActiveIndex && this.hasPropagatedOnce;
    }

    onFinish(): void {
        this.router.navigate(['nomenclatures/products']);
    }
}
