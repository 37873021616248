import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-companies-add-edit-call-center',
    templateUrl: './companies-add-edit-call-center.component.html',
    styleUrls: ['./companies-add-edit-call-center.component.scss'],
})
export class CompaniesAddEditCallCenterComponent {
    @Input() form: FormGroup;
    constructor() {}
}
