import { Injectable } from '@angular/core';
import { TableService } from 'primeng/table';
import { Observable } from 'rxjs';
import { ApiResponseModel } from '../../../models/api-response.model';
import { ProductApiResponseModel, ProductCategoryModel } from '../../../models/nomenclatures/products/product.model';
import { MenuRestrictionsModel } from '../../../models/nomenclatures/products/menu.restrictions.model';
import { HttpApiService } from '../../base/http-api.service';

@Injectable({
    providedIn: 'root',
})
export class CategoryService {
    constructor(public httpApiService: HttpApiService) {}

    getItem(uid: string): Observable<ProductApiResponseModel> {
        return this.httpApiService.httpGet('category/show/' + uid);
    }

    saveItem(data: ProductCategoryModel): Observable<ProductApiResponseModel> {
        return this.httpApiService.httpPut('category/update/' + data.uid, data);
    }
}
