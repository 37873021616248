import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from "@angular/forms";
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { RippleModule } from 'primeng/ripple';
import { TableModule } from "primeng/table";
import { TooltipModule } from 'primeng/tooltip';
import { SharedModule } from '../../shared/shared.module';
import { OrdersActionTemplateComponent } from './orders-action-template/orders-action-template.component';
import { OrdersComponent } from './orders.component';
import { ViewOrderComponent } from './view-order/view-order.component';

@NgModule({
    declarations: [OrdersComponent, ViewOrderComponent, OrdersActionTemplateComponent],
    imports: [
        CommonModule,
        DividerModule,
        SharedModule,
        RippleModule,
        ButtonModule,
        TooltipModule,
        ReactiveFormsModule,
        TableModule,
    ],
})
export class OrdersModule {}
