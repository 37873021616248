import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { ApiResponseModel } from '../../../../../shared/models/api-response.model';
import { LocationService } from '../../../../../shared/services/app/nomenclatures/location.service';
import { MessageService } from 'primeng/api';
import { NgSelectComponent } from '@ng-select/ng-select';

interface ECommerceType {
    id: string;
    name: string;
}

@Component({
    selector: 'app-locations-add-edit-sync',
    templateUrl: './locations-add-edit-sync.component.html',
    styleUrls: ['./locations-add-edit-sync.component.scss'],
})
export class LocationsAddEditSyncComponent implements OnInit {
    loading: boolean;

    eCommerceTypes: ECommerceType[] = [
        { id: '1', name: 'WooCommerce' },
        { id: '2', name: 'Magneto' },
        { id: '3', name: 'Prestashop' },
        { id: '4', name: 'Restajet' },
    ];

    @Input() form: FormGroup;
    @Input() uid: string;
    @Input() companyUid: string;
    @Input() ecommerceModule: boolean;
    @Input() cloudCompany: boolean;

    @ViewChild('eCommerceDropdown') eCommerceTypeDropdown: NgSelectComponent;
    @ViewChild('testECommerceButton') testECommerceButton: ElementRef;
    @ViewChild('getEcommerceProductsButton') eCommerceProductsButton: ElementRef;

    posDeviceEndpoint: string;

    constructor(private locationService: LocationService, private messageService: MessageService) {}

    ngOnInit() {
        this.form.controls['allowEcommerce'].valueChanges.subscribe((value: boolean) => {
            this.onAllowEcommerce(value);
        });

        this.posDeviceEndpoint = `v2/company/${this.companyUid}/pos-devices`;
    }

    /**
     * Fires when the allowEcommerce checkbox is toggled. Toggles all form controls and buttons related to E-Commerce between enabled and disabled.
     * @param value the control value (true/false)
     *
     */
    onAllowEcommerce(value: boolean) {
        const controlsToToggle: string[] = [
            'syncOrders',
            'ecommerceSkipCategorySync',
            'ecommerceSkipProductSync',
            'ecommerceUser',
            'ecommercePassword',
            'ecommerceUrl',
            'ecommerceType',
            'getDescriptionFromFreya',
            'syncDescriptionToEcommerce',
            'syncNameToEcommerce',
            'syncProductsBarcodes',
        ];

        if (!value) {
            controlsToToggle.forEach((controlKey: string) => {
                this.form.controls[controlKey].disable();
                this.eCommerceTypeDropdown.setDisabledState(true);
                this.testECommerceButton.nativeElement.disabled = true;
                this.eCommerceProductsButton.nativeElement.disabled = true;
            });
        } else {
            controlsToToggle.forEach((controlKey: string) => {
                this.form.controls[controlKey].enable();
                this.eCommerceTypeDropdown.setDisabledState(false);
                // Enable eCommerce buttons only if we are editing a location
                if (this.uid) {
                    this.testECommerceButton.nativeElement.disabled = false;
                    this.eCommerceProductsButton.nativeElement.disabled = false;
                }
            });
        }
    }

    testEcommerceConnection(): void {
        this.loading = true;
        this.locationService
            .testEcommerceConnection(this.uid)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe({
                next: (response: ApiResponseModel) => {
                    if (response.status === true) {
                        this.messageService.add({
                            detail: 'Conexiunea cu E-Commerce a fost realizata cu succes!',
                            summary: 'Conexiune E-Commerce',
                            severity: 'success',
                        });
                    }
                },
            });
    }

    testFreyaConnection(): void {
        this.loading = true;
        this.locationService
            .testFreyaConnection(this.uid)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe({
                next: (response: ApiResponseModel) => {
                    if (response.status === true) {
                        this.messageService.add({
                            detail: 'Conexiunea cu Freya a fost realizata cu succes!',
                            summary: 'Conexiune Freya',
                            severity: 'success',
                        });
                    }
                },
            });
    }

    getProducsFromFreya(): void {
        this.loading = true;
        this.locationService
            .getProducsFromFreya(this.uid)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe({
                next: (response: ApiResponseModel) => {
                    if (response.status === true) {
                        this.messageService.add({
                            detail: 'Produsele din Freya au fost preluate cu succes!',
                            summary: 'Conexiune Freya',
                            severity: 'success',
                        });
                    } else {
                        this.messageService.add({
                            detail: 'Conexiunea cu Freya nu a fost realizata',
                            summary: 'Conexiune Freya',
                            severity: 'error',
                        });
                    }
                },
                error: () => {
                    this.messageService.add({
                        detail: 'Conexiunea cu Freya nu a fost realizata',
                        summary: 'Conexiune Freya',
                        severity: 'error',
                    });
                },
            });
    }

    getProducsFromEcomerce(): void {
        this.loading = true;
        this.locationService
            .getProducsFromEcomerce(this.uid)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe({
                next: (response: ApiResponseModel) => {
                    if (response.status === true) {
                        this.messageService.add({
                            detail: 'Produsele din E-Commerce au fost preluate cu succes!',
                            summary: 'Conexiune E-Commerce',
                            severity: 'success',
                        });
                    } else {
                        this.messageService.add({
                            detail: 'Conexiunea cu E-Commerce nu a fost realizata',
                            summary: 'Conexiune E-Commerce',
                            severity: 'error',
                        });
                    }
                },
                error: () => {
                    this.messageService.add({
                        detail: 'Conexiunea cu E-Commerce nu a fost realizata',
                        summary: 'Conexiune E-Commerce',
                        severity: 'error',
                    });
                },
            });
    }
}
