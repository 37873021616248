import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs';
import { ApiResponseModel } from '../../../shared/models/api-response.model';
import { CustomerAddressModel } from '../../../shared/models/nomenclatures/customers/customer-address.model';
import { OrderModel, OrderPayment, OrderProduct } from '../../../shared/models/nomenclatures/order.model';
import { OrderService } from '../../../shared/services/app/nomenclatures/order.service';

interface FormModel {
    uid: string;
    customerName: string;
    customerPhone: string;
    deliveryType: string;
    customerEmail: string;
    customerAddress: string;
    products: FormArray<FormGroup>;
    payments: FormArray<FormGroup>;
    total: number;
}

@Component({
    selector: 'app-view-order',
    templateUrl: './view-order.component.html',
    styleUrls: ['./view-order.component.scss'],
})
export class ViewOrderComponent implements OnInit {
    uid: string;
    loading: boolean = false;
    itemModel: OrderModel = new OrderModel();
    form: FormGroup;
    constructor(
        private config: DynamicDialogConfig,
        private ref: DynamicDialogRef,
        private formBuilder: FormBuilder,
        private orderService: OrderService
    ) {}

    get formProducts(): FormArray<FormGroup> {
        return this.form.get('products') as FormArray<FormGroup>;
    }

    get formPayments(): FormArray<FormGroup> {
        return this.form.get('payments') as FormArray<FormGroup>;
    }

    ngOnInit(): void {
        this.uid = this.config.data.uid;
        this.createForm();
        this.getItem();
    }

    private createForm(): void {
        this.form = this.formBuilder.group<FormModel>({
            uid: '',
            customerName: '',
            deliveryType: '',
            customerEmail: '',
            customerPhone: '',
            customerAddress: '',
            products: this.formBuilder.array<FormGroup>([]),
            payments: this.formBuilder.array<FormGroup>([]),
            total: 0,
        });
    }

    private getItem(): void {
        this.loading = true;
        this.orderService
            .get(this.uid)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe((response: ApiResponseModel) => {
                this.itemModel.map(response.payload);
                const dates = ['pickup_date', 'created_at', 'updated_at'];
                this.castDates(this.itemModel, dates);
                this.patchForm(this.itemModel);
            });
    }

    private castDates(item: OrderModel, keys: Array<string>): void {
        keys.forEach((key: string) => {
            const dateStr = item[key];
            item[key] = new Date(dateStr);
        });
    }

    private patchForm(data: OrderModel): void {
        const address = data.customer.addresses.find((address: CustomerAddressModel) => address.id === data.address_id);
        this.form.patchValue({
            uid: data.uid,
            customerName: `${data.customer.firstName} ${data.customer.lastName}`,
            deliveryType: data.deliveryType,
            customerEmail: data.customer.email,
            customerPhone: data.customer.phone,
            customerAddress: address ? address.display_name : '-',
            total: data.totalWithDiscount,
        });
        data.products.forEach((product: OrderProduct) => this.addProduct(product));
        data.payments.forEach((payment: OrderPayment) => this.addPayment(payment));
    }

    private addProduct(product: OrderProduct): void {
        const group = this.formBuilder.group({
            name: product.name,
            units: product.units,
            pricePerUnit: product.price + ' lei',
            price: product.price * product.units + ' lei',
            priceWithDiscount: product.priceWithDiscount * product.units + ' lei',
            discount: !product.discount
                ? '-'
                : product.percentDiscount
                ? product.discount + '%'
                : product.discount + ' lei',
            comment: product.comment ? product.comment : '-',
        });
        this.formProducts.push(group);
    }

    private addPayment(payment: OrderPayment): void {
        const group = this.formBuilder.group({
            name: payment.name,
            total: payment.total + ' lei',
            type: payment.type,
        });
        this.formPayments.push(group);
    }
}
