import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-locations-add-edit-schedule',
    templateUrl: './locations-add-edit-schedule.component.html',
    styleUrls: ['./locations-add-edit-schedule.component.scss'],
})
export class LocationsAddEditScheduleComponent implements OnInit {
    schedule: FormGroup;
    days: FormGroup[] = [];

    dayNames: string[] = ['Luni', 'Marti', 'Miercuri', 'Joi', 'Vineri', 'Sambata', 'Duminica'];

    @Input() form: FormGroup;

    constructor() {}

    ngOnInit() {
        this.schedule = this.form.get('schedule') as FormGroup;
        const controls = this.schedule.controls;
        Object.keys(controls).forEach((key: string) => {
            const control = controls[key] as FormGroup;
            this.days.push(control);
        });
    }
}
