<p-divider *ngIf="isStandalone"></p-divider>
<div
    class="flex"
    [ngClass]="{ 'justify-content-between align-items-center': isStandalone, 'justify-content-end': !isStandalone }"
>
    <h2 *ngIf="isStandalone">Locații pentru compania: {{ companyName }}</h2>
    <button
        *ngIf="!isStandalone"
        pButton
        pRipple
        label="Adaugă locație"
        type="button"
        (click)="onAdd()"
        icon="pi pi-plus"
    ></button>
</div>
<p-divider *ngIf="isStandalone"></p-divider>

<div [class]="isStandalone ? 'card' : ''">
    <div class="grid">
        <div class="col-12">
            <app-stt-table
                #table
                [actionTemplate]="actionTemplate"
                [endpoint]="tableEndpoint"
                [query]="tableQuery"
            ></app-stt-table>
        </div>
    </div>
</div>
