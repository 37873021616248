import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { CustomValidationService } from '../../shared/services/app/custom-validation.service';
import { ValidationMessageService } from '../../shared/services/base/validation-message.service';
import { AuthRequestModel } from '../models/auth.model';
import { UserService } from '../user.service';
import { ApiResponseModel } from '../../shared/models/api-response.model';
import { CompanyService } from '../../shared/services/app/nomenclatures/company.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    form: FormGroup;
    returnUrl: string;
    loading: boolean = false;
    display: any = false;

    constructor(
        private formBuilder: FormBuilder,
        private userService: UserService,
        private readonly companyService: CompanyService,
        private route: ActivatedRoute,
        private router: Router,
        private cd: ChangeDetectorRef,
        private validationMessageService: ValidationMessageService,
        private customValidation: CustomValidationService
    ) {}

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
        });

        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.form.patchValue({
            email: this.route.snapshot.queryParams['email'] || '',
        });

        this.cd.markForCheck();
    }

    onSend(): void {
        if (!this.customValidation.validateOnSubmit(this.form)) return;
        let authModel: AuthRequestModel;
        authModel = this.form.getRawValue();
        this.userService.setRedirectUrl(this.returnUrl);
        this.loading = true;
        this.userService
            .login(authModel)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe({
                next: (response: ApiResponseModel) => {
                    this.userService.setUserDetails(response.payload);
                    this.router.navigate([this.returnUrl]);
                },
                error: (err) => {
                    if (err instanceof HttpErrorResponse) {
                        this.validationMessageService.serverSideValidation(err, this.form, '', true);
                    }
                },
            });
    }
}
