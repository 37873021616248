<p-progressBar mode="indeterminate" *ngIf="loading"></p-progressBar>
<div [ngClass]="{ 'margin-top-for-tabs': !isDialog }" class="grid" *ngIf="!loading">
    <div class="col-6 pr-3 overflow-y-scroll product-side">
        <div *ngFor="let menu of menus">
            <h3>{{ menu.name }}</h3>
            <ul class="flex flex-column gap-3">
                <li *ngFor="let item of menu.products">
                    <a
                        (click)="getConfigurations(menu.code, item.code)"
                        id="{{ menu.code + '_' + item.code }}"
                        class="item-link"
                    >
                        {{ item.name }}
                        <strong>
                            {{
                                restrictionNumber.get(menu.code + '_' + item.code)
                                    ? ' (' +
                                      restrictionNumber.get(menu.code + '_' + item.code) +
                                      ' restrictii configurate)'
                                    : ''
                            }}
                        </strong>
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div
        class="col-6 pl-3 border-left-1 border-gray-400 overflow-y-scroll configurable-side"
        *ngIf="configurableProductIdInFreya"
    >
        <h2>Configurare pentru produsul {{ getProductModelFromMenuById(configurableProductIdInFreya).name }}</h2>
        <div class="product-configuration p-1">
            <div *ngIf="configurableSteps.length === 0">
                <h3 class="red-text">Nu se pot configura restricții pentru acest produs</h3>
            </div>
            <div *ngFor="let configurableMenu of configurableSteps">
                <h3>{{ configurableMenu.name }}</h3>
                <ul class="configurable-list">
                    <li *ngFor="let item of configurableMenu.products">
                        <p-checkbox
                            id="{{ configurableMenu.id + '_' + item.id }}"
                            [binary]="true"
                            [(ngModel)]="item.selected"
                            [label]="item.name"
                        ></p-checkbox>
                    </li>
                </ul>
            </div>
        </div>
        <div class="buttons mt-4">
            <button
                *ngIf="configurableSteps.length > 0"
                pButton
                type="button"
                class="p-button-success"
                label="Salvează regula"
                id="buttonSaveRules"
                icon="pi pi-save"
                (click)="onSave()"
            ></button>
            <button
                *ngIf="configurableSteps.length > 0"
                pButton
                type="button"
                class="p-button-danger"
                id="buttonClearRules"
                label="Șterge regulile"
                icon="pi pi-save"
                (click)="onClear()"
            ></button>
        </div>
    </div>
</div>
