import { Injectable } from '@angular/core';
import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MessageService } from 'primeng/api';

@Injectable({
    providedIn: 'root',
})
export class CustomValidationService {
    constructor(private messageService: MessageService) {}

    matchPasswords(password: string, confirmPassword: string): ValidatorFn {
        return (form: FormGroup): ValidationErrors | null => {
            const passwordControl = form.controls[password];
            const confirmPasswordControl = form.controls[confirmPassword];

            if (!passwordControl || !confirmPasswordControl) return null;

            if (confirmPasswordControl.errors && !confirmPasswordControl.errors.passwordsMatch) return null;

            if (passwordControl.value !== confirmPasswordControl.value) {
                confirmPasswordControl.setErrors({ passwordsMatch: true });
                return { passwordsMatch: true };
            }
            confirmPasswordControl.setErrors(null);
            return null;
        };
    }

    validateOnSubmit(formGroup: FormGroup): boolean {
        if (formGroup.valid) return true;
        this.messageService.add({
            summary: 'Eroare!',
            severity: 'error',
            detail: 'Există câmpuri invalide!',
        });
        formGroup.markAllAsTouched();
        Object.keys(formGroup.controls).forEach((key: string) => {
            const control = formGroup.get(key);
            control.markAsTouched({ onlySelf: true });
            control.markAsDirty({ onlySelf: true });
        });
        return false;
    }
}
