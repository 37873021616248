<div class="grid" [formGroup]="form">
    <div class="col-5 xl:col-4 pr-3 layer-list">
        <div class="mt-2" *ngIf="!hasFreyaCloud">
            <app-stt-dropdown
                [endPoint]="dropdownEndpoint"
                [fields]="{ text: 'name', value: 'id' }"
                label="Produs livrare (Freya)"
                [formControlParent]="form.controls['freyaDeliveryProduct']"
                icon="pi pi-box"
            ></app-stt-dropdown>
        </div>
        <p-divider *ngIf="!hasFreyaCloud"></p-divider>
        <div
            *ngFor="let formGroup of freyaDeliveryProductCost.controls; let i = index"
            formArrayName="freyaDeliveryProductCost"
        >
            <div class="delivery-item" [ngClass]="{ active: selectedPolygonId === i }">
                <h4 [ngStyle]="{ color: colorPalette[i] ? colorPalette[i].fillColour : '' }">
                    {{ !hasPolygons ? 'Configurare setări livrare' : 'Zona ' + (i + 1) }}
                </h4>
                <div class="grid mt-1">
                    <div class="col-5">
                        <app-stt-input
                            [control]="formGroup.controls['productCost']"
                            label="Cost livrare"
                            inputId="productCost"
                            type="number"
                        ></app-stt-input>
                    </div>
                    <div class="col-5">
                        <app-stt-input
                            [control]="formGroup.controls['freeDeliveryThreshold']"
                            label="Comanda minimă"
                            inputId="freeDeliveryThreshold"
                            type="number"
                        ></app-stt-input>
                    </div>
                    <div class="col-2">
                        <button pButton icon="pi pi-pencil" type="button" (click)="editPromotions(i)"></button>
                    </div>
                </div>
            </div>
            <p-divider *ngIf="colorPalette[i] && colorPalette[i].used"></p-divider>
        </div>
    </div>
    <div class="col-7 xl:col-8 pl-3 border-left-1 border-gray-400" *ngIf="hasPolygons">
        <h2>Configurare rază de livrare</h2>
        <div
            leaflet
            [leafletOptions]="options"
            (leafletMapReady)="onMapReady($event)"
            (leafletMapMove)="onMapMove()"
            (leafletClick)="onClickMap($event)"
            id="map"
            class="full-width"
        >
            <div
                leafletDraw
                [leafletDrawOptions]="drawOptions"
                (leafletDrawCreated)="onDrawCreated($event)"
                (leafletDrawEdited)="onEdited($event)"
                (leafletDrawDeleted)="onDrawDeleted($event)"
            ></div>
            <div [leafletLayer]="drawnItems"></div>
            <div [leafletLayer]="locationLayer"></div>
        </div>
    </div>
</div>
