import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { finalize } from 'rxjs';
import { CompanyModel } from '../../../../../auth/models/company.model';
import { ApiResponseModel } from '../../../../../shared/models/api-response.model';
import { CustomerAddressModel } from '../../../../../shared/models/nomenclatures/customers/customer-address.model';
import { CompanyService } from '../../../../../shared/services/app/nomenclatures/company.service';
import { CustomerService } from '../../../../../shared/services/app/nomenclatures/customer.service';
import { LocationService } from '../../../../../shared/services/app/nomenclatures/location.service';

interface Suggestion {
    name: string;
    id: string;
    lat: string;
    lon: string;
    displayName: string;
    streetName?: string;
    streetNumber?: string | number;
    country?: string;
    city?: string;
    county?: string;
    suburb?: string;
    type: 'google' | 'nominatim';
}

@Component({
    selector: 'app-customers-add-edit-addresses',
    templateUrl: './customers-add-edit-addresses.component.html',
    styleUrls: ['./customers-add-edit-addresses.component.scss'],
})
export class CustomersAddEditAddressesComponent implements OnInit {
    hasAddresses: boolean = false;
    addresses: string[] = [];
    formGroups: FormGroup[] = [];

    googleSessionUid?: string;

    suggestionAddresses: Suggestion[];
    results: string[];

    loading: boolean = false;

    company: CompanyModel;

    @Input() form: FormGroup;
    @Output() addressAdded: EventEmitter<any> = new EventEmitter<any>();

    get formAddresses(): FormArray {
        return this.form.get('addresses') as FormArray;
    }

    constructor(
        private locationService: LocationService,
        private companyService: CompanyService,
        private customerService: CustomerService,
        private messageService: MessageService
    ) {}

    ngOnInit(): void {
        this.hasAddresses = this.formAddresses.length > 0;
        this.formGroups = this.formAddresses.controls as FormGroup[];
        this.updateLocalAddress();

        this.company = this.companyService.company;
    }

    addAddress(): void {
        this.addressAdded.emit();
        this.hasAddresses = true;
        this.addresses.splice(0, 0, '');
    }

    // Updates the local address name array used to label the accordion headers
    updateLocalAddress(index?: number): void {
        if (index) {
            const formAddress = this.formAddresses.at(index) as FormGroup;
            this.addresses[index] = formAddress.getRawValue().display_name;
        } else {
            this.addresses = [];
            this.formAddresses.controls.forEach((address: FormGroup) => {
                const temp = address.getRawValue().display_name;
                this.addresses.push(temp);
            });
        }
    }

    onAutocompleteOptionSelected(option: string, index: number) {
        const data = this.suggestionAddresses.find((address: Suggestion) => {
            return address.displayName === option;
        });

        if (data) {
            const formGroup: FormGroup = this.formAddresses.at(index) as FormGroup;
            const customer_uid = this.form.controls['uid'].value;
            /**
             * If data is received from Google, do another call to get the place details.
             * Else, just patch the form with the data from Nominatim.
             */
            if (data.type === 'google') {
                if (!this.googleSessionUid) {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Eroare la geodecodarea Google',
                        detail: 'Nu am putut prelua uid-ul sesiunii de autocomplete, încearca din nou.',
                    });
                    return;
                }

                this.loading = true;
                this.locationService
                    .getGooglePlaceDetails(data.id, this.googleSessionUid, this.company.uid)
                    .pipe(
                        finalize(() => {
                            this.googleSessionUid = undefined;
                            formGroup.patchValue({
                                district: data.county,
                                boundingbox: '[]',
                                customerUid: customer_uid,
                                county: data.county,
                                place_id: data.id,
                                city: data.city,
                                street: data.streetName,
                                number: data.streetNumber ? data.streetNumber : '-',
                                display_name: data.displayName,
                                lat: data.lat,
                                lon: data.lon,
                                type: data.type,
                            });
                            this.loading = false;
                        })
                    )
                    .subscribe((response: ApiResponseModel) => {
                        data.name = response.payload.displayName;
                        data.streetName = response.payload.streetName;
                        data.streetNumber = response.payload.streetNumber;
                        data.city = response.payload.city;
                        data.county = response.payload.county;
                        data.country = response.payload.country;
                        data.lat = response.payload.lat;
                        data.lon = response.payload.lon;
                    });
            } else {
                formGroup.patchValue({
                    customerUid: customer_uid,
                    osm_id: data.id,
                    boundingbox: '[]',
                    district: data.county,
                    county: data.county,
                    city: data.city,
                    street: data.streetName,
                    number: data.streetNumber ? data.streetNumber : '-',
                    display_name: data.displayName,
                    lat: data.lat,
                    lon: data.lon,
                    type: data.type,
                });
            }
            this.updateLocalAddress(index);
        } else {
            alert('ERROR');
        }
    }

    /**
     * Checks if form[uid] exists. If it does, it means the address has already been saved.
     * @param index Address index in FormArray
     *
     * @return boolean true if there is an address at that FormArray index, false otherwise
     */
    isCreated(index: number): boolean {
        const form: FormGroup = this.formAddresses.at(index) as FormGroup;
        const uid = form.controls['uid'];
        return uid.value !== null;
    }

    onAddressChange(i: number) {
        const county = this.formAddresses.at(i).get('county').value;
        const city = this.formAddresses.at(i).get('city').value;
        const searchText = this.formAddresses.at(i).get('fullAddress').value;

        this.suggestionAddresses = [];

        if (searchText.length <= 2) {
            return;
        }

        this.locationService
            .addressLookup(county, city, searchText, this.company.uid, undefined, this.googleSessionUid)
            .subscribe((response: ApiResponseModel) => {
                const newResults: string[] = [];
                if (response.payload.session_uid) {
                    this.googleSessionUid = response.payload.session_uid;
                }
                response.payload.suggestions.map((item: Suggestion) => {
                    this.suggestionAddresses.push(item);
                    newResults.push(item.displayName);
                });
                this.results = newResults;
            });
    }

    saveAddress(index: number) {
        const form = this.formAddresses.at(index) as FormGroup;
        const data: CustomerAddressModel = form.getRawValue();

        this.customerService.addAddress(data).subscribe((response: ApiResponseModel) => {
            this.messageService.add({
                summary: 'Adresa a fost salvata cu succes!',
                severity: 'success',
            });
            form.patchValue(response.payload);
        });
    }

    deleteAddress(index: number) {
        const form = this.formAddresses.at(index) as FormGroup;
        const data: CustomerAddressModel = form.getRawValue();

        this.customerService
            .removeAddress(data)
            .pipe(
                finalize(() => {
                    this.formAddresses.removeAt(index);
                    this.addresses.splice(index, 1);
                })
            )
            .subscribe(() => {
                this.messageService.add({
                    summary: 'Adresa a fost stearsa cu succes!',
                    severity: 'success',
                });
            });
    }
}
