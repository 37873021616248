import { BaseModel } from '../../base.model';

export class ScheduleModel extends BaseModel {
    monday: ScheduleDayModel = null;
    tuesday: ScheduleDayModel = null;
    wednesday: ScheduleDayModel = null;
    thursday: ScheduleDayModel = null;
    friday: ScheduleDayModel = null;
    saturday: ScheduleDayModel = null;
    sunday: ScheduleDayModel = null;
}

export class ScheduleDayModel extends BaseModel {
    start: Date;
    end: Date;
    allDay: string | boolean;
    closed: string | boolean;
}
